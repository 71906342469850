export const listaCompanias = [
    {id: 1, tipo: "JOSE ALFREDO PAINO SCARPATI"}
]

export const listaSexo = [
    {id: "M", tipo: "Masculino"},
    {id: "F", tipo: "Femenino"}
]

export const listaPermisos = [
    {id: "G", tipo: "Grupo"},
    {id: "U", tipo: "Usuario"}
]

export const listaPrivilegios = [
    {id: "N", tipo: "Normal"},
    {id: "M", tipo: "Master"}
]

export const listaEstadosActivo = [
    {id: "A", tipo: "Activo"},
    {id: "I", tipo: "Inactivo"}
]

export const listaEstados = [
    {id: "S", tipo: "Si"},
    {id: "N", tipo: "No"}
]

export const listaAppDefault = [
    {id: 1, tipo: "Kardex Cloud"},
    {id: 2, tipo: "Servicios"}
]

export const listaEstadosActivoBusqueda = [
    {id: "A", tipo: "Activo"},
    {id: "I", tipo: "Inactivo"},
    {id: "T", tipo: "Todos"},
]

export const listaTipoModulo = [
    {id: "MWT", tipo: "MENU (WPANEL TRANSACCION)"},
    {id: "MO", tipo: "MENU (OPCION)"},
    {id: "N", tipo: "NINGUNO"},
    {id: "A", tipo: "APLICACION"},
]
