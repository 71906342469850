import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import { DataContext } from "../../../context/DataContext";
import { modulosBuscar } from "../../../api/moduloApi";
import { grupoCrear, grupoEditar } from "../../../api/grupoApi";

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { listaEstados, listaEstadosActivo } from "../../../constants/constants-selectores";
import { CONST_RUTA_GRUPOS_LISTAR } from "../../../constants/constants-rutas-codigos";
import { obtenerLocalStorage } from "../../../resources/funciones";
import { CONST_KEY_USUARIO_AUTENTICADO } from "../../../constants/constanst-valores";
import { usuarioObtenerCompaniasYSucursales } from "../../../api/usuarioApi";

export const GrupoForm = () => {

    const usuarioAutenticado = obtenerLocalStorage(CONST_KEY_USUARIO_AUTENTICADO);
    
    const [listaCompanias, setListaCompanias] = useState([])

    const [mensaje, setMensaje] = useState("")
    const [mostrar, setMostrar] = useState(false)
    const [tipoAlerta, setTipoAlerta] = useState("")

    //Obtener la accion a partir de la url (agregar o editar)
    const location = useLocation();
    const pathname = location.pathname;
    const accion = pathname.split("/").pop()

    const grupo = location?.state || {}

    const [listaModulosPorCompania, setListaModulosPorCompania] = useState([])

    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm({
        defaultValues: {
            grupo: grupo?.GrupNom || "",
            estado: grupo?.GrupEst || "A",
            acceso_sede: grupo?.GrupAcsSed || "N",
        }
    });

    const onSubmitCrear = async (data) => {

        const dataPOST = {
            nombre: data?.grupo?.toUpperCase().trim() || "",
            app: data?.app || "",
            compania: data?.compania || "",
            estado: data?.estado || "",
            acesoSede: data?.acceso_sede || "",
            token: usuarioAutenticado?.token
        }

        const { status, message } = await grupoCrear(dataPOST)

        setMostrar(true)
        setMensaje(message)
        // status == 1 ? setTipoAlerta("success") : setTipoAlerta("warning")
        if(status == 1){
            setTipoAlerta("success")
            navigate(CONST_RUTA_GRUPOS_LISTAR)
        }else{
            setTipoAlerta("warning")
        }
    }

    const onSubmitEditar = async (data) => {

        const dataPOST = {
            nombre: data?.grupo.toUpperCase().trim() || "",
            app: data?.app || "",
            compania: data?.compania || "",

            codigo: grupo?.GrupCod || "",

            estado: data?.estado || "",
            acesoSede: data?.acceso_sede || "",

            token: usuarioAutenticado?.token
            
        }

        const { status, message } = await grupoEditar(dataPOST)
        status == 1 ? setTipoAlerta("success") : setTipoAlerta("warning")
        setMostrar(true)
        setMensaje(message)
    }

    const navigate = useNavigate();

    const cancelar = () => {
        navigate(CONST_RUTA_GRUPOS_LISTAR)
    }

    const obtenerCompaniasYSucursales = async () => {

        const dataPOST = {
            token: usuarioAutenticado?.token
        }

        const { companias } = await usuarioObtenerCompaniasYSucursales(dataPOST)
        setListaCompanias(companias)
        setValue("compania", grupo?.GrupComp || companias?.[0]?.id)

        return {
            companias,
        }
    }

    // const obtenerModulosPorCompania = async (idCompania) => {

    //     const dataPOST = {
    //         CompCod: idCompania,
    //         Estado: "A",
    //         Superior: "",
    //         token: usuarioAutenticado?.token
    //     }


    //     const { modulos } = await modulosBuscar(dataPOST)
        
    //     setListaModulosPorCompania(modulos)
    //     setValue("app", grupo?.GrupApp || modulos?.[0]?.ModCod || "N")
    // }

  useEffect(() => {

    const cargarDatosIniciales = async () => {
      const {companias} = await obtenerCompaniasYSucursales()
    //   await obtenerModulosPorCompania(watch("compania"))
    };

    cargarDatosIniciales();
  }, [])


    return (
        <div className="p-3">
            <div className="container-fluid">
                <h3 className="text-center mt-1">Grupo</h3>
                <div className="row mt-3">
                    <div className="col-12 col-lg-7 mx-auto mt-0">
                        {/* Formulario */}
                        <form id="CreateForm" onSubmit={accion == "agregar" ? handleSubmit(onSubmitCrear) : handleSubmit(onSubmitEditar)} className="row g-2 p-1">
                            <div className="row border p-1 pb-2 rounded-5 g-2">

                                <div className="col-12 col-lg-12">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Compañía</label>
                                        <select
                                            {...register("compania")}
                                            className="form-select form-select-sm"
                                            disabled={accion == "agregar" ? false : true}
                                            value={watch("compania")}
                                        >
                                            {
                                                listaCompanias?.length > 0 && listaCompanias.map(c => (
                                                    <option key={c?.id} value={c?.id}>{c?.tipo}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>


                                <div className="col-12 col-lg-12">
                                    <div className="d-flex align-items-center">

                                        <label className="col-4 text-start px-2">Descripción</label>
                                        <input
                                            {...register("grupo", {
                                                required: {
                                                    value: true
                                                }
                                            })}
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                        />
                                    </div>
                                    {errors.grupo && errors.grupo.type === "required" && <p role="alert" className="error px-2 mb-0">Ingrese descripción</p>}
                                </div>

                                {/* <div className="col-12 col-lg-12">
                                    <div className="d-flex align-items-center">

                                        <label className="col-4 text-start px-2">App Default</label>
                                        <select {...register("app")} className="form-select form-select-sm" defaultValue={grupo?.GrupApp || "N"}>
                                            <option value={"N"}>NINGUNO</option>
                                            {
                                                listaModulosPorCompania?.length > 0 && listaModulosPorCompania.map(e => (
                                                    <option key={e?.ModCod} value={e?.ModCod} selected={e?.ModCod == grupo?.GrupApp}>{e?.ModDesc}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div> */}

                                <div className="col-12 col-lg-12">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Acceso a sedes</label>
                                        <select {...register("acceso_sede")} className="form-select form-select-sm" >
                                            {
                                                listaEstados?.length > 0 && listaEstados.map(e => (
                                                    <option key={e?.id} value={e?.id}>{e?.tipo}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-12">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Estado</label>
                                        <select {...register("estado")} className="form-select form-select-sm" >
                                            {
                                                listaEstadosActivo?.length > 0 && listaEstadosActivo.map(e => (
                                                    <option key={e?.id} value={e?.id}>{e?.tipo}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </form>
                        
                        <div className='mt-4 botones-accion text-center'>
                                {
                                    accion == "agregar" && (
                                        <button className='btn btn-sm btn-primario col-md-2' type="submit" form="CreateForm" onClick={handleSubmit(onSubmitCrear)}>Guardar</button>
                                    )
                                }
                                {
                                    accion == "editar" && (
                                        <button className='btn btn-sm btn-primario col-md-2' type="submit" form="CreateForm" onClick={handleSubmit(onSubmitEditar)}>Guardar</button>
                                    )
                                }
                                <button className='btn btn-sm btn-secundario col-md-2' onClick={cancelar}>Cancelar</button>

                            </div>
                    </div >
                    {/* Fin formulario */}
                </div>

                {
                    mostrar && (
                        <div className="row mt-4">
                            <div className="col-lg-6 mx-auto text-center alert-error">
                                <Stack spacing={2}>
                                    <Alert variant="outlined" severity={tipoAlerta} >{mensaje}</Alert>
                                </Stack>
                            </div>
                        </div>

                    )
                }

            </div>
        </div>
    )
}
