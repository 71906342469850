import React from 'react'

export const SinAcceso = () => {

  const imagen = "/img/sin-acceso.png"

  return (
    <div className="p-3 h-100">
      <div className="container-fluid h-100 d-flex justify-content-center align-items-center">
        <div className="text-center">
          <img src={imagen} alt="imagen" className="img-thumbnail" style={{ height: "15rem", width: "15rem" }} />
          <h3 className="mt-3">No está autorizado para navegar en esta página</h3>
          <h6>Comuníquese con el área de Sistemas para solicitar acceso</h6>
        </div>
        
         
   
      </div>
    </div>
  )
}
