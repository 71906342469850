import { api } from "./configApi"

export const companiaCrear = async (dataPOST) => {
    try {

        const res = await api.post("api/compania/AgregarCompania", dataPOST)
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                compania: res.data.compania,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                compania: "",
                message: error.response.data.message,
            };
        }
        return {
            status: -1,
            compania: "",
            message: "Error en el servidor",
        }
    }
}

export const companiasBuscar = async (dataPOST) => {
    try {
        
        const res = await api.post("api/compania/BuscarCompania", dataPOST)
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                companias: res.data.resultado,
                message: res.data.message
            }
        }

    } catch (error) {
        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                companias: [],
                message: error.response.data.message,
            };
        }
        return {
            status: -1,
            companias: [],
            message: "Error en el servidor",
        }
    }
}

export const companiaDelete = async (dataPOST) => {
    try {

        const res = await api.post("api/compania/EliminarCompania", dataPOST)
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        }
    }
}

export const companiaMostrar = async (dataPOST) => {
    try {

        const res = await api.post("api/compania/MostrarCompania", dataPOST)

        // res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                compania: res.data.resultado,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                compania: {},
                message: error.response.data.message,
            };
        }
        return {
            status: -1,
            compania: {},
            message: "Error en el servidor",
        }
    }
}

export const companiaEditar = async (dataPOST) => {
    try {

        const res = await api.post("api/compania/ActualizarCompania", dataPOST)

        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        }
    }
}



