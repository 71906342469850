import { useLocation, useNavigate } from "react-router-dom";

import { FcSearch } from "react-icons/fc";
import { useEffect, useState } from "react";
import { gruposUsuariosPorGrupo } from "../../../api/grupoApi";
import { usuarioListarTodosPorCompania } from "../../../api/usuarioApi";
import { IoMdAdd } from "react-icons/io"
import { CONST_RUTA_GRUPOS_LISTAR, CONST_RUTA_GRUPOS_USUARIOS_AGREGAR, CONST_RUTA_SIN_ACCESO } from "../../../constants/constants-rutas-codigos";
import { rutasCodigosPrograma } from "../../../constants/constanst-arreglo-rutas-codigo";
import { validarAutenticacion } from "../../../resources/funcion-validar-autenticacion";
import { CONST_KEY_USUARIO_AUTENTICADO } from "../../../constants/constanst-valores";
import { obtenerLocalStorage } from "../../../resources/funciones";
import { DataContext } from "../../../context/DataContext";
import { useContext } from "react";

export const GrupoUsuarios = () => {

  const usuarioAutenticado = obtenerLocalStorage(CONST_KEY_USUARIO_AUTENTICADO);
  
  const { listaRutasDeAcceso } = useContext(DataContext)

  const navigate = useNavigate()

  const location = useLocation()

  const nombre_grupo = location?.state?.nombre_grupo
  const usuarios = location?.state?.usuarios
  const compania = location?.state?.compania
  const codigo_grupo = location?.state?.codigo_grupo

  const [listaUsuarios, setListaUsuarios] = useState(usuarios)

  //Estados para ver si mostrar o no los botones
  const [mostrarBotonGruposUsuariosAgregar, setMostrarBotonGruposUsuariosAgregar] = useState(false)

  const volver = () => {
    navigate(CONST_RUTA_GRUPOS_LISTAR)
  }

  const obtenerTodosLosUsuariosPorCompania = async (idCompania) => {

    const dataPOST = {
      compania: idCompania,
      token: usuarioAutenticado?.token
    }

    const { usuarios } = await usuarioListarTodosPorCompania(dataPOST)
   
    return usuarios
  }

  const agregarUsuariosAlGrupo = async () => {

    const rutaNavegar = CONST_RUTA_GRUPOS_USUARIOS_AGREGAR
    const modulo = rutasCodigosPrograma.find(r => r.ruta == rutaNavegar)
    const validarAutenticado = await validarAutenticacion(usuarioAutenticado?.token, modulo?.codigo)

    if (validarAutenticado) {
      navigate(rutaNavegar, {
        state: {
          nombre_grupo,
          codigo_grupo,
          compania
        }
      })
    } else {
      navigate(CONST_RUTA_SIN_ACCESO)
    }

  }

  const usuariosPorGrupoActivos = async () => {

    const dataPOST = {
      codigo_grupo,
      compania,
      token: usuarioAutenticado?.token
    }

    const { status, usuarios } = await gruposUsuariosPorGrupo(dataPOST)
    setListaUsuarios(usuarios)
  }

  useEffect(() => {
    usuariosPorGrupoActivos()

    const encontradoMostrarBotonGruposUsuariosAgregar = listaRutasDeAcceso?.find(r=> r.codigo_programa == "S0102060000")
    encontradoMostrarBotonGruposUsuariosAgregar ? setMostrarBotonGruposUsuariosAgregar(true) : setMostrarBotonGruposUsuariosAgregar(false)

  }, [listaRutasDeAcceso])

  return (
    <div className="p-3">
      <div className="container-fluid">
        <h3 className="text-center mt-1">Grupo - Usuarios</h3>

        <div className="mb-3">
          <label>Usuarios del Grupo: </label>
          <strong> {nombre_grupo}</strong>
        </div>


        <div className="col-10 mx-auto ps-0">

          <div className={mostrarBotonGruposUsuariosAgregar ? "d-flex justify-content-between" : "d-flex justify-content-end"}>
            {
              mostrarBotonGruposUsuariosAgregar && (
                <button className='btn btn-primario btn-sm align-middle d-flex justify-content-center align-items-center col-md-2' onClick={agregarUsuariosAlGrupo}>
                  <IoMdAdd size={18} />
                  <span className="ms-1">Agregar</span>
                </button>
              )}
            <button className='btn btn-sm btn-secundario col-md-2' onClick={volver}>Cancelar</button>

          </div>
        </div>


        {/* TABLA DE GRUPO - USUARIO */}
        <div className="col-10 mx-auto mt-4">
          <table className="table table-hover mt-3 bg-form">
            <thead className="table-light">
              <tr>
                <th scope="col" className="col-4 text-center">Usuario</th>
                <th scope="col" className="col-8 text-center">Nombre</th>
                {/* <th scope="col" className="col-2 text-center">Estado</th> */}
              </tr>
            </thead>
            <tbody>
              {
                listaUsuarios?.length > 0 ? listaUsuarios.map((g, index) => {

                  return (
                    <tr key={g?.UseCod}>
                      <td>{g?.UseCod}</td>
                      <td>{g?.UseNomCom}</td>
                      {/* <td>
                          <div className="form-check d-flex justify-content-center align-items-center">
                            <input className="form-check-input"
                              type="checkbox"
                              id={`myCheckbox${g?.UseCod}`}
                              // {...register(`modulos.${index}.estado`)}
                            />
                          </div>
                        </td> */}
                    </tr>
                  )
                }) :
                  (
                    <tr>
                      <td colSpan="2" className="text-center">No hay usuarios</td>
                    </tr>
                  )
              }
            </tbody>
          </table>
        </div>

        {/* FIN TABLA */}
      </div>
    </div>
  )
}
