import { api } from "./configApi"

export const moduloCrear = async (dataPOST) => {
    try {
        const res = await api.post("api/modulo/AgregarModulo", dataPOST)
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                modulo: res.data.Nombre,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                modulo: "",
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            modulo: "",
            message: "Error al registrar el usuario",
        }
    }
}

export const modulosBuscar = async (dataPOST) => {
    try {
        
        const res = await api.post("api/modulo/BuscarModulo", dataPOST)
   
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                modulos: res.data.resultado,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                modulos: [],
                message: error.response.data.message,
            };
        }
        return {
            status: -1,
            modulos: [],
            message: "Error en el servidor",
        }
    }
}

export const moduloDelete = async (dataPOST) => {
    try {

        const res = await api.post("api/modulo/EliminarModulo", dataPOST)
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
            }
        }
    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status, 
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        }
    }
}

export const moduloMostrar = async (dataPOST) => {
    try {
       
        const res = await api.post("api/modulo/MostrarModulo", dataPOST)
    
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                modulo: res.data.resultado[0],
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status, 
                modulo: {},
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            modulo: {},
            message: "Error en el servidor",
        }
    }
}

export const moduloEditar = async (dataPOST) => {
    try {
        const res = await api.post("api/modulo/EditarModulo", dataPOST)

        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status, 
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        }
    }
}

export const moduloSubirNivel = async (dataPOST) => {
    try {
        const res = await api.post("api/modulo/subirModulo", dataPOST)
   
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                modulos: res.data.resultado,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status, 
                modulos: [],
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            modulos: [],
            message: "Error en el servidor",
        }
    }
}
