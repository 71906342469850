import { useContext, useEffect, useState } from "react"
import { Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom'
import { Usuarios, UsuarioForm, UsuarioGrupos } from '../pages/Seguridad/Usuario'

import { Grupos, GrupoForm, GrupoUsuarios, GrupoPermisos, GrupoUsuariosAgregar } from '../pages/Seguridad/Grupo'
import { Modulos, ModuloForm } from '../pages/Seguridad/Modulo'
import { Companias, CompaniaForm } from '../pages/Seguridad/Compania'
import { SucursalForm, Sucursales } from '../pages/Seguridad/Sucursal'
import { Login } from '../pages/Seguridad/Auth'
import { Sedes, SedeForm } from '../pages/Seguridad/Sedes'
import { ParametroForm, Parametros } from '../pages/Seguridad/Parametro'

import { cambiarColores, obtenerLabel, obtenerLocalStorage } from '../resources/funciones'
import { ScrollToTop } from '../components/ScrollToTop'
import { CONST_KEY_USUARIO_AUTENTICADO, CONST_PARAMETRO_SISTEMA_SEGURIDAD } from '../constants/constanst-valores'
import { SinAcceso } from '../components/SinAcceso'
import { Error404 } from '../components/Error404'
import { verificarUsuario } from '../api/authApi'
import { CONST_RUTA_LOGIN, CONST_RUTA_SIN_ACCESO } from '../constants/constants-rutas-codigos'
import { DataContext } from '../context/DataContext'
import { InicioSeguridad } from '../components/InicioSeguridad'
import { rutasCodigosPrograma } from '../constants/constanst-arreglo-rutas-codigo'

import { ConexionExterna } from '../components/conexionExterna/ConexionExterna'
import { IniciandoSesion } from '../components/conexionExterna/IniciandoSesion'
import { SideBar } from "../components/layout"


const routesConfig = [
    {
        path: "/seguridad",
        component: InicioSeguridad,
    },
    {
        path: "/seguridad/usuarios/*",
        component: Usuarios,
        children: [
            {
                path: "agregar",
                component: UsuarioForm,
            },
            {
                path: "grupos",
                component: UsuarioGrupos,
            },
            {
                path: "editar",
                component: UsuarioForm,
            },
        ],
    },
    {
        path: "/seguridad/grupos/*",
        component: Grupos,
        children: [
            {
                path: "agregar",
                component: GrupoForm,
            },
            {
                path: "usuarios",
                component: GrupoUsuarios,
            },
            {
                path: "/usuarios-agregar",
                component: GrupoUsuariosAgregar,
            },
            {
                path: "permisos",
                component: GrupoPermisos,
            },
            {
                path: "editar",
                component: GrupoForm,
            },
        ],
    },
    {
        path: "/seguridad/modulos/*",
        component: Modulos,
        children: [
            {
                path: "agregar",
                component: ModuloForm,
            },
            {
                path: "editar",
                component: ModuloForm,
            },
        ],
    },
    {
        path: "/seguridad/companias/*",
        component: Companias,
        children: [
            {
                path: "agregar",
                component: CompaniaForm,
            },
            {
                path: "editar",
                component: CompaniaForm,
            },
        ],
    },
    {
        path: "/seguridad/sucursales/*",
        component: Sucursales,
        children: [
            {
                path: "agregar",
                component: SucursalForm,
            },
            {
                path: "editar",
                component: SucursalForm,
            },
        ],
    },
    {
        path: "/seguridad/sedes/*",
        component: Sedes,
        children: [
            {
                path: "agregar",
                component: SedeForm,
            },
            {
                path: "editar",
                component: SedeForm,
            },
        ],
    },
    {
        path: "/seguridad/parametros/*",
        component: Parametros,
        children: [
            {
                path: "agregar",
                component: ParametroForm,
            },
            {
                path: "editar",
                component: ParametroForm,
            },
        ],
    },
    // Agrega configuraciones para otros componentes aquí
];

export const AppRouter = () => {
    const usuarioAutenticado = obtenerLocalStorage(CONST_KEY_USUARIO_AUTENTICADO);

    //Obtener los estilos de la pagina de cada compania
    const estilosCompania = usuarioAutenticado?.estilo;

    const { getCompaniasYSedesPorUsuario, getRutasDeAcceso } = useContext(DataContext)

    const location = useLocation();

    const ruta = location.pathname.split("/").pop();

    const url_pagina = location?.pathname

    const [mostrarPaginaAutenticado, setMostrarPaginaAutenticado] = useState(false);

    const [isLoading, setIsLoading] = useState(true)

    const validarAutenticacion = async (token, url) => {

        if (url_pagina != "/") {
            setIsLoading(true)

            const modulo = rutasCodigosPrograma.find(r => r.ruta == url)
            const dataPOST = {
                token: token,
                codigo: modulo?.codigo || ""
            }

            // console.log('dataPOST autenticacion', dataPOST)

            const { autenticado } = await verificarUsuario(dataPOST)
            setIsLoading(false)
            setMostrarPaginaAutenticado(autenticado)
        } else {
            setMostrarPaginaAutenticado(true)
            setIsLoading(false)
        }
    }

    useEffect(() => {

        if (usuarioAutenticado?.token) {
            validarAutenticacion(usuarioAutenticado?.token, url_pagina)
            // getCompaniasYSedesPorUsuario(usuarioAutenticado?.token)
            getRutasDeAcceso(usuarioAutenticado?.token, CONST_PARAMETRO_SISTEMA_SEGURIDAD)
        }

    }, [url_pagina])

    useEffect(() => {
        // Guardar los colores al cargar la pagina
        if (estilosCompania) {
            const colores = [
                { name: '--color-fondo-menu-primario', color: estilosCompania?.fondo_menu },
                { name: '--color-texto-nav-item', color: estilosCompania?.text_opciones },
                { name: '--color-fondo-nav-item-activo', color: estilosCompania?.fond_opc_activo },
                { name: '--color-texto-nav-item-activo', color: estilosCompania?.text_opc_activo },
                { name: '--color-button-primario', color: estilosCompania?.boton_primario },
                { name: '--color-button-primario-hover', color: estilosCompania?.boton_primario_activo },
                { name: '--color-button-primario-text', color: estilosCompania?.text_boton_primario },
                { name: '--color-button-secundario', color: estilosCompania?.boton_secundario },
                { name: '--color-button-secundario-hover', color: estilosCompania?.boton_secundario_activo },
                { name: '--color-button-secundario-text', color: estilosCompania?.text_boton_secundario },
            ];
            cambiarColores(colores);
        } else {
            // const colores = [
            //     { name: '--color-fondo-menu-primario', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_FONDO_MENU_PRIMARIO },
            //     { name: '--color-texto-nav-item', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_TEXTO_NAV_ITEM },
            //     { name: '--color-fondo-nav-item-activo', color: configColoresCompaniaSUPERADMIN?.CONST_FONDO_NAV_ITEM_ACTIVO },
            //     { name: '--color-texto-nav-item-activo', color: configColoresCompaniaSUPERADMIN?.CONST_TEXTO_NAV_ITEM_ACTIVO },
            //     { name: '--color-button-primario', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_PRIMARIO },
            //     { name: '--color-button-primario-hover', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_PRIMARIO_HOVER },
            //     { name: '--color-button-primario-text', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_PRIMARIO_TEXT },
            //     { name: '--color-button-secundario', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_SECUNDARIO },
            //     { name: '--color-button-secundario-hover', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_SECUNDARIO_HOVER },
            //     { name: '--color-button-secundario-text', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_SECUNDARIO_TEXT},
            // ];
            // cambiarColores(colores);
        }

        // getCompaniasYSedesPorUsuario(usuarioAutenticado?.token)
    }, [])

    return (usuarioAutenticado?.token != null) ? (
        (mostrarPaginaAutenticado) ? (
            <>
                <ScrollToTop />
                <SideBar ruta={ruta}>
                    <Routes>
                        <Route path={CONST_RUTA_LOGIN} element={<Login />} />
                        {routesConfig.map((route, index) => (
                            <Route
                                key={index}
                                path={route.path}
                                element={
                                    <Routes>
                                        <Route path="/" element={<route.component />} />
                                        {route.children &&
                                            route.children.map((childRoute, childIndex) => {
                                                return (
                                                    <Route
                                                        key={childIndex}
                                                        path={childRoute?.path}
                                                        element={<childRoute.component />}
                                                    />)
                                            })}
                                        <Route path="/*" element={<SinAcceso />} />
                                    </Routes>
                                }
                            />
                        ))}

                        <Route path="/*" element={<SinAcceso />} />
                    </Routes>
                </SideBar>

            </>
        ) : (
            <>
                <ScrollToTop />
                <SideBar ruta={ruta}>
                    {isLoading ? (
                        <div className="h-100 d-flex justify-content-center align-items-center">
                            <div className="spinner-border text-light" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    ) : <SinAcceso />}
                </SideBar>
            </>
        )
    ) : (
        <>

            <ScrollToTop />
            <Routes>
                <Route path={CONST_RUTA_LOGIN} element={<Login />} />

                <Route path="/conexionExterna/:token" element={<ConexionExterna />}></Route>
                <Route path="/conectando" element={<IniciandoSesion />}></Route>
                <Route path={CONST_RUTA_SIN_ACCESO} element={<SinAcceso />} />
                <Route path="/*" element={<Navigate to="/" />} />
            </Routes>

        </>

    )

};

