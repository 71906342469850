import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { grupoModuloSubirNivel, gruposPermisosAgregar, gruposPermisosListar } from '../../../api/grupoApi'
import { useState } from 'react'
import { LuArrowUpNarrowWide } from "react-icons/lu"

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useEffect } from 'react'
import { obtenerLocalStorage } from '../../../resources/funciones'
import { CONST_KEY_USUARIO_AUTENTICADO } from '../../../constants/constanst-valores'
import { CONST_RUTA_GRUPOS_LISTAR } from '../../../constants/constants-rutas-codigos'

export const GrupoPermisos = () => {

    const usuarioAutenticado = obtenerLocalStorage(CONST_KEY_USUARIO_AUTENTICADO)

    const [mensaje, setMensaje] = useState('')
    const [mostrar, setMostrar] = useState(false)
    const [tipoAlerta, setTipoAlerta] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()
    const location = useLocation();

    const [listaModulos, setListaModulos] = useState([])
    const [nombreGrupo, setNombreCompra] = useState(location?.state?.nombreGrupo || "")
    const [compania, setCompania] = useState(location?.state?.compania || "")
    const [grupo, setGrupo] = useState(location?.state?.grupo || "")

    const [moduloSuperior, setModuloSuperior] = useState([0])

    const { register, handleSubmit, setValue, watch, reset } = useForm({
        defaultValues: {
            superior: moduloSuperior || "",
        }
    });

    const onSubmitGuardar = async (data) => {

        const formatearModulosConEstado = data.modulos
            .filter((m) => m?.codigo !== undefined)
            .map((m) => ({
                cod_modulo: m.codigo,
                estado: m.estado ? "A" : "I",
            }));

        const dataPOST = {
            cod_grupo: grupo,
            compania: compania,
            modulos: formatearModulosConEstado,
            token: usuarioAutenticado?.token
        }

        const { status, message } = await gruposPermisosAgregar(dataPOST)

        setMostrar(true)
        setMensaje(message)
        status == 1 ? setTipoAlerta("success") : setTipoAlerta("warning")

    }

    const volver = () => {
        navigate(CONST_RUTA_GRUPOS_LISTAR)
    }

    const moduloListarSubModulos = async (superior) => {

        if (grupo != "") {
            setIsLoading(true)
            const dataPOST = {
                compania,
                superior: superior,
                grupo,
                tip_usuario: usuarioAutenticado?.tipo,
                token: usuarioAutenticado?.token

            }

            const { status, modulos } = await gruposPermisosListar(dataPOST)

            if (modulos.length > 0) {
                setModuloSuperior([...moduloSuperior, superior])
                const formatearModulosConEstado = modulos.map(m => {
                    return {
                        ...m,
                        estado: m?.estado == "A" ? true : false
                    }
                })

                reset({
                    modulos: formatearModulosConEstado
                })

                setListaModulos(formatearModulosConEstado)
            }
            setIsLoading(false)
        } else {
            navigate(CONST_RUTA_GRUPOS_LISTAR)
        }

    }

    const moduloRetroceder = async () => {

        if (moduloSuperior.length > 1) {
            setIsLoading(true)
            let moduloSubir = moduloSuperior.pop();

            const dataPOST = {
                compania: compania,
                superior: moduloSubir,
                grupo: grupo,
                tip_usuario: usuarioAutenticado?.tipo,
                token: usuarioAutenticado?.token
            }

            const { status, modulos } = await grupoModuloSubirNivel(dataPOST)

            const formatearModulosConEstado = modulos.map(m => {
                return {
                    codigo: m?.codigo,
                    desc: m?.desc,
                    estado: m?.estado == "A" ? true : false
                }
            })

            reset({
                modulos: formatearModulosConEstado
            })
            setIsLoading(false)
            setListaModulos(formatearModulosConEstado)
        }

    }

    const obtenerModulos = async () => {

        if (grupo != "") {
            setIsLoading(true)
            const dataPOST = {
                compania: compania,
                grupo: grupo,
                superior: moduloSuperior,
                tip_usuario: usuarioAutenticado?.tipo,
                token: usuarioAutenticado?.token

            }

            const { status, modulos } = await gruposPermisosListar(dataPOST)

            const formatearModulosConEstado = modulos.map(m => {
                return {
                    codigo: m?.codigo,
                    desc: m?.desc,
                    estado: m?.estado == "A" ? true : false
                }
            })

            reset({
                modulos: formatearModulosConEstado
            })
            setIsLoading(false)
            setListaModulos(formatearModulosConEstado)
        } else {
            navigate('/seguridad/grupos')
        }
    }

    useEffect(() => {
        obtenerModulos()
    }, [])

    return (
        <div className="p-3">
            <div className="container-fluid">
                <h3 className="text-center mt-1">Grupo - Permisos</h3>

                <strong>Grupo: </strong>
                <label>{nombreGrupo}</label>

                {/* TABLA DE USUARIO - GRUPO */}

                <div className='col-12 col-md-10 text-end mx-auto'>
                    {/* <button className='btn btn-sm btn-secondary' onClick={moduloRetroceder}>Retroceder</button> */}
                    <button className="btn btn-light btn-sm" onClick={moduloRetroceder} style={{ cursor: 'pointer' }}><LuArrowUpNarrowWide size={25} className="color-iconos" /></button>
                </div>

                <div className="col-12 col-md-10 mx-auto table-responsive">
                    <table className="table table-hover mt-3 bg-form">
                        <thead className="table-light">
                            <tr>
                                <th scope="col" className="col-2 text-center">Programa</th>
                                <th scope="col" className="col-7 text-center">Módulo</th>
                                <th scope="col" className="col-3 text-center">Permiso</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                isLoading ? (
                                    <tr>
                                        <td colSpan="3" className="text-center">
                                            <div className="spinner-border text-secondary spinner-border-sm" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </td>
                                    </tr>

                                ) : (
                                    (listaModulos?.length > 0) ? listaModulos.map((g, index) => (

                                            <tr key={g?.codigo}>
                                                <td>{g?.codigo}</td>
                                                <td><Link onClick={() => moduloListarSubModulos(g?.codigo)} style={{ textDecoration: "none" }}>{g?.desc}</Link></td>
                                                <td>
                                                    <div className="form-check d-flex justify-content-center align-items-center">
                                                        <input className="form-check-input"
                                                            type="checkbox"
                                                            id={`myCheckbox${g?.codigo}`}
                                                            {...register(`modulos.${index}.estado`)}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        
                                    )) :(
                                       <tr colSpan="3" >No hay grupos</tr>
                                    )
                                )
                            }
                        </tbody>
                    </table>
                </div>
                {/* FIN TABLA */}

                {/* <div className='row justify-content-center mt-3'>
                    <div className='col-2 text-center'>
                        <button className='btn btn-sm btn-primario' onClick={handleSubmit(onSubmitGuardar)}>Guardar</button>
                    </div>
                    <div className='col-2 text-center'>
                        <button className='btn btn-sm btn-secundario' onClick={volver}>Cancelar</button>
                    </div>
                </div> */}

                <div className='mt-3 botones-accion text-center'>

                    <button className='btn btn-sm btn-primario col-md-2' onClick={handleSubmit(onSubmitGuardar)}>Guardar</button>


                    <button className='btn btn-sm btn-secundario col-md-2' onClick={volver}>Cancelar</button>

                </div>

                {
                    mostrar && (
                        <div className="row mt-4">
                            <div className="col-lg-6 mx-auto text-center alert-error">
                                <Stack spacing={2}>
                                    <Alert variant="outlined" severity={tipoAlerta} >{mensaje}</Alert>
                                </Stack>
                            </div>
                        </div>

                    )
                }
            </div>
        </div>
    )
}
