import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

//Iconos
import { FcKey } from 'react-icons/fc';
import { RiTeamFill } from 'react-icons/ri';
import { FaRegEdit } from 'react-icons/fa'
import { RiDeleteBinFill } from 'react-icons/ri'
import { IoMdAdd } from "react-icons/io"
import { BiSearch } from "react-icons/bi"

import Swal from 'sweetalert2';
import { useContext, useEffect, useState } from "react";
import { usuariosBuscar, usuarioDelete, usuarioMostrar, grupoMostrarGruposPorUsuario, usuarioObtenerCompaniasYSedes, usuarioObtenerCompaniasYSucursales } from "../../../api/usuarioApi";
import { DataContext } from "../../../context/DataContext";
import { listaEstadosActivo, listaEstadosActivoBusqueda, listaPermisos } from "../../../constants/constants-selectores";
import { obtenerLabel, obtenerLocalStorage } from "../../../resources/funciones";
import { sedesPorCompania } from "../../../api/sedeApi";
import { useMobileDetection } from "../../../hook/useMobileDetection";
import { CONST_KEY_USUARIO_AUTENTICADO, CONST_ROL_MASTER } from "../../../constants/constanst-valores";
import { validarAutenticacion } from "../../../resources/funcion-validar-autenticacion";
import { CONST_CODIGO_USUARIOS_AGREGAR, CONST_CODIGO_USUARIOS_EDITAR, CONST_CODIGO_USUARIOS_ELIMINAR, CONST_CODIGO_USUARIOS_GRUPOS, CONST_RUTA_SIN_ACCESO, CONST_RUTA_USUARIOS_AGREGAR, CONST_RUTA_USUARIOS_EDITAR, CONST_RUTA_USUARIOS_GRUPOS } from "../../../constants/constants-rutas-codigos";
import { sucursalesPorCompania } from "../../../api/sucursalApi";

export const Usuarios = () => {

  const { listaRutasDeAcceso } = useContext(DataContext);

  const [listaCompanias, setListaCompanias] = useState([])
  const [listaSucursales, setListaSucursales] = useState([])
  const [listaSedes, setListaSedes] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const usuarioAutenticado = obtenerLocalStorage(CONST_KEY_USUARIO_AUTENTICADO);

  // console.log('usuarioAutenticado',usuarioAutenticado)
  const rol = usuarioAutenticado?.tipo

  //Estados para ver si mostrar o no los botones
  const [mostrarBotonAgregar, setMostrarBotonAgregar] = useState(false)
  const [mostrarBotonEditar, setMostrarBotonEditar] = useState(false)
  const [mostrarBotonEliminar, setMostrarBotonEliminar] = useState(false)
  const [mostrarBotonUsuarioGrupo, setMostrarBotonUsuarioGrupo] = useState(false)

  //Obtener datos para la busqueda rapida
  const location = useLocation();
  const datosParaBusquedaRapida = location?.state || null

  const isMobile = useMobileDetection()

  const { register, handleSubmit, watch, formState: { errors }, reset, setValue } = useForm({
    defaultValues: {
      NombreCompleto: "",
      Estado: "A",
    }
  });


  const [listaUsuarios, setListaUsuarios] = useState([])
  const [buscar, setBuscar] = useState(false)

  const [mostrarOpcionTodos, setMostrarOpcionTodos] = useState(true)

  const onSubmitBuscar = async (data) => {

    setIsLoading(true)

    const dataPOST = {
      Compania: data?.compania,
      Estado: data?.Estado,
      NombreCompleto: data?.NombreCompleto,
      Sede: data?.Sede || "T",
      Sucursal: data?.sucursal,
      token: usuarioAutenticado?.token
    }
    
    const { usuarios } = await usuariosBuscar(dataPOST)

    setListaUsuarios(usuarios)
    setBuscar(true)
    setIsLoading(false)
  };

  const navigate = useNavigate()

  const usuarioAgregar = async () => {

    const validarAutenticado = await validarAutenticacion(usuarioAutenticado?.token, CONST_CODIGO_USUARIOS_AGREGAR)
    if (validarAutenticado) {
      navigate(CONST_RUTA_USUARIOS_AGREGAR)
    } else {
      navigate(CONST_RUTA_SIN_ACCESO)
    }
  }

  const usuarioGrupo = async (compania, nombreUsuario, codUsuario) => {

    const validarAutenticado = await validarAutenticacion(usuarioAutenticado?.token, CONST_CODIGO_USUARIOS_GRUPOS)

    if (validarAutenticado) {
      const dataPOST = {
        codigo_usuario: codUsuario,
        compania: compania,
        token: usuarioAutenticado?.token
      }

      const { grupos } = await grupoMostrarGruposPorUsuario(dataPOST)

      navigate(CONST_RUTA_USUARIOS_GRUPOS, {
        state: {
          grupos: grupos,
          nombreUsuario: nombreUsuario,
          codUsuario: codUsuario,
          compania: compania
        }
      })
    } else {
      navigate(CONST_RUTA_SIN_ACCESO)
    }

  }

  const usuarioPermisos = () => {
    navigate('/seguridad/usuarios/permisos')
  }

  const usuarioEditar = async (UseCod) => {

    const validarAutenticado = await validarAutenticacion(usuarioAutenticado?.token, CONST_CODIGO_USUARIOS_EDITAR)

    if (validarAutenticado) {

      const dataPOST = {
        usuario: UseCod,
        token: usuarioAutenticado?.token
      }
      const { usuario } = await usuarioMostrar(dataPOST)

      navigate(CONST_RUTA_USUARIOS_EDITAR, {
        state: usuario
      })
    } else {
      navigate(CONST_RUTA_SIN_ACCESO)
    }
  }

  const optionsEliminar = (nombreCompleto) => {
    return {
      title: `¿Estás seguro de eliminar al usuario: ${nombreCompleto}?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#6c757d',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
    }
  }

  const usuarioEliminar = (usuario, nombreCompleto) => {
    Swal.fire(optionsEliminar(nombreCompleto)).then(async (result) => {
      if (result.isConfirmed) {

        const dataPOST = {
          usuario: usuario,
          token: usuarioAutenticado?.token
        }

        const { status } = await usuarioDelete(dataPOST)
        if (status == 1) {
          obtenerUsuarios()
          Swal.fire(
            'Usuario eliminado',
            '',
            'success'
          )
        } else {
          Swal.fire(
            'Error al eliminar usuario',
            '',
            'success'
          )
        }
      }
    })
  }

  const obtenerUsuarios = async () => {

    setIsLoading(true)

    const dataPOST = {
      // UseCod: watch("UseCod") || "",
      NombreCompleto: "",
      Compania: watch("compania"),
      Sucursal: watch("sucursal"),
      Estado: watch("Estado") || "A",
      Sede: watch("Sede"),
      token: usuarioAutenticado?.token
    }

    const { usuarios } = await usuariosBuscar(dataPOST)

    setListaUsuarios(usuarios)
    setIsLoading(false)
    setBuscar(true)

  }

  // const usuarioPassword = (idUsuario) => {
  //   const usuarioEncontrado = listaUsuarios.find(usuario => usuario.UseCod === idUsuario)
  //   setDatosUsuario({
  //     nombreCompleto: usuarioEncontrado?.UseNomCom || "",
  //     usuario: usuarioEncontrado?.UseCod || "",
  //     password: usuarioEncontrado?.UsePsw || ""
  //   })
  // }

  const obtenerCompaniasYSucursales = async () => {

    const dataPOST = {
      token: usuarioAutenticado?.token
    }

    const { companias, sucursales, sedes } = await usuarioObtenerCompaniasYSucursales(dataPOST)

    setListaCompanias(companias)
    setListaSucursales(sucursales)
    setValue("compania", datosParaBusquedaRapida == null ? companias?.[0]?.id : datosParaBusquedaRapida?.codCompania)

    return {
      companias,
      sucursales,
      sedes
    }
  }

  const obtenerSucursalesPorCompaniaPorDefecto = async (idCompania) => {

    const dataPOST = {
      token: usuarioAutenticado?.token,
      Codigo: idCompania,
      Estado: "A"
    }

    const { sucursales } = await sucursalesPorCompania(dataPOST)

    const listaSucursalesFormateada = sucursales?.map(s => ({
      id: s?.CompCod,
      tipo: s?.CompNom
    }))

    setListaSucursales(listaSucursalesFormateada)
    setValue("sucursal", datosParaBusquedaRapida == null ? sucursales?.[0]?.CompCod : datosParaBusquedaRapida?.codSucursal)

  }

  const obtenerSedesPorSucursalPorDefecto = async (idSucursal) => {

    const dataPOST = {
      compania: idSucursal,
      token: usuarioAutenticado?.token
    }

    const { sedes } = await sedesPorCompania(dataPOST)

    setListaSedes(sedes)
    setValue("Sede", datosParaBusquedaRapida == null ? (sedes?.[0]?.SedCod || "T") : datosParaBusquedaRapida?.codSede)

  }

  const obtenerSucursalesPorCompania = async (idCompania) => {

    const dataPOST = {
      token: usuarioAutenticado?.token,
      Codigo: idCompania,
      Estado: "A"
    }


    const { sucursales } = await sucursalesPorCompania(dataPOST)

    const listaSucursalesFormateada = sucursales?.map(s => ({
      id: s?.CompCod,
      tipo: s?.CompNom
    }))

    setListaSucursales(listaSucursalesFormateada)

    setValue("compania", idCompania)
    setValue("sucursal", sucursales?.[0]?.CompCod)

  }

  const obtenerSedesPorSucursal = async (idCompania) => {

    const dataPOST = {
      compania: idCompania,
      token: usuarioAutenticado?.token
    }

    const { sedes } = await sedesPorCompania(dataPOST)
    
    setListaSedes(sedes)
    setValue("sucursal", idCompania)
    // datosParaBusquedaRapida ya tiene datosParaBusquedaRapida?.codSede
    setValue("Sede", sedes?.[0]?.SedCod)

  }

  useEffect(() => {

    const encontradoRutaBotonAgregar = listaRutasDeAcceso?.find(r => r.codigo_programa == CONST_CODIGO_USUARIOS_AGREGAR)
    const encontradoRutaBotonEditar = listaRutasDeAcceso?.find(r => r.codigo_programa == CONST_CODIGO_USUARIOS_EDITAR)
    const encontradoRutaBotonEliminar = listaRutasDeAcceso?.find(r => r.codigo_programa == CONST_CODIGO_USUARIOS_ELIMINAR)
    const encontradoRutaBotonUsuarioGrupo = listaRutasDeAcceso?.find(r => r.codigo_programa == CONST_CODIGO_USUARIOS_GRUPOS)

    encontradoRutaBotonAgregar ? setMostrarBotonAgregar(true) : setMostrarBotonAgregar(false)
    encontradoRutaBotonEditar ? setMostrarBotonEditar(true) : setMostrarBotonEditar(false)
    encontradoRutaBotonEliminar ? setMostrarBotonEliminar(true) : setMostrarBotonEliminar(false)
    encontradoRutaBotonUsuarioGrupo ? setMostrarBotonUsuarioGrupo(true) : setMostrarBotonUsuarioGrupo(false)

  }, [listaRutasDeAcceso])


  useEffect(() => {

    const cargarDatosIniciales = async () => {
      const { companias, sucursales, sedes } = await obtenerCompaniasYSucursales()
      if (rol == CONST_ROL_MASTER) {
        await obtenerSucursalesPorCompaniaPorDefecto(watch("compania"));
      } else {
        setListaSucursales(sucursales)
        setValue("sucursal", sucursales?.[0]?.id)
      }
      if(sucursales?.length>1){
        await obtenerSedesPorSucursalPorDefecto(watch("sucursal"));
      }else{
         const listaSedesFormateada = sedes?.map(s => ({
          SedCod: s?.id,
          SedDesc: s?.tipo
        }))
        setListaSedes(listaSedesFormateada)
        setValue("Sede", datosParaBusquedaRapida == null ? (listaSedesFormateada?.[0]?.SedCod || "T") : datosParaBusquedaRapida?.codSede)
        setMostrarOpcionTodos(false)
      }
      
      await obtenerUsuarios()
    };

    cargarDatosIniciales();
  }, [])

  return (
    <>
      <div className="p-3">
        <div className="container-fluid" >
          <h3 className="text-center mt-1">Usuarios</h3>

          {/* Formulario */}
          <div className="my-3 border p-1 rounded-5 bg-form">
            <form onSubmit={handleSubmit(onSubmitBuscar)} className="row p-1">

              {/* <div className="col-12 col-lg-2">
                <label className="text-start">Usuario</label>
                <input
                  {...register("UseCod")}
                  type="text"
                  className="form-control form-control-sm"
                  autoComplete="off"
                  placeholder="Usuario"
                />
              </div> */}
              <div className="col-12 col-lg-2">
                <label className="text-start">Compañía</label>
                <select
                  {...register("compania", {
                    onChange: async (e) => {
                      await obtenerSucursalesPorCompania(e.target.value)
                      await obtenerSedesPorSucursal(watch("sucursal"))
                    },
                    required: true
                  })}
                  className="form-select form-select-sm" value={watch("compania")}>
                  {
                    listaCompanias?.length > 0 && listaCompanias.map(e => (
                      <option key={e?.id} value={e?.id}>{e?.tipo}</option>
                    ))
                  }
                </select>
              </div>
              <div className="col-12 col-lg-2">
                <label className="text-start">Sucursal</label>
                <select
                  {...register("sucursal", {
                    onChange: async (e) => { await obtenerSedesPorSucursal(e.target.value) },
                    required: true
                  })}
                  className="form-select form-select-sm"
                  value={watch("sucursal")}
                >

                  {
                    listaSucursales?.length > 0 && listaSucursales.map(e => (
                      <option key={e?.id} value={e?.id}>{e?.tipo}</option>
                    ))
                  }
                  {/* <option value={"T"}>TODOS</option> */}
                </select>
              </div>
              <div className="col-12 col-lg-3">
                <label className="text-start">Sede</label>
                <select 
                  {...register("Sede", {
                    required: true
                  })} 
                  className="form-select form-select-sm" 
                  value={watch("Sede")}
                >

                  {
                    listaSedes?.length > 0 && listaSedes.map(e => (
                      <option key={e?.SedCod} value={e?.SedCod}>{e?.SedDesc}</option>
                    ))
                  }
                  {
                    listaSedes.length > 1 && <option value={"T"}>TODOS</option>
                  }
                </select>
              </div>
              <div className="col-12 col-lg-2">
                <label className="text-start">Nombres</label>
                <input
                  {...register("NombreCompleto")}
                  type="text"
                  className="form-control form-control-sm"
                  autoComplete="off"
                  placeholder="Nombres"
                />
              </div>


              <div className="col-12 col-lg-2">
                <label className="text-start">Estado</label>
                <select {...register("Estado")} className="form-select form-select-sm">
                  {
                    listaEstadosActivoBusqueda?.length > 0 && listaEstadosActivoBusqueda.map(e => (
                      <option key={e?.id} value={e?.id}>{e?.tipo}</option>
                    ))
                  }
                </select>
              </div>

              <div className="col-12 col-lg-1">
                <label className="text-start">&nbsp;</label>
                <div className={isMobile ? "text-center" : ""}>
                  <button type="submit" onClick={handleSubmit(onSubmitBuscar)} style={{ cursor: 'pointer', background: 'none', border: 'none' }}>
                    <BiSearch size={25} className="color-iconos" />
                  </button>
                </div>
              </div>
            </form>
          </div >
          {/* Fin formulario */}

          {
            mostrarBotonAgregar && (
              <div className="mx-auto ps-0">
                <button className='btn btn-primario btn-sm d-flex align-items-center' onClick={usuarioAgregar}>
                  <IoMdAdd size={18} />
                  <span className="ms-1">Agregar</span>
                </button>
              </div>
            )
          }


          {/* Tabla de resultados */}

          <div className="col-12 table-responsive mt-2">
            <table className="table table-hover mt-2">
              <thead className="table-light">
                <tr>
                  <th scope="col" className="col text-center">Usuario</th>
                  <th scope="col" className="col text-center">Nombre Completo</th>
                  <th scope="col" className="col text-center">Sucursal</th>
                  <th scope="col" className="col text-center">Sede</th>
                  <th scope="col" className="col text-center">Estado</th>
                  {/* <th scope="col" className="col text-center">Clave</th> */}
                  {
                    mostrarBotonUsuarioGrupo && (
                      <th scope="col" className="col text-center">Grupo</th>
                    )

                  }
                  {
                    mostrarBotonEditar && (
                      <th scope="col" className="col text-center">Editar</th>
                    )
                  }
                  {
                    mostrarBotonEliminar && (
                      <th scope="col" className="col text-center">Eliminar</th>
                    )
                  }

                </tr>
              </thead>
              <tbody>

                {
                  isLoading ? (
                    <tr>
                      <td colSpan="100" className="text-center">
                        <div className="spinner-border text-secondary spinner-border-sm" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </td>
                    </tr>

                  ) : (
                    listaUsuarios?.length > 0 ? listaUsuarios.map((u) => (
                      <tr key={u?.UseCod}>
                        <td>{u?.UseCod}</td>
                        <td>{u?.UseNomCom}</td>
                        <td>{obtenerLabel(listaSucursales,u?.UseComp)}</td>
                        <td>{u?.TMSED10?.SedDesc}</td>
                        <td>{obtenerLabel(listaEstadosActivo, u?.UseEst)}</td>
                        {/* <td><FcKey size={23} cursor={'pointer'} data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => usuarioPassword(u?.UseCod)} /></td> */}
                        {
                          mostrarBotonUsuarioGrupo && (
                            <td><RiTeamFill size={23} cursor={'pointer'} className="color-iconos" onClick={() => usuarioGrupo(watch("sucursal"), u?.UseNomCom, u?.UseCod)} /></td>
                          )
                        }

                        {/* <td><RiUserSettingsLine size={23} cursor={'pointer'} color="#27AE60" onClick={usuarioPermisos} /></td> */}
                        {
                          mostrarBotonEditar && (
                            <td><FaRegEdit size={23} cursor={'pointer'} className="color-iconos" onClick={() => usuarioEditar(u?.UseCod)} /></td>
                          )
                        }
                        {
                          mostrarBotonEliminar && (
                            <td><RiDeleteBinFill size={23} cursor={'pointer'} color="#C0392B" onClick={() => usuarioEliminar(u?.UseCod, u?.UseNomCom)} /></td>
                          )
                        }
                      </tr>
                    )) :
                      (
                        (buscar) ? (
                          <tr>
                            <td colSpan="100" className="text-center">No hay usuarios</td>
                          </tr>
                        ) : (
                          <tr>
                            <td colSpan="100" className="text-center">Realice una búsqueda</td>
                          </tr>
                        )
                      )
                  )
                }
              </tbody>
            </table>
          </div>


          {/* Fin Tabla de resultados */}
        </div>

      </div>

    </>
  )
};

