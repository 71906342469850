import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { listaEstadosActivo, listaPermisos, listaPrivilegios, listaSexo } from "../../../constants/constants-selectores";
import { DataContext } from "../../../context/DataContext";
import { CONST_DNI, CONST_KEY_USUARIO_AUTENTICADO, CONST_ROL_MASTER } from "../../../constants/constanst-valores";
import { usuarioCrear, usuarioEditar, usuarioObtenerCompaniasYSucursales } from "../../../api/usuarioApi";

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { sedesPorCompania } from "../../../api/sedeApi";
import { obtenerLabel, obtenerLocalStorage } from "../../../resources/funciones";
import { CONST_RUTA_USUARIOS_LISTAR } from "../../../constants/constants-rutas-codigos";
import { sucursalesPorCompania } from "../../../api/sucursalApi";

export const UsuarioForm = () => {

    const { listaTipoDocumento } = useContext(DataContext)

    const usuarioAutenticado = obtenerLocalStorage(CONST_KEY_USUARIO_AUTENTICADO)

    const rol = usuarioAutenticado?.tipo

    const [listaCompanias, setListaCompanias] = useState([])
    const [listaSucursales, setListaSucursales] = useState([])
    const [listaSedes, setListaSedes] = useState([])

    const [mensaje, setMensaje] = useState('')
    const [mostrar, setMostrar] = useState(false)
    const [tipoAlerta, setTipoAlerta] = useState("")

    //Obtener la accion a partir de la url (agregar o editar)
    const location = useLocation();
    const pathname = location.pathname;

    const usuario = location?.state || {}

    const accion = pathname.split("/").pop()

    const { register, handleSubmit, watch, formState: { errors }, reset, setValue } = useForm({
        defaultValues: {
            usuario: usuario?.UseCod || "",
            num_ide: usuario?.UseIdeNro || "",
            nombre: usuario?.UseNom || "",
            ape_pat: usuario?.UseApePat || "",
            ape_mat: usuario?.UseApeMat || "",
            telefono: usuario?.UseTel || "",
            celular: usuario?.UseCel || "",
            sexo: usuario?.UseSex || "M",
            email_lab: usuario?.UseEmailLab || "",
            email: usuario?.UseEmail || "",
            // permiso: usuario?.UsePermi || "G",
            privilegio: usuario?.UsePriv || "N",
            estado: usuario?.UseEst || "A",
        },
    });

    const onSubmitCrear = async (data) => {

        const dataPOST = {
            ...data,
            ape_pat: data?.ape_pat?.toUpperCase().trim() || "",
            ape_mat: data?.ape_mat?.toUpperCase().trim() || "",
            celular: data?.celular?.toUpperCase().trim() || "",
            compania_pad: data?.compania || "",
            compania: data?.sucursal || "",
            email_lab: data?.email_lab.trim() || "",
            email: data?.email.trim() || "",
            estado: data?.estado || "",
            nombre: data?.nombre?.toUpperCase().trim() || "",
            num_ide: data?.num_ide?.toUpperCase().trim() || "",
            privilegio: rol == CONST_ROL_MASTER ? data?.privilegio : "N",
            sede: data?.sede || "",
            sexo: data?.sexo || "",
            telefono: data?.telefono?.toUpperCase().trim() || "",
            tipo_ide: data?.tipo_ide || "",
            token: usuarioAutenticado?.token,
            usuario: data?.usuario?.toUpperCase().trim() || "",
        }

        const { status, message } = await usuarioCrear(dataPOST)

        setMostrar(true)
        setMensaje(message)
        // status == 1 ? setTipoAlerta("success") : setTipoAlerta("warning")
        if(status == 1) {
            setTipoAlerta("success")
            navigate(CONST_RUTA_USUARIOS_LISTAR, {
                state: {
                    codCompania: watch("compania"),
                    codSucursal: watch("sucursal"),
                    codSede: watch("sede"),
                }
            })
        }else{
            setTipoAlerta("warning")
        }
    }

    const onSubmitEditar = async (data) => {

        const dataPOST = {
            ...data,
            num_ide: data?.num_ide.toUpperCase().trim() || "",
            nombre: data?.nombre.toUpperCase().trim() || "",
            ape_pat: data?.ape_pat.toUpperCase().trim() || "",
            ape_mat: data?.ape_mat.toUpperCase().trim() || "",
            telefono: data?.telefono.toUpperCase().trim() || "",
            celular: data?.celular.toUpperCase().trim() || "",
            sexo: data?.sexo || "",
            email_lab: data?.email_lab.trim() || "",
            email: data?.email.trim() || "",
            compania_pad: usuario?.UseCompPad || "",
            compania: usuario?.UseComp || "",
            sede: usuario?.SedCod || "",
            // Se crea dos atributos porque el atributo usuario es una llave primaria de la tabla usuario
            // y para actualizar una llave primaria se necesita tanto el usaurio anterior y el nuevo usuario actualizado
            usuario: usuario?.UseCod || "",
            usuario_nuevo: data?.usuario?.toUpperCase() || "",
            token: usuarioAutenticado?.token

        }

        const { status, message } = await usuarioEditar(dataPOST)
        setMostrar(true)
        setMensaje(message)

        status == 1 ? setTipoAlerta("success") : setTipoAlerta("warning")
    }

    const navigate = useNavigate();

    const cancelar = () => {
        navigate(CONST_RUTA_USUARIOS_LISTAR, {
            state: {
                codCompania: watch("compania"),
                codSucursal: watch("sucursal"),
                codSede: watch("sede"),
            }
        })
    }

    const obtenerCompaniasYSucursales = async () => {

        const dataPOST = {
            token: usuarioAutenticado?.token
        }

        const { companias, sucursales, sedes } = await usuarioObtenerCompaniasYSucursales(dataPOST)
        setListaCompanias(companias)
        setListaSucursales(sucursales)
        setValue("compania", usuario?.UseCompPad || companias?.[0]?.id)

        return {
            companias,
            sucursales,
            sedes
        }
    }

    const obtenerSucursalesPorCompania = async (idCompania) => {

        if (idCompania) {
            const dataPOST = {
                token: usuarioAutenticado?.token,
                Codigo: idCompania,
                Estado: "A"
            }
            const { sucursales } = await sucursalesPorCompania(dataPOST)
            setListaSucursales(sucursales)
            setValue("sucursal", usuario?.UseComp || sucursales?.[0]?.CompCod)
        }
    }

    const obtenerSedesPorSucursal = async (idCompania) => {

        if (idCompania) {
            const dataPOST = {
                compania: idCompania,
                token: usuarioAutenticado?.token
            }
            const { sedes } = await sedesPorCompania(dataPOST)

            setListaSedes(sedes)
            setValue("sede", usuario?.SedCod || sedes?.[0]?.SedCod)

        } else {
            setValue("sede", undefined)
            setListaSedes([])
        }
    }

    const [maxLengthInput, setMaxLengthInput] = useState(8)

    const handleInputChange = (e) => {
        let inputValue = e.target.value.trim(); // Eliminar espacios en blanco al inicio y al final

        if (watch("tipo_ide") === "D") {
            // Filtrar caracteres no numéricos cuando tipo_ide es "D"
            inputValue = inputValue.replace(/[^0-9]/g, "");
        }

        // Limitar la longitud del valor (sin contar espacios)
        if (inputValue.length > maxLengthInput) {
            inputValue = inputValue.slice(0, maxLengthInput);
        }

        e.target.value = inputValue;
    };

    const cambiarTipoDocumento = () => {
        if (watch("tipo_ide") == "D") {
            setMaxLengthInput(8)
        } else {
            setMaxLengthInput(12)
        }
    }

    useEffect(() => {

        const cargarDatosIniciales = async () => {

            const { companias, sucursales, sedes } = await obtenerCompaniasYSucursales(usuarioAutenticado?.token)

            if (rol == CONST_ROL_MASTER) {
                await obtenerSucursalesPorCompania(watch("compania"));
            } else {
                const listaSucursalesFormateada = sucursales?.map(s => ({
                    CompCod: s?.id,
                    CompNom: s?.tipo
                }))

                setListaSucursales(listaSucursalesFormateada)
                setValue("sucursal", listaSucursalesFormateada?.[0]?.CompCod)
            }
            if (sucursales?.length > 1) {
                await obtenerSedesPorSucursal(watch("sucursal"));
            } else {
                const listaSedesFormateada = sedes?.map(s => ({
                    SedCod: s?.id,
                    SedDesc: s?.tipo
                }))
                setListaSedes(listaSedesFormateada)
                setValue("sede", usuario?.SedCod || listaSedesFormateada?.[0]?.SedCod)
            }

        };

        cargarDatosIniciales();
        setValue("tipo_ide", usuario?.UseTIde || CONST_DNI);
    }, []);

    return (
        <div className="p-3">
            <div className="container-fluid">
                <h3 className="text-center mt-1">Usuario</h3>
                <div className="row mt-3">
                    <div className="col-12 col-lg-9 mx-auto mt-0">
                        {/* Formulario */}
                        <form id="CreateForm" onSubmit={accion == "agregar" ? handleSubmit(onSubmitCrear) : handleSubmit(onSubmitEditar)} className="row g-2 p-1">
                            <div className="row border p-1 pb-2 rounded-5 g-2">

                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Compañía</label>

                                        <select
                                            {...register("compania", {
                                                required: {
                                                    value: true,
                                                },
                                                onChange: async (e) => {
                                                    await obtenerSucursalesPorCompania(e.target.value);
                                                    await obtenerSedesPorSucursal(watch("sucursal"));
                                                },
                                            })}
                                            className="form-select form-select-sm"
                                            disabled={accion == "agregar" ? false : true}
                                            value={watch("compania")}
                                        >
                                            {
                                                listaCompanias?.length > 0 && listaCompanias.map(c => (
                                                    <option key={c?.id} value={c?.id}>{c?.tipo}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    {errors.compania && errors.compania.type === "required" && <p role="alert" className="error px-2 mb-0">Ingrese compañía</p>}
                                </div>

                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Sucursal</label>

                                        <select
                                            {...register("sucursal", {
                                                required: {
                                                    value: true,
                                                },
                                                onChange: async (e) => {
                                                    await obtenerSedesPorSucursal(e.target.value);
                                                },
                                            })}
                                            className="form-select form-select-sm"
                                            disabled={accion == "agregar" ? false : true}
                                            value={watch("sucursal")}
                                        >
                                            {
                                                listaSucursales?.length > 0 && listaSucursales.map(c => (
                                                    <option key={c?.CompCod} value={c?.CompCod}>{c?.CompNom}</option>
                                                ))
                                            }
                                        </select>

                                    </div>
                                    {errors.compania && errors.compania.type === "required" && <p role="alert" className="error px-2 mb-0">Ingrese compañía</p>}
                                </div>

                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Sede</label>
                                        <select {...register("sede", {
                                            required: {
                                                value: true,
                                            }
                                        })}
                                            className="form-select form-select-sm"
                                            disabled={accion == "agregar" ? false : true}
                                            value={watch("sede")}
                                        >
                                            {
                                                listaSedes?.length > 0 && listaSedes.map(e => (
                                                    <option key={e?.SedCod} value={e?.SedCod}>{e?.SedDesc}</option>
                                                ))
                                            }

                                        </select>

                                    </div>
                                    {errors.sede && errors.sede.type === "required" && <p role="alert" className="error px-2 mb-0">Ingrese sede</p>}
                                </div>

                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Usuario</label>
                                        <input
                                            {...register("usuario", {
                                                required: {
                                                    value: true,
                                                }
                                            })}
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            readOnly={accion == "editar" ? true : false}
                                        />
                                    </div>
                                    {errors.usuario && errors.usuario.type === "required" && <p role="alert" className="error px-2 mb-0">Ingrese código</p>}
                                </div>
                                {/* <div className="col-12 col-lg-6">
                                </div> */}
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Documento</label>
                                        <select {...register("tipo_ide", {
                                            required: {
                                                value: true,
                                            },
                                            onChange: (e) => { cambiarTipoDocumento(e.target.value) }
                                        })}
                                            className="form-select form-select-sm"
                                            value={watch("tipo_ide")}
                                        >
                                            {
                                                listaTipoDocumento?.length > 0 && listaTipoDocumento.map(d => (
                                                    <option key={d?.id} value={d?.id}>{d?.tipo}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    {errors.tipo_ide && errors.tipo_ide.type === "required" && <p role="alert" className="error px-2 mb-0">Ingrese tipo de documento</p>}
                                </div>

                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">

                                        <label className="col-4 text-start px-2">Número</label>
                                        <input
                                            {...register("num_ide", {
                                                required: {
                                                    value: true,
                                                    message: "Ingrese número"
                                                },
                                                pattern: {
                                                    value: watch("tipo_ide") == "D" ? /^\d{8}$/ : /^[a-zA-Z0-9]{9,12}$/,
                                                    // message: `El número debe ser de ${maxLengthInput} dígitos.`
                                                    message: `Número de documento incorrecto`
                                                }
                                            })}
                                            type="text"

                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            maxLength={maxLengthInput}
                                            onInput={handleInputChange}
                                        />
                                    </div>
                                    {errors.num_ide && <p role="alert" className="error px-2 mb-0">{errors.num_ide.message}</p>}
                                </div>
                                <div className="col-12 col-lg-12">
                                    <div className="d-flex align-items-center">

                                        <label className="col-4 col-lg-2 text-start px-2">Nombres</label>
                                        <input
                                            {...register("nombre", {
                                                required: {
                                                    value: true,
                                                }
                                            })}
                                            type={"text"}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                        />
                                    </div>
                                    {errors.nombre && errors.nombre.type === "required" && <p role="alert" className="error px-2 mb-0">Ingrese nombres</p>}

                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Ap. Paterno</label>
                                        <input
                                            {...register("ape_pat", {
                                                required: {
                                                    value: true,
                                                }
                                            })}
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                        />
                                    </div>
                                    {errors.ape_pat && errors.ape_pat.type === "required" && <p role="alert" className="error px-2 mb-0">Ingrese apellido paterno</p>}
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">

                                        <label className="col-4 text-start px-2">Ap. Materno</label>
                                        <input
                                            {...register("ape_mat")}
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Teléfono</label>
                                        <input
                                            {...register("telefono")}
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Celular</label>
                                        <input
                                            {...register("celular")}
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Sexo</label>
                                        <select {...register("sexo")} className="form-select form-select-sm" >
                                            {
                                                listaSexo?.length > 0 && listaSexo.map(s => (
                                                    <option key={s?.id} value={s?.id}>{s?.tipo}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Correo Lab</label>
                                        <input
                                            {...register("email_lab")}
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            style={{ textTransform: "none" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Correo Per</label>
                                        <input
                                            {...register("email")}
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            style={{ textTransform: "none" }}
                                        />
                                    </div>
                                </div>
                                {/* <div className="col-12 col-lg-6">
                                <div className="d-flex align-items-center">
                                    <label className="col-4 text-start px-2">Permiso</label>
                                    <select {...register("permiso")} className="form-select form-select-sm" >
                                        {
                                            listaPermisos?.length > 0 && listaPermisos.map(p => (
                                                <option key={p?.id} value={p?.id}>{p?.tipo}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div> */}
                                {
                                    rol == CONST_ROL_MASTER && (
                                        <div className="col-12 col-lg-6">
                                            <div className="d-flex align-items-center">

                                                <label className="col-4 text-start px-2">Privilegio</label>
                                                <select {...register("privilegio")} className="form-select form-select-sm" >
                                                    {
                                                        listaPrivilegios?.length > 0 && listaPrivilegios.map(p => (
                                                            <option key={p?.id} value={p?.id}>{p?.tipo}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    )
                                }

                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Estado</label>
                                        <select {...register("estado")} className="form-select form-select-sm" >
                                            {
                                                listaEstadosActivo?.length > 0 && listaEstadosActivo.map(e => (
                                                    <option key={e?.id} value={e?.id}>{e?.tipo}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </form>


                        <div className='mt-4 botones-accion text-center'>
                            {
                                accion == "agregar" && (
                                    <button className='btn btn-sm btn-primario col-md-2' type="submit" form="CreateForm" onClick={handleSubmit(onSubmitCrear)}>Guardar</button>
                                )
                            }
                            {
                                accion == "editar" && (
                                    <button className='btn btn-sm btn-primario col-md-2' type="submit" form="CreateForm" onClick={handleSubmit(onSubmitEditar)}>Guardar</button>
                                )
                            }
                            <button className='btn btn-sm btn-secundario col-md-2' onClick={cancelar}>Cancelar</button>

                        </div>
                        {/* Fin formulario */}
                    </div >

                </div>

                {
                    mostrar && (
                        <div className="row mt-4">
                            <div className="col-lg-6 mx-auto text-center alert-error">
                                <Stack spacing={2}>
                                    <Alert variant="outlined" severity={tipoAlerta} >{mensaje}</Alert>
                                </Stack>
                            </div>
                        </div>

                    )
                }

            </div>
        </div>
    )
}
