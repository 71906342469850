import { api } from "./configApi"

export const grupoCrear = async (dataPOST) => {
    try {
        const res = await api.post("api/grupo/AgregarGrupo", dataPOST)
    
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: 1,
                message: res.data.message || "",
            }
        }

    } catch (error) {
        if (error.response && error.response.data.status == 0) {
            return {
                status: 0,
                message: error.response.data.message || "",
            };
        }

        return {
            status: -1,
            message: "Error al registrar el usuario",
        }
    }
}

export const gruposBuscar = async (dataPOST) => {
    try {
        const res = await api.post("api/grupo/BuscarGrupo", dataPOST)
        // res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                grupos: res.data.resultado,
                message: res.data.message,
            }
        }

    } catch (error) {
        
        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                grupos: [],
                message: error.response.data.message,
            };
        }
        return {
            status: -1,
            grupos: [],
            message: "Error en el servidor",
        }
    }
}

export const grupoDelete = async (dataPOST) => {
    try {

        const res = await api.post("api/grupo/EliminarGrupo", dataPOST)
    
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
            }
        }
    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status, 
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        }
    }
}

export const grupoMostrar = async (dataPOST) => {
    try {

        const res = await api.post("api/grupo/MostrarGrupo", dataPOST)

        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                grupo: res.data.grupo,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status, 
                grupo: {},
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            grupo: {},
            message: "Error en el servidor",
        }
    }
}

export const grupoEditar = async (dataPOST) => {
    try {
        const res = await api.post("api/grupo/ActualizarGrupo", dataPOST)
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status, 
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        }
    }
}

export const gruposPermisosListar = async (dataPOST) => {
    try {
        
        const res = await api.post("api/modulo/ListarModuloGrupo", dataPOST)

        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
                modulos: res.data.resultado
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status, 
                message: error.response.data.message,
                modulos: []
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
            modulos: []
        }
    }
}

export const gruposPermisosAgregar = async (dataPOST) => {
    try {

        const res = await api.post("api/modulo/AddModuleGroup", dataPOST)
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status, 
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        }
    }
}

export const gruposUsuariosPorGrupo = async (dataPOST) => {
    try {

        const res = await api.post("api/usuario/MostrarGroupUser", dataPOST)
    
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
                usuarios: res.data.lista_grupo
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status, 
                message: error.response.data.message,
                usuarios: []
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
            usuarios: []
        }
    }
}

export const gruposAniadirUsuariosPorGrupo = async (dataPOST) => {
    try {
        const res = await api.post("api/usuario/AddGroupUser", dataPOST)
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status, 
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        }
    }
}

export const grupoModuloSubirNivel = async (dataPOST) => {
    try {
        const res = await api.post("api/modulo/SubirModuloGrupo", dataPOST)

        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                modulos: res.data.resultado,
                message: res.data.message,
            }
        }

    } catch (error) {
        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                modulos: [],
                message: "No se encontro módulos",
            };
        }
        return {
            status: -1,
            modulos: [],
            message: "Error en el servidor",
        }
    }
}

export const grupoListarUsuariosPorCompaniaYSucursales= async (dataPOST) => {
    try {
        const res = await api.post("api/usuario/ListarUsuario", dataPOST)

        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                usuarios: res.data.resultado,
                message: res.data.message,
            }
        }

    } catch (error) {
        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                usuarios: [],
                message: "No se encontro usuarios",
            };
        }
        return {
            status: -1,
            usuarios: [],
            message: "Error en el servidor",
        }
    }
}

