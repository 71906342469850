import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { listaEstadosActivo } from "../../../constants/constants-selectores";
import { companiaCrear, companiaEditar } from "../../../api/companiaApi";
import { DataContext } from "../../../context/DataContext";
import { modulosBuscar } from "../../../api/moduloApi";

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { CONST_RUTA_COMPANIAS_LISTAR, CONST_RUTA_SUCURSALES_LISTAR } from "../../../constants/constants-rutas-codigos";
import { cambiarColores, handleImagenSeleccionada, obtenerLocalStorage, obtenerNombreEImagenBase64 } from "../../../resources/funciones";

import { FaBars, FaAngleDown } from "react-icons/fa";
import { RiLogoutBoxLine } from "react-icons/ri";
import { CONST_KEY_USUARIO_AUTENTICADO } from "../../../constants/constanst-valores";

import Swal from 'sweetalert2';
import { usuarioObtenerCompaniasYSucursales } from "../../../api/usuarioApi";

export const SucursalForm = () => {

    const { getDataSelector } = useContext(DataContext)
    const usuarioAutenticado = obtenerLocalStorage(CONST_KEY_USUARIO_AUTENTICADO);

    const [listaCompanias, setListaCompanias] = useState([])

    const [mensaje, setMensaje] = useState('')
    const [mostrar, setMostrar] = useState(false)
    const [tipoAlerta, setTipoAlerta] = useState("")

    //Obtener la accion a partir de la url (agregar o editar)
    const location = useLocation();
    const pathname = location.pathname;

    const compania = location?.state || {}

    const accion = pathname.split("/").pop()

    // Obtener los valores de las variables CSS cuando el componente se monta
    const computedStyles = getComputedStyle(document.documentElement);

    // const logo_primario_guardado = compania?.estilo?.log_primario?.slice(24,) ? "data:image/jpeg;base64,/9j/" + compania?.estilo?.log_primario?.slice(24,) : ""

    // const logo_secundario_guardado = compania?.estilo?.log_secundario?.slice(24,) ? "data:image/jpeg;base64,/9j/" + compania?.estilo?.log_secundario?.slice(24,) : ""

    const logo_primario_guardado = compania?.estilo?.log_primario ? `data:image/png;base64,${compania?.estilo?.log_primario}` : ""
    const logo_secundario_guardado = compania?.estilo?.log_secundario ? `data:image/png;base64,${compania?.estilo?.log_secundario}` : ""

    const { register, handleSubmit, watch, formState: { errors }, reset, getValues, setValue } = useForm({
        defaultValues: {
            CompCod: compania?.CompCod || "",
            CompPre: compania?.CompPre || "",
            CompRuc: compania?.CompRuc || "",
            CompRSoc: compania?.CompRSoc || "",
            CompNom: compania?.CompNom || "",
            CompDirec: compania?.CompDirec || "",
            CompCor: compania?.CompCor || "",
            CompTel: compania?.CompTel || "",

            CompPrinc: compania?.CompPrinc || "N",
            CompEst: compania?.CompEst || "A",
            // CompApDef: compania?.CompApDef || "N",

            CompPad: compania?.CompPad || "",
            CompTip: compania?.CompTip || "S",

            imagen_logo_principal: "",
            imagen_logo_secundario: "",
            color_fondo_menu_primario: compania?.estilo?.fondo_menu || computedStyles?.getPropertyValue('--color-fondo-menu-primario'),
            color_texto_nav_item: compania?.estilo?.text_opciones || computedStyles?.getPropertyValue('--color-texto-nav-item'),
            color_fondo_nav_item_activo: compania?.estilo?.fond_opc_activo || computedStyles?.getPropertyValue('--color-fondo-nav-item-activo'),
            color_texto_nav_item_activo: compania?.estilo?.text_opc_activo || computedStyles?.getPropertyValue('--color-texto-nav-item-activo'),

            color_button_primario: compania?.estilo?.boton_primario || computedStyles?.getPropertyValue('--color-button-primario'),
            color_button_primario_hover: compania?.estilo?.boton_primario_activo || computedStyles?.getPropertyValue('--color-button-primario-hover'),
            color_button_primario_texto: compania?.estilo?.text_boton_primario || computedStyles?.getPropertyValue('--color-button-primario-text'),

            color_button_secundario: compania?.estilo?.boton_secundario || computedStyles?.getPropertyValue('--color-button-secundario'),
            color_button_secundario_hover: compania?.estilo?.boton_secundario_activo || computedStyles?.getPropertyValue('--color-button-secundario-hover'),
            color_button_secundario_texto: compania?.estilo?.text_boton_secundario || computedStyles?.getPropertyValue('--color-button-secundario-text'),

        }
    });

    const onSubmitCrear = async (data) => {

        const imagen1 = await obtenerNombreEImagenBase64(data?.imagen_logo_principal[0])
        const imagen2 = await obtenerNombreEImagenBase64(data?.imagen_logo_secundario[0])

        const dataPOST = {
            // CompApDef: data?.CompApDef,
            CompEst: data?.CompEst,
            CompCod: data?.CompCod?.toUpperCase().trim() || "",
            CompPre: data?.CompPre?.toUpperCase().trim() || "",
            CompRuc: data?.CompRuc?.toUpperCase().trim() || "",
            CompRSoc: data?.CompRSoc?.toUpperCase().trim() || "",
            CompNom: data?.CompNom?.toUpperCase().trim() || "",
            CompDirec: data?.CompDirec?.toUpperCase().trim() || "",
            CompCor: data?.CompCor?.toUpperCase().trim() || "",
            CompTel: data?.CompTel?.toUpperCase().trim() || "",

            CompPad: data?.CompPad || "",
            CompTip: "S",

            log_primario: imagen1?.imagenBase64 || "",
            log_secundario: imagen2?.imagenBase64 || "",
            fondo_menu: data?.color_fondo_menu_primario || "",
            text_opciones: data?.color_texto_nav_item || "",
            fond_opc_activo: data?.color_fondo_nav_item_activo || "",
            text_opc_activo: data?.color_texto_nav_item_activo || "",
            boton_primario: data?.color_button_primario || "",
            boton_primario_activo: data?.color_button_primario_hover || "",
            text_boton_primario: data?.color_button_primario_texto || "",
            boton_secundario: data?.color_button_secundario || "",
            boton_secundario_activo: data?.color_button_secundario_hover || "",
            text_boton_secundario: data?.color_button_secundario_texto || "",
            token: usuarioAutenticado?.token
        }

        // Guardar los colores una vez se haya creado la compania
        // const colores = [
        //     { name: '--color-fondo-menu-primario', color: watch("color_fondo_menu_primario") },
        //     { name: '--color-texto-nav-item', color: watch("color_texto_nav_item") },
        //     { name: '--color-fondo-nav-item-activo', color: watch("color_fondo_nav_item_activo") },
        //     { name: '--color-texto-nav-item-activo', color: watch("color_texto_nav_item_activo") },
        //     { name: '--color-button-primario', color: watch("color_button_primario") },
        //     { name: '--color-button-primario-hover', color: watch("color_button_primario_hover") },
        //     { name: '--color-button-primario-text', color: watch("color_button_primario_texto") },
        //     { name: '--color-button-secundario', color: watch("color_button_secundario") },
        //     { name: '--color-button-secundario-hover', color: watch("color_button_secundario_hover") },
        //     { name: '--color-button-secundario-text', color: watch("color_button_secundario_texto") },
        // ];

        // cambiarColores(colores);

        const { status, message } = await companiaCrear(dataPOST)
        getDataSelector()
        setMostrar(true)
        setMensaje(message)
        if (status == 1) {
            setTipoAlerta("success")
            //   getCompaniasYSedesPorUsuario(usuarioAutenticado?.token)
            alertMensajeGuardado()

        } else {
            setTipoAlerta("warning")
        }

    }

    const onSubmitEditar = async (data) => {

        const imagen1 = await obtenerNombreEImagenBase64(data?.imagen_logo_principal[0])
        const imagen2 = await obtenerNombreEImagenBase64(data?.imagen_logo_secundario[0])

        const dataPOST = {
            // CompApDef: data?.CompApDef,
            CompEst: data?.CompEst,

            CompPre: data?.CompPre?.toUpperCase().trim() || "",
            CompRuc: data?.CompRuc?.toUpperCase().trim() || "",
            CompRSoc: data?.CompRSoc?.toUpperCase().trim() || "",
            CompNom: data?.CompNom?.toUpperCase().trim() || "",
            CompDirec: data?.CompDirec?.toUpperCase().trim() || "",
            CompCor: data?.CompCor?.toUpperCase().trim() || "",
            CompTel: data?.CompTel?.toUpperCase().trim() || "",

            CompCod: compania?.CompCod || "",
            compania_nuevo: data?.CompCod?.toUpperCase() || "",

            CompPad: data?.CompPad || "",
            CompTip: "S",

            log_primario: data?.imagen_logo_principal.length == 0 ? compania?.estilo?.log_primario : imagen1.imagenBase64,
            log_secundario: data?.imagen_logo_secundario.length == 0 ? compania?.estilo?.log_secundario : imagen2.imagenBase64,

            // log_primario: imagen1.imagenBase64 == null ? logo_primario_guardado : imagen1.imagenBase64,
            // log_secundario: imagen2.imagenBase64 == null ? logo_secundario_guardado : imagen2.imagenBase64,

            fondo_menu: data?.color_fondo_menu_primario || "",
            text_opciones: data?.color_texto_nav_item || "",
            fond_opc_activo: data?.color_fondo_nav_item_activo || "",
            text_opc_activo: data?.color_texto_nav_item_activo || "",
            boton_primario: data?.color_button_primario || "",
            boton_primario_activo: data?.color_button_primario_hover || "",
            text_boton_primario: data?.color_button_primario_texto || "",
            boton_secundario: data?.color_button_secundario || "",
            boton_secundario_activo: data?.color_button_secundario_hover || "",
            text_boton_secundario: data?.color_button_secundario_texto || "",

            token: usuarioAutenticado?.token
        }

        const { status, message } = await companiaEditar(dataPOST)
        getDataSelector()
        setMostrar(true)
        setMensaje(message)

        if (status == 1) {
            setTipoAlerta("success")
            //   getCompaniasYSedesPorUsuario(usuarioAutenticado?.token)
            alertMensajeGuardado()
        } else {
            setTipoAlerta("warning")
        }
    }

    const navigate = useNavigate();

    const cancelar = () => {
        navigate(CONST_RUTA_SUCURSALES_LISTAR)
    }


    // const [listaModulos, setListaModulos] = useState([])

    // const modulosBuscarActivos = async () => {

    //     const dataPOST = {
    //         Estado: "A",
    //         Superior: "",
    //         token: usuarioAutenticado?.token
    //     }

    //     const { modulos } = await modulosBuscar(dataPOST)
    //     setValue("CompApDef", compania?.CompApDef || "N")
    //     setListaModulos(modulos)
    // }

    const alertMensajeGuardado = () => {
        Swal.fire({
            icon: 'success',
            text: 'Para notar los cambios de los colores del sistema, vuelva a iniciar sesión',
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            }
        })
    }

    const [logoPrincipalBase64, setLogoPrincipalBase64] = useState(null);
    const [logoSecundarioBase64, setLogoSecundarioBase64] = useState(null);

    const obtenerCompaniasYSucursales = async () => {

        const dataPOST = {
            token: usuarioAutenticado?.token
        }

        const { companias } = await usuarioObtenerCompaniasYSucursales(dataPOST)
        setListaCompanias(companias)
        setValue("CompPad", compania?.CompPad || companias?.[0]?.id)

        return {
            companias,
        }
    }


    useEffect(() => {

        const cargarDatosIniciales = async () => {

            const { companias } = await obtenerCompaniasYSucursales()

            // await modulosBuscarActivos()
            //   await obtenerModulosPorCompania(watch("compania"))
        };

        cargarDatosIniciales();
    }, [])


    return (
        <div className="p-3">
            <div className="container-fluid">
                <h3 className="text-center mt-1">Sucursal</h3>
                <div className="row mt-3">
                    <div className="col-12 col-lg-9 mx-auto mt-0">
                        {/* Formulario */}
                        <form id="CreateForm" onSubmit={accion == "agregar" ? handleSubmit(onSubmitCrear) : handleSubmit(onSubmitEditar)} className="row g-2 p-1">
                            <div className="row border p-1 pb-2 rounded-5 g-2">

                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Compañía</label>
                                        <select
                                            {...register("CompPad", {
                                                required: {
                                                    value: true,
                                                }
                                            })}
                                            className="form-select form-select-sm"
                                            disabled={accion == "editar" ? true : false}
                                            value={watch("CompPad")}
                                        >
                                            {
                                                listaCompanias?.length > 0 && listaCompanias.map(c => (
                                                    <option key={c?.id} value={c?.id}>{c?.tipo}</option>
                                                ))
                                            }
                                        </select>

                                    </div>
                                    {errors.CompPad && errors.CompPad.type === "required" && <p role="alert" className="error px-2 mb-0">Ingrese compañía</p>}
                                </div>

                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Código</label>
                                        <input
                                            {...register("CompCod", {
                                                required: {
                                                    value: true,
                                                }
                                            })}
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            readOnly={accion == "editar" ? true : false}
                                        />
                                    </div>
                                    {errors.CompCod && errors.CompCod.type === "required" && <p role="alert" className="error px-2 mb-0">Ingrese código</p>}
                                </div>

                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Prefijo</label>
                                        <input
                                            {...register("CompPre")}
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">RUC</label>
                                        <input
                                            {...register("CompRuc", {
                                                required: {
                                                    value: true,
                                                }
                                            })}
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                        />
                                    </div>
                                    {errors.CompRuc && errors.CompRuc.type === "required" && <p role="alert" className="error px-2 mb-0">Ingrese RUC</p>}
                                </div>
                                <div className="col-12 col-lg-12">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 col-lg-2 text-start px-2">Razon Social</label>
                                        <input
                                            {...register("CompRSoc", {
                                                required: {
                                                    value: true,
                                                }
                                            })}
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                        />
                                    </div>
                                    {errors.CompRSoc && errors.CompRSoc.type === "required" && <p role="alert" className="error px-2 mb-0">Ingrese Razon Social</p>}
                                </div>
                                <div className="col-12 col-lg-12">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 col-lg-2 text-start px-2">Nombre</label>
                                        <input
                                            {...register("CompNom", {
                                                required: {
                                                    value: true,
                                                }
                                            })}
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                        />
                                    </div>
                                    {errors.CompNom && errors.CompNom.type === "required" && <p role="alert" className="error px-2 mb-0">Ingrese nombre</p>}

                                </div>
                                <div className="col-12 col-lg-12">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 col-lg-2 text-start px-2">Dirección</label>
                                        <input
                                            {...register("CompDirec", {
                                                required: {
                                                    value: true,
                                                }
                                            })}
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                        />
                                    </div>
                                    {errors.CompDirec && errors.CompDirec.type === "required" && <p role="alert" className="error px-2 mb-0">Ingrese dirección</p>}
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Correo</label>
                                        <input
                                            {...register("CompCor")}
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Telefono</label>
                                        <input
                                            {...register("CompTel")}
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Estado</label>
                                        <select {...register("CompEst")} className="form-select form-select-sm" >
                                            {
                                                listaEstadosActivo?.length > 0 && listaEstadosActivo.map(e => (
                                                    <option key={e?.id} value={e?.id}>{e?.tipo}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>

                                {/* <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">App Default</label>
                                        <select
                                            {...register("CompApDef")}
                                            className="form-select form-select-sm"
                                            value={watch("CompApDef")}
                                        >
                                            <option value={"N"}>NINGUNO</option>
                                            {
                                                listaModulos?.length > 0 && listaModulos.map(a => (
                                                    <option key={a?.ModCod} value={a?.ModCod}>{a?.ModDesc}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div> */}
                                <hr className="mt-3" />
                                <h4 className="px-2">Configuración del sistema</h4>
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-top">
                                        <label className="col-4 col-lg-4 text-start px-2">Logo principal</label>
                                        <div className="col-lg-8">
                                            <div className="input-group mb-3">
                                                <input
                                                    {...register("imagen_logo_principal", {
                                                        validate: (value) => {
                                                            // Verificar si se seleccionó un archivo
                                                            if (!value[0]) return true;
                                                            // Verificar si el archivo es una imagen
                                                            return value[0].type.startsWith("image/");
                                                        },
                                                    })}
                                                    type="file"
                                                    className="form-control form-control-sm"
                                                    id="inputGroupFile01"
                                                    accept="image/*"
                                                    onChange={(e) => handleImagenSeleccionada(e, setLogoPrincipalBase64)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        (logo_primario_guardado != "" || logoPrincipalBase64 != null) && (
                                            <div className="col-lg-8 mx-auto d-flex justify-content-center align-items-center" style={{ height: "190px", width: "190px" }}>
                                                <img
                                                    src={logoPrincipalBase64 || logo_primario_guardado}
                                                    alt="Imagen logo 1"
                                                    className="img-thumbnail"
                                                />
                                            </div>
                                        )}
                                    {errors.imagen_logo_principal && (
                                        <p role="alert" className="error px-2 mb-0">
                                            {errors.imagen_logo_principal.type === 'validate' ? 'Debe seleccionar una imagen válida' : ''}
                                        </p>
                                    )}
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-top">
                                        <label className="col-4 col-lg-4 text-start px-2">Logo secundario</label>
                                        <div className="col-lg-8">
                                            <div className="input-group mb-3">
                                                <input
                                                    {...register("imagen_logo_secundario", {
                                                        validate: (value) => {
                                                            // Verificar si se seleccionó un archivo
                                                            if (!value[0]) return true;
                                                            // Verificar si el archivo es una imagen
                                                            return value[0].type.startsWith("image/");
                                                        },
                                                    })}
                                                    type="file"
                                                    className="form-control form-control-sm"
                                                    id="inputGroupFile02"
                                                    accept="image/*"
                                                    onChange={(e) => handleImagenSeleccionada(e, setLogoSecundarioBase64)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        (logo_secundario_guardado != "" || logoSecundarioBase64 != null) && (
                                            <div className="col-lg-8 mx-auto d-flex justify-content-center align-items-center" style={{ height: "190px", width: "190px" }}>
                                                <img
                                                    src={logoSecundarioBase64 || logo_secundario_guardado}
                                                    alt="Imagen logo 2"
                                                    className="img-thumbnail"
                                                />
                                            </div>
                                        )
                                    }

                                    {errors.imagen_logo_secundario && (
                                        <p role="alert" className="error px-2 mb-0">
                                            {errors.imagen_logo_secundario.type === 'validate' ? 'Debe seleccionar una imagen válida' : ''}
                                        </p>
                                    )}
                                </div>
                                <h6 className="px-2">Colores del menú</h6>
                                <div className="col-12 col-lg-6">
                                    <div className="row g-3">
                                        <div className="col-12 col-lg-12">
                                            <div className="d-flex align-items-top">
                                                <label className="col-6 col-lg-8 text-start px-2">Fondo</label>
                                                <input
                                                    {...register("color_fondo_menu_primario")}
                                                    type="color"
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-12">
                                            <div className="d-flex align-items-top">
                                                <label className="col-6 col-lg-8 text-start px-2">Texto de las opciones</label>
                                                <input
                                                    {...register("color_texto_nav_item")}
                                                    type="color"
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-12">
                                            <div className="d-flex align-items-top">
                                                <label className="col-6 col-lg-8 text-start px-2">Fondo de opciones activo</label>
                                                <input
                                                    {...register("color_fondo_nav_item_activo")}
                                                    type="color"
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-12">
                                            <div className="d-flex align-items-top">
                                                <label className="col-6 col-lg-8 text-start px-2">Texto de opciones activo</label>
                                                <input
                                                    {...register("color_texto_nav_item_activo")}
                                                    type="color"
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-12 col-lg-6 d-flex justify-content-center">
                                    <div className="sidebar" style={{ background: watch("color_fondo_menu_primario") || compania?.estilo?.fondo_menu, width: "10rem", height: "13rem", padding: "10px !important" }}>
                                        <div className="top_section justify-content-center" style={{ position: "relative" }}>

                                            {/* <h1 className="logo text-center text-white"
                                                style={{ flex: 1, alignSelf: 'end' }}>
                                                LOGO
                                            </h1> */}
                                            {
                                                (logo_primario_guardado != "" || logoPrincipalBase64 != null) && (
                                                    <div className="col-lg-8 mx-auto d-flex justify-content-center align-items-center" style={{ height: "28px", width: "28px" }}>
                                                        <img
                                                            src={logoPrincipalBase64 || logo_primario_guardado}
                                                            alt="Imagen logo 1"
                                                            className="img-thumbnail"
                                                        />
                                                    </div>
                                                )}
                                            {/* <div className="text-center d-flex align-items-end justify-content-center" style={{ position: "absolute", right: "10px" }}>
                                                <FaBars className="color-iconos" style={{ color: watch("color_button_primario"), fontSize: "18px" }} />
                                            </div> */}
                                            {
                                                (logo_primario_guardado == "" && logoPrincipalBase64 == null) && (
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
                                                            <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z">
                                                            </path>
                                                            <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V1Zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3V1Z">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                )
                                            }

<div class="bars text-center d-flex align-items-end justify-content-center" style={{ position: "absolute", right: "8px", color: watch("color_texto_nav_item") }}><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" /*  class="color-iconos"  */ height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" style={{ fontSize: "18px" }}><path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg></div>

                                        </div>
                                        <section className="routes">
                                            <div className="usuario text-center p-0 m-0"><span className="ms-1" style={{ fontSize: "0.688rem", color: watch("color_texto_nav_item") }}>User</span></div>
                                            <a className="none link"
                                                style={{ cursor: "pointer", color: watch("color_texto_nav_item") }}
                                                onMouseEnter={(e) => {
                                                    // Cambiar el color de fondo y el color del texto en hover
                                                    e.currentTarget.style.backgroundColor = watch("color_fondo_nav_item_activo"); // Color de fondo en hover
                                                    e.currentTarget.style.color = watch("color_texto_nav_item_activo"); // Color de texto en hover
                                                }}
                                                onMouseLeave={(e) => {
                                                    // Volver a los estilos predeterminados al dejar de hacer hover
                                                    e.currentTarget.style.backgroundColor = "transparent"; // Color de fondo predeterminado
                                                    e.currentTarget.style.color = watch("color_texto_nav_item"); // Color de texto predeterminado
                                                }}
                                            >
                                                <div className="link_text">
                                                    Opción 1
                                                </div>

                                            </a>
                                            <div className="menu">
                                                <div className="menu_item" style={{ cursor: "pointer" }}>
                                                    <div className="icon"></div>
                                                    <div className="link_text" style={{ color: watch("color_texto_nav_item") }}>Opción 2</div>
                                                </div>
                                                <div style={{ transform: "none" }}>
                                                    <FaAngleDown />
                                                </div>
                                            </div>
                                            <a aria-current="page" className="none link"
                                                style={{ cursor: "pointer", color: watch("color_texto_nav_item") }}
                                                onMouseEnter={(e) => {
                                                    // Cambiar el color de fondo y el color del texto en hover
                                                    e.currentTarget.style.backgroundColor = watch("color_fondo_nav_item_activo"); // Color de fondo en hover
                                                    e.currentTarget.style.color = watch("color_texto_nav_item_activo"); // Color de texto en hover
                                                }}
                                                onMouseLeave={(e) => {
                                                    // Volver a los estilos predeterminados al dejar de hacer hover
                                                    e.currentTarget.style.backgroundColor = "transparent"; // Color de fondo predeterminado
                                                    e.currentTarget.style.color = watch("color_texto_nav_item"); // Color de texto predeterminado
                                                }}
                                            >
                                                <div>
                                                    <RiLogoutBoxLine className="color-iconos" style={{ fontSize: "24px", color: watch("color_texto_nav_item") }} />
                                                </div>
                                                <div className="link_text">salir</div>
                                            </a>
                                        </section>
                                    </div>
                                </div>

                                <h6 className="px-2">Colores de botones</h6>

                                <div className="col-12 col-lg-4">
                                    <div className="row g-3">
                                        <div className="col-12 col-lg-12">
                                            <div className="d-flex align-items-top">
                                                <label className="col-6 col-lg-8 text-start px-2">Botón primario</label>
                                                <input
                                                    {...register("color_button_primario")}
                                                    type="color"
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-12">
                                            <div className="d-flex align-items-top">
                                                <label className="col-6 col-lg-8 text-start px-2">Botón primario activo</label>
                                                <input
                                                    {...register("color_button_primario_hover")}
                                                    type="color"
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-12">
                                            <div className="d-flex align-items-top">
                                                <label className="col-6 col-lg-8 text-start px-2">Texto del botón primario</label>
                                                <input
                                                    {...register("color_button_primario_texto")}
                                                    type="color"
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-2 d-flex justify-content-center align-items-center">
                                    <div className="p-3 border">
                                        <button
                                            className="btn btn-sm btn-primario"
                                            type="button"
                                            style={{ background: watch("color_button_primario"), color: watch("color_button_primario_texto") }}
                                            onMouseEnter={(e) => {
                                                // Cambiar el color de fondo y el color del texto en hover
                                                e.currentTarget.style.backgroundColor = watch("color_button_primario_hover"); // Color de fondo en hover
                                                e.currentTarget.style.color = watch("color_button_primario_texto"); // Color de texto en hover
                                            }}
                                            onMouseLeave={(e) => {
                                                // Volver a los estilos predeterminados al dejar de hacer hover
                                                e.currentTarget.style.backgroundColor = watch("color_button_primario"); // Color de fondo predeterminado
                                                e.currentTarget.style.color = watch("color_button_primario_texto"); // Color de texto predeterminado
                                            }}

                                        >Guardar</button>
                                    </div>

                                </div>

                                <div className="col-12 col-lg-4">
                                    <div className="row g-3">
                                        <div className="col-12 col-lg-12">
                                            <div className="d-flex align-items-top">
                                                <label className="col-6 col-lg-8 text-start px-2">Botón secundario</label>
                                                <input
                                                    {...register("color_button_secundario")}
                                                    type="color"
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-12">
                                            <div className="d-flex align-items-top">
                                                <label className="col-6 col-lg-8 text-start px-2">Botón secundario activo</label>
                                                <input
                                                    {...register("color_button_secundario_hover")}
                                                    type="color"
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-12">
                                            <div className="d-flex align-items-top">
                                                <label className="col-6 col-lg-8 text-start px-2">Texto del botón secundario</label>
                                                <input
                                                    {...register("color_button_secundario_texto")}
                                                    type="color"
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-2 d-flex justify-content-center align-items-center">
                                    <div className="p-3 border">
                                        <button
                                            className="btn btn-sm btn-secundario"
                                            type="button"
                                            style={{ background: watch("color_button_secundario"), color: watch("color_button_secundario_texto") }}
                                            onMouseEnter={(e) => {
                                                // Cambiar el color de fondo y el color del texto en hover
                                                e.currentTarget.style.backgroundColor = watch("color_button_secundario_hover"); // Color de fondo en hover
                                                e.currentTarget.style.color = watch("color_button_secundario_texto"); // Color de texto en hover
                                            }}
                                            onMouseLeave={(e) => {
                                                // Volver a los estilos predeterminados al dejar de hacer hover
                                                e.currentTarget.style.backgroundColor = watch("color_button_secundario"); // Color de fondo predeterminado
                                                e.currentTarget.style.color = watch("color_button_secundario_texto"); // Color de texto predeterminado
                                            }}
                                        >
                                            Cancelar
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </form>
                        {/* Fin formulario */}

                        <div className='mt-4 botones-accion text-center'>
                            {
                                accion == "agregar" && (
                                    <button className='btn btn-sm btn-primario col-md-2' type="submit" form="CreateForm" onClick={handleSubmit(onSubmitCrear)}>Guardar</button>
                                )
                            }
                            {
                                accion == "editar" && (
                                    <button className='btn btn-sm btn-primario col-md-2' type="submit" form="CreateForm" onClick={handleSubmit(onSubmitEditar)}>Guardar</button>
                                )
                            }
                            <button className='btn btn-sm btn-secundario col-md-2' onClick={cancelar}>Cancelar</button>
                        </div>
                    </div >
                </div>

                {
                    mostrar && (
                        <div className="row mt-4">
                            <div className="col-lg-6 mx-auto text-center alert-error">
                                <Stack spacing={2}>
                                    <Alert variant="outlined" severity={tipoAlerta} >{mensaje}</Alert>
                                </Stack>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
