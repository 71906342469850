export const CONST_RUTA_LOGIN = '/'
export const CONST_RUTA_SIN_ACCESO = '/sin-acceso'

export const CONST_RUTA_SEGURIDAD = '/seguridad'
export const CONST_CODIGO_SEGURIDAD = 'S0100000000'

/**************************** USUARIOS *****************************************/
//Rutas Navigate usuarios

export const CONST_RUTA_USUARIOS_LISTAR = '/seguridad/usuarios'
export const CONST_RUTA_USUARIOS_AGREGAR = '/seguridad/usuarios/agregar'
export const CONST_RUTA_USUARIOS_EDITAR = '/seguridad/usuarios/editar'
export const CONST_RUTA_USUARIOS_GRUPOS = '/seguridad/usuarios/grupos'

// Codigos Modulo Usuarios
export const CONST_CODIGO_USUARIOS_LISTAR = 'S0101000000'
export const CONST_CODIGO_USUARIOS_AGREGAR = 'S0101010000'
export const CONST_CODIGO_USUARIOS_EDITAR = 'S0101020000'
export const CONST_CODIGO_USUARIOS_ELIMINAR = 'S0101030000'
export const CONST_CODIGO_USUARIOS_GRUPOS = 'S0101040000'

/**************************** GRUPOS *****************************************/
//Rutas Navigate grupos

export const CONST_RUTA_GRUPOS_LISTAR = '/seguridad/grupos'
export const CONST_RUTA_GRUPOS_AGREGAR = '/seguridad/grupos/agregar'
export const CONST_RUTA_GRUPOS_USUARIOS = '/seguridad/grupos/usuarios'
export const CONST_RUTA_GRUPOS_PERMISOS = '/seguridad/grupos/permisos'
export const CONST_RUTA_GRUPOS_EDITAR = '/seguridad/grupos/editar'
export const CONST_RUTA_GRUPOS_USUARIOS_AGREGAR = '/seguridad/grupos/usuarios-agregar'

// Codigos Modulo Grupos
export const CONST_CODIGO_GRUPOS_LISTAR = 'S0102000000'
export const CONST_CODIGO_GRUPOS_AGREGAR = 'S0102010000'
export const CONST_CODIGO_GRUPOS_EDITAR = 'S0102020000'
export const CONST_CODIGO_GRUPOS_ELIMINAR = 'S0102030000'
export const CONST_CODIGO_GRUPOS_USUARIOS = 'S0102040000'
export const CONST_CODIGO_GRUPOS_PERMISOS = 'S0102050000'
export const CONST_CODIGO_GRUPOS_USUARIOS_AGREGAR = 'S0102060000'

/**************************** MODULOS *****************************************/
//Rutas Navigate modulos

export const CONST_RUTA_MODULOS_LISTAR = '/seguridad/modulos'
export const CONST_RUTA_MODULOS_AGREGAR = '/seguridad/modulos/agregar'
export const CONST_RUTA_MODULOS_EDITAR = '/seguridad/modulos/editar'

// Codigos Modulo "MODULOS"
export const CONST_CODIGO_MODULOS_LISTAR = 'S0103000000'
export const CONST_CODIGO_MODULOS_AGREGAR = 'S0103010000'
export const CONST_CODIGO_MODULOS_EDITAR = 'S0103020000'
export const CONST_CODIGO_MODULOS_ELIMINAR = 'S0103030000'

/**************************** COMPANIAS *****************************************/
//Rutas Navigate compañias

export const CONST_RUTA_COMPANIAS_LISTAR = '/seguridad/companias'
export const CONST_RUTA_COMPANIAS_AGREGAR = '/seguridad/companias/agregar'
export const CONST_RUTA_COMPANIAS_EDITAR = '/seguridad/companias/editar'

// Codigos Modulo Compañias
export const CONST_CODIGO_COMPANIAS_LISTAR = 'S0104000000'
export const CONST_CODIGO_COMPANIAS_AGREGAR = 'S0104010000'
export const CONST_CODIGO_COMPANIAS_EDITAR = 'S0104020000'
export const CONST_CODIGO_COMPANIAS_ELIMINAR = 'S0104030000'

/**************************** SEDES *****************************************/
//Rutas Navigate sedes
export const CONST_RUTA_SEDES_LISTAR = '/seguridad/sedes'
export const CONST_RUTA_SEDES_AGREGAR = '/seguridad/sedes/agregar'
export const CONST_RUTA_SEDES_EDITAR = '/seguridad/sedes/editar'

// Codigos Modulo Sedes
export const CONST_CODIGO_SEDES_LISTAR = 'S0105000000'
export const CONST_CODIGO_SEDES_AGREGAR = 'S0105010000'
export const CONST_CODIGO_SEDES_EDITAR = 'S0105020000'
export const CONST_CODIGO_SEDES_ELIMINAR = 'S0105030000'

/**************************** PARAMETROS *****************************************/
//Rutas Navigate parametros
export const CONST_RUTA_PARAMETROS_LISTAR = '/seguridad/parametros'
export const CONST_RUTA_PARAMETROS_AGREGAR = '/seguridad/parametros/agregar'
export const CONST_RUTA_PARAMETROS_EDITAR = '/seguridad/parametros/editar'

// Codigos Modulo Parametros
export const CONST_CODIGO_PARAMETROS_LISTAR = 'S0106000000'
export const CONST_CODIGO_PARAMETROS_AGREGAR = 'S0106010000'
export const CONST_CODIGO_PARAMETROS_EDITAR = 'S0106020000'
export const CONST_CODIGO_PARAMETROS_ELIMINAR = 'S0106030000'

/**************************** SUCURSALES *****************************************/
//Rutas Navigate sucursales
export const CONST_RUTA_SUCURSALES_LISTAR = '/seguridad/sucursales'
export const CONST_RUTA_SUCURSALES_AGREGAR = '/seguridad/sucursales/agregar'
export const CONST_RUTA_SUCURSALES_EDITAR = '/seguridad/sucursales/editar'

// Codigos Modulo Sucursales
export const CONST_CODIGO_SUCURSALES_LISTAR = 'S0107000000'
export const CONST_CODIGO_SUCURSALES_AGREGAR = 'S0107010000'
export const CONST_CODIGO_SUCURSALES_EDITAR = 'S0107020000'
export const CONST_CODIGO_SUCURSALES_ELIMINAR = 'S0107030000'

