import { useState, useEffect } from 'react';
const tamMobile = 768

export const useMobileDetection = () => {
  const [isMobile, setIsMobile] = useState(() => {
    if (window.innerWidth <= tamMobile) {
      return true;
    }
    return false;
  });

  const handleWindowResize = () => {
    if (window.innerWidth <= tamMobile) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleWindowResize(); // Llamar a la función al cargar la página

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return isMobile;
};