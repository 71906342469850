import { useEffect, useState } from "react"
import { dataSelector } from "../api/dataSelector"
import { DataContext } from "./DataContext"
import { usuarioObtenerCompaniasYSedes, usuarioObtenerCompaniasYSucursales, usuarioObtenerRutasDeAcceso } from "../api/usuarioApi"
import { obtenerLocalStorage } from "../resources/funciones"
import { CONST_KEY_USUARIO_AUTENTICADO } from "../constants/constanst-valores"

export const DataProvider = ({ children }) => {

    const [listaCompanias, setListaCompanias] = useState([])
    const [listaSucursales, setListaSucursales] = useState([])
    const [listaSedesPorUsuario, setListaSedesPorUsuario] = useState([])
    const [listaTipoDocumento, setListaTipoDocumento] = useState([])
    const [listaUbigeo, setListaUbigeo] = useState([])
    const [listaRutasDeAcceso, setListaRutasDeAcceso] = useState([])

    const getDataSelector = async () => {
        const { tiposIdentificacion, ubigeos } = await dataSelector()
        setListaTipoDocumento(tiposIdentificacion)
        setListaUbigeo(ubigeos)
    }

    const getCompaniasYSedesPorUsuario = async(token) => {
        const dataPOST = {
            token
        }
        const {companias, sucursales, sedes} = await usuarioObtenerCompaniasYSucursales(dataPOST)

        setListaCompanias(companias)
        setListaSucursales(sucursales)
        setListaSedesPorUsuario(sedes)
        return {
            companias: companias,
            sucursales: sucursales,
            primeraCompania: companias?.[0]?.id,
            primeraSucursal: sucursales?.[0]?.id,
        }
    }

    const getRutasDeAcceso = async(token, parametro) => {
        const dataPOST ={
            token,
            parametro
        }
        const {rutas} = await usuarioObtenerRutasDeAcceso(dataPOST)
        setListaRutasDeAcceso(rutas)
    }

    useEffect(() => {
        getDataSelector()
    }, [])

    return (
        <DataContext.Provider
            value={{
                listaCompanias, setListaCompanias, 
                listaSucursales, setListaSucursales,
                listaSedesPorUsuario, setListaSedesPorUsuario,
                listaTipoDocumento, setListaTipoDocumento,
                listaUbigeo, setListaUbigeo,
                getDataSelector, getCompaniasYSedesPorUsuario,
                getRutasDeAcceso, listaRutasDeAcceso
            }}>
            {children}
        </DataContext.Provider>
    )
}
