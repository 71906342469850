import { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { listaEstadosActivo } from "../../../constants/constants-selectores";
import { DataContext } from "../../../context/DataContext";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { sedeCrear, sedeEditar } from "../../../api/sedeApi";
import { CONST_RUTA_SEDES_LISTAR } from "../../../constants/constants-rutas-codigos";
import { obtenerLocalStorage } from "../../../resources/funciones";
import { CONST_KEY_USUARIO_AUTENTICADO, CONST_ROL_MASTER } from "../../../constants/constanst-valores";
import { sucursalesPorCompania } from "../../../api/sucursalApi";
import { usuarioObtenerCompaniasYSucursales } from "../../../api/usuarioApi";

export const SedeForm = () => {

    const usuarioAutenticado = obtenerLocalStorage(CONST_KEY_USUARIO_AUTENTICADO);
    const rol = usuarioAutenticado?.tipo

    const { listaUbigeo } = useContext(DataContext)
    const [listaCompanias, setListaCompanias] = useState([])
    const [listaSucursales, setListaSucursales] = useState([])

    const [mensaje, setMensaje] = useState('')
    const [mostrar, setMostrar] = useState(false)
    const [tipoAlerta, setTipoAlerta] = useState("")
    const [idSedeInicial, setIdSedeInicial] = useState("")

    //Obtener la accion a partir de la url (agregar o editar)
    const location = useLocation();
    const pathname = location.pathname;

    const sede = location?.state || {}

    const accion = pathname.split("/").pop()

    const obtenerUbigeo = listaUbigeo.find(u => u.id == sede?.SedCodUbi)

    const { register, handleSubmit, watch, formState: { errors }, reset, control, setValue } = useForm({
        defaultValues: {
            horario_desde: sede?.SedHoraIni || "",
            horario_hasta: sede?.SedHoraFin || "",
            desc_corta: sede?.SedDesc || "",
            desc_larga: sede?.SedDescLarg || "",
            estado: sede?.SedEst || "A",
            direccion: sede?.SedDirec || "",
            depProvDis: obtenerUbigeo || ""
        },
    });

    const onSubmitCrear = async (data) => {

        const dataPOST = {
            compania: data?.sucursal || "",
            descripcion: data?.desc_corta?.toUpperCase().trim() || "",
            descripcionLarga: data?.desc_larga?.toUpperCase().trim() || "",
            horaInicio: data?.horario_desde || "",
            horaFin: data?.horario_hasta || "",
            direccion: data?.direccion?.toUpperCase().trim() || "",
            estado: data?.estado || "",
            ubigeo: data?.depProvDis?.id || "",
            codigo: idSedeInicial == "" ? "" : idSedeInicial,
            token: usuarioAutenticado?.token
        }

        if (idSedeInicial == "") {
            const { status, message, idSede } = await sedeCrear(dataPOST)
            setMostrar(true)
            setMensaje(message)
            setIdSedeInicial(idSede)
            // status == 1 ? setTipoAlerta("success") : setTipoAlerta("warning")
            if(status == 1){
                setTipoAlerta("success")
                navigate(CONST_RUTA_SEDES_LISTAR)
            }else{
                setTipoAlerta("warning")
            }
        } else {
            const { status, message } = await sedeEditar(dataPOST)
            setMostrar(true)
            setMensaje(message)
            status == 1 ? setTipoAlerta("success") : setTipoAlerta("warning")
        }

    }

    const onSubmitEditar = async (data) => {

        const dataPOST = {
            compania: data?.sucursal || "",
            codigo: sede?.SedCod || "",
            descripcion: data?.desc_corta?.toUpperCase().trim() || "",
            descripcionLarga: data?.desc_larga?.toUpperCase().trim() || "",
            horaInicio: data?.horario_desde || "",
            horaFin: data?.horario_hasta || "",
            direccion: data?.direccion?.toUpperCase().trim(),
            estado: data?.estado,
            ubigeo: data?.depProvDis?.id,

            token: usuarioAutenticado?.token
        }

        const { status, message } = await sedeEditar(dataPOST)
        setMostrar(true)
        setMensaje(message)

        status == 1 ? setTipoAlerta("success") : setTipoAlerta("warning")
    }

    const obtenerCompaniasYSucursales = async () => {

        const dataPOST = {
            token: usuarioAutenticado?.token
        }

        const { companias, sucursales } = await usuarioObtenerCompaniasYSucursales(dataPOST)
        setListaCompanias(companias)
        setListaSucursales(sucursales)
        setValue("compania", sede?.comp_pad_cod || companias?.[0]?.id)

        return {
            companias,
            sucursales
        }
    }


    const obtenerSucursalesPorCompania = async (idCompania) => {

        if (idCompania) {
            const dataPOST = {
                token: usuarioAutenticado?.token,
                Codigo: idCompania,
                Estado: "A"
            }

            const { sucursales } = await sucursalesPorCompania(dataPOST)

            setListaSucursales(sucursales)
            setValue("sucursal", sede?.CompCod || sucursales?.[0]?.CompCod)
        }
    }

    const navigate = useNavigate();

    const cancelar = () => {
        navigate(CONST_RUTA_SEDES_LISTAR)
    }

    useEffect(() => {

        const cargarDatosIniciales = async () => {

            const { companias, sucursales } = await obtenerCompaniasYSucursales(usuarioAutenticado?.token)

            if (rol == CONST_ROL_MASTER) {
                await obtenerSucursalesPorCompania(watch("compania"));
            } else {
                const listaSucursalesFormateada = sucursales?.map(s => ({
                    CompCod: s?.id,
                    CompNom: s?.tipo
                }))

                setListaSucursales(listaSucursalesFormateada)
                setValue("sucursal", listaSucursalesFormateada?.[0]?.CompCod)
            }
        };

        cargarDatosIniciales();

    }, []);

    return (
        <div className="p-3">
            <div className="container-fluid">
                <h3 className="text-center mt-1">Sede</h3>
                <div className="row mt-3">
                    <div className="col-12 col-lg-9 mx-auto mt-0">
                        {/* Formulario */}
                        <form id="CreateForm" onSubmit={accion == "agregar" ? handleSubmit(onSubmitCrear) : handleSubmit(onSubmitEditar)}>
                            <div className="row border p-1 pb-2 rounded-5 g-2">
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Compañía</label>
                                        <select
                                            {...register("compania", {
                                                required: true,
                                                onChange: async (e) => {
                                                    await obtenerSucursalesPorCompania(e.target.value)
                                                }
                                            })}
                                            className="form-select form-select-sm"
                                            disabled={accion == "editar" ? true : false}
                                            value={watch("compania")}
                                        >
                                            {
                                                listaCompanias?.length > 0 && listaCompanias.map(c => (
                                                    <option key={c?.id} value={c?.id}>{c?.tipo}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    {errors.compania && errors.compania.type === "required" && <p role="alert" className="error px-2 mb-0">Ingrese Compañía</p>}
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Sucursal</label>
                                        <select
                                            {...register("sucursal", {
                                                required: true
                                            })}
                                            className="form-select form-select-sm"
                                            disabled={accion == "editar" ? true : false}
                                            value={watch("sucursal")}
                                        >
                                            {
                                                listaSucursales?.length > 0 && listaSucursales.map(c => (
                                                    <option key={c?.CompCod} value={c?.CompCod}>{c?.CompNom}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    {errors.sucursal && errors.sucursal.type === "required" && <p role="alert" className="error px-2 mb-0">Ingrese Sucursal</p>}
                                </div>

                                <div className="col-12 col-lg-12">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 col-lg-2 text-start px-2">Descripción corta</label>
                                        <input
                                            {...register("desc_corta")}
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-12">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 col-lg-2 text-start px-2">Descripción larga</label>
                                        <input
                                            {...register("desc_larga")}
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-12">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 col-lg-2 text-start px-2">Distrito</label>
                                        <Controller

                                            name="depProvDis"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    disablePortal
                                                    size="small"
                                                    id="combo-box-demo"
                                                    className="autocomplete-personalizado"
                                                    options={listaUbigeo}
                                                    getOptionLabel={(option) => option.tipo}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            placeholder="Ingrese distrito"
                                                            variant="outlined"
                                                        />
                                                    }
                                                    value={obtenerUbigeo}
                                                    onChange={(e, newValue) => field.onChange(newValue)} // Actualizar el valor del campo "distrito" cuando se seleccione una opción
                                                />
                                            )}
                                        />

                                    </div>
                                    {errors.depProvDis && errors.depProvDis.type === "required" && <p role="alert" className="error px-2 mb-0">Ingrese Distrito</p>}
                                </div>
                                <div className="col-12 col-lg-12">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 col-lg-2 text-start px-2">Dirección</label>
                                        <input
                                            {...register("direccion")}
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 px-2">Horario desde</label>
                                        <div className="col-3 pe-2">
                                            <input
                                                {...register("horario_desde")}
                                                type="time"
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                            />
                                        </div>
                                        <label className="col text-center">hasta: </label>
                                        <div className="col-3">
                                            <input
                                                {...register("horario_hasta")}
                                                type="time"
                                                className="form-control form-control-sm"
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Estado</label>
                                        <select {...register("estado")} className="form-select form-select-sm" >
                                            {
                                                listaEstadosActivo?.length > 0 && listaEstadosActivo.map(e => (
                                                    <option key={e?.id} value={e?.id}>{e?.tipo}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div className='mt-4 botones-accion text-center'>
                            {
                                accion == "agregar" && (
                                    <button className='btn btn-sm btn-primario col-md-2' type="submit" form="CreateForm" onClick={handleSubmit(onSubmitCrear)}>Guardar</button>
                                )
                            }
                            {
                                accion == "editar" && (
                                    <button className='btn btn-sm btn-primario col-md-2' type="submit" form="CreateForm" onClick={handleSubmit(onSubmitEditar)}>Guardar</button>
                                )
                            }
                            <button className='btn btn-sm btn-secundario col-md-2' onClick={cancelar}>Cancelar</button>

                        </div>

                        {/* Fin formulario */}
                    </div >

                </div>


                {
                    mostrar && (
                        <div className="row mt-4">
                            <div className="col-lg-6 mx-auto text-center alert-error">
                                <Stack spacing={2}>
                                    <Alert variant="outlined" severity={"success"} >{mensaje}</Alert>
                                </Stack>
                            </div>
                        </div>

                    )
                }
            </div>
        </div>
    )
}
