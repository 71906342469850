import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { listaEstadosActivo } from "../../../constants/constants-selectores";
import { modulosBuscar } from "../../../api/moduloApi";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { parametroCrear, parametroEditar } from "../../../api/parametroApi";
import { CONST_RUTA_PARAMETROS_LISTAR } from "../../../constants/constants-rutas-codigos";
import { usuarioObtenerCompaniasYSucursales } from "../../../api/usuarioApi";
import { CONST_KEY_USUARIO_AUTENTICADO } from "../../../constants/constanst-valores";
import { obtenerLocalStorage } from "../../../resources/funciones";

export const ParametroForm = () => {

    const usuarioAutenticado = obtenerLocalStorage(CONST_KEY_USUARIO_AUTENTICADO);
    
    const [listaCompanias, setListaCompanias] = useState([])

    // const [listaModulosPorCompania, setListaModulosPorCompania] = useState([])

    const [mensaje, setMensaje] = useState('')
    const [mostrar, setMostrar] = useState(false)
    const [tipoAlerta, setTipoAlerta] = useState("")

    //Obtener la accion a partir de la url (agregar o editar)
    const location = useLocation();
    const pathname = location.pathname;

    const parametro = location?.state || {}

    const accion = pathname.split("/").pop()

    const { register, handleSubmit, watch, formState: { errors }, reset, setValue } = useForm({
        defaultValues: {
            codigo: parametro?.ParamCod || "",
            descripcion: parametro?.ParamDes || "",
            valor: parametro?.ParamVal || ( accion=="agregar" ? `{"modulo":1}` : ""),
            estado: parametro?.ParamEst || "A"
        }
    });

    const onSubmitCrear = async (data) => {
        const dataPOST = {
            codi_parametro: data?.codigo?.toUpperCase().trim() || "",
            descripcion: data?.descripcion?.toUpperCase().trim() || "" ,
            compania: data?.compania,
            valor: data?.valor,
            estado: data?.estado
        }

        const { status, message } = await parametroCrear(dataPOST)

        setMostrar(true)
        setMensaje(message)
        // status == 1 ? setTipoAlerta("success") : setTipoAlerta("warning")
        if(status == 1){
            setTipoAlerta("success")
            navigate(CONST_RUTA_PARAMETROS_LISTAR)
        }else{
            setTipoAlerta("warning")
        }

    }

    const onSubmitEditar = async (data) => {

        const dataPOST = {
            codi_parametro: data?.codigo?.toUpperCase().trim() || "",
            descripcion: data?.descripcion?.toUpperCase().trim() || "" ,
            compania: parametro?.CompCod || "",
            valor: data?.valor,
            estado: data?.estado
        }

        const { status, message } = await parametroEditar(dataPOST)
        setMostrar(true)
        setMensaje(message)

        status == 1 ? setTipoAlerta("success") : setTipoAlerta("warning")
    }

    const navigate = useNavigate();

    const cancelar = () => {
        navigate(CONST_RUTA_PARAMETROS_LISTAR)
    }

    const obtenerCompaniasYSucursales = async () => {

        const dataPOST = {
            token: usuarioAutenticado?.token
        }

        const { companias } = await usuarioObtenerCompaniasYSucursales(dataPOST)
        setListaCompanias(companias)
        setValue("compania", parametro?.CompCod || companias?.[0]?.id)

        return {
            companias,
        }
    }

    useEffect(() => {

        const cargarDatosIniciales = async () => {
          const {companias} = await obtenerCompaniasYSucursales()
        };
    
        cargarDatosIniciales();
      }, [])


    return (
        <div className="p-3">
            <div className="container-fluid">
                <h3 className="text-center mt-1">Parámetro</h3>
                <div className="row mt-3">
                    <div className="col-12 col-lg-9 mx-auto">
                        {/* Formulario */}
                        <form id="CreateForm" className="row g-2 p-1">
                            <div className="row border p-1 pb-2 rounded-5 g-2">
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Compañía</label>
                                        <select
                                            {...register("compania")}
                                            className="form-select form-select-sm"
                                            // onChange={(e) => obtenerModulosPorCompania(e.target.value)}
                                            disabled={accion == "editar" ? true : false}
                                            value={watch("compania")}
                                        >
                                            {
                                                listaCompanias?.length > 0 && listaCompanias.map(c => (
                                                    <option key={c?.id} value={c?.id}>{c?.tipo}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Código</label>
                                        <input
                                            {...register("codigo", {
                                                required: {
                                                    value: true,
                                                }
                                            })}
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            readOnly={accion == "editar" ? true : false}
                                        />
                                    </div>
                                    {errors.codigo && errors.codigo.type === "required" && <p role="alert" className="error px-2 mb-0">Ingrese código</p>}
                                </div>
                                <div className="col-12 col-lg-12">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 col-lg-2 text-start px-2">Descripción</label>
                                        <input
                                            {...register("descripcion")}
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-lg-12">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 col-lg-2 text-start px-2">Valor</label>
                                        <input
                                            {...register("valor")}
                                            type="text"
                                            placeholder={`{"clave": "valor"}`}
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            style={{ textTransform: "none" }}

                                        />
                                        
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Estado</label>
                                        <select {...register("estado")} className="form-select form-select-sm" >
                                            {
                                                listaEstadosActivo?.length > 0 && listaEstadosActivo.map(c => (
                                                    <option key={c?.id} value={c?.id}>{c?.tipo}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>

                        </form>
                        {/* Fin formulario */}
                    </div >

                </div>

                <div className='mt-4 botones-accion text-center'>
                    {
                        accion == "agregar" && (
                            <button className='btn btn-sm btn-primario col-md-2' type="submit" form="CreateForm" onClick={handleSubmit(onSubmitCrear)}>Guardar</button>
                        )
                    }
                    {
                        accion == "editar" && (
                            <button className='btn btn-sm btn-primario col-md-2' type="submit" form="CreateForm" onClick={handleSubmit(onSubmitEditar)}>Guardar</button>
                        )
                    }
                    <button className='btn btn-sm btn-secundario col-md-2' onClick={cancelar}>Cancelar</button>

                </div>

                {
                    mostrar && (
                        <div className="row mt-4">
                            <div className="col-lg-6 mx-auto text-center alert-error">
                                <Stack spacing={2}>
                                    <Alert variant="outlined" severity={tipoAlerta} >{mensaje}</Alert>
                                </Stack>
                            </div>
                        </div>
                    )
                }

            </div>
        </div>
    )
}
