export const configColoresCompaniaSUPERADMIN = {
    CONST_COLOR_FONDO_MENU_PRIMARIO: "#1c2536",
    CONST_COLOR_TEXTO_NAV_ITEM: "#9DA4AE",
    CONST_FONDO_NAV_ITEM_ACTIVO: "#545c64",
    CONST_TEXTO_NAV_ITEM_ACTIVO: "#ffffff",
    
    CONST_COLOR_BUTTON_PRIMARIO: "#6366f1",
    CONST_COLOR_BUTTON_PRIMARIO_HOVER: "#4338CA",
    CONST_COLOR_BUTTON_PRIMARIO_TEXT: "#ffffff",

    CONST_COLOR_BUTTON_SECUNDARIO: "#9DA4AE",
    CONST_COLOR_BUTTON_SECUNDARIO_HOVER: "#6b727f",
    CONST_COLOR_BUTTON_SECUNDARIO_TEXT: "#ffffff",
}

