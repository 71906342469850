import { useState, useEffect } from "react"
import { AuthContext } from "./AuthContext"
import { guardarLocalStorage, obtenerLocalStorage } from "../resources/funciones"
import { CONST_KEY_USUARIO_AUTENTICADO } from "../constants/constanst-valores"
import { useNavigate } from "react-router-dom"

export const AuthProvider = ({ children }) => {
    
    const [usuarioAutenticado, setUsuarioAutenticado] = useState(null)

    const navigate = useNavigate()

    const authLogout = () => {
        guardarLocalStorage(null, CONST_KEY_USUARIO_AUTENTICADO)
        navigate("/")
    }

    return (
        <AuthContext.Provider
            value={{
                usuarioAutenticado, setUsuarioAutenticado,
                authLogout
            }}>
            {children}
        </AuthContext.Provider>
    )
}
