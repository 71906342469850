import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select'
import { grupoListarUsuariosPorCompaniaYSede, grupoListarUsuariosPorCompaniaYSucursales, gruposAniadirUsuariosPorGrupo } from '../../../api/grupoApi';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { CONST_RUTA_GRUPOS_USUARIOS } from '../../../constants/constants-rutas-codigos';
import { obtenerLocalStorage } from '../../../resources/funciones';
import { CONST_KEY_USUARIO_AUTENTICADO, CONST_ROL_MASTER } from '../../../constants/constanst-valores';
import { DataContext } from '../../../context/DataContext';
import { sucursalesPorCompania } from '../../../api/sucursalApi';
import { useForm } from 'react-hook-form';
import { sedesPorCompania } from '../../../api/sedeApi';
import { useEffect } from 'react';
import { usuarioObtenerCompaniasYSucursales } from '../../../api/usuarioApi';

export const GrupoUsuariosAgregar = () => {

    const [listaCompanias, setListaCompanias] = useState([])
    const [listaSucursales, setListaSucursales] = useState([])

    const usuarioAutenticado = obtenerLocalStorage(CONST_KEY_USUARIO_AUTENTICADO);
    const rol = usuarioAutenticado?.tipo

    const location = useLocation()

    const nombre_grupo = location?.state?.nombre_grupo
    const compania = location?.state?.compania

    const codigo_grupo = location?.state?.codigo_grupo

    const [mensaje, setMensaje] = useState("")
    const [mostrar, setMostrar] = useState(false)
    const [tipoAlerta, setTipoAlerta] = useState("")

    const [listaUsuarios, setListaUsuarios] = useState(/* listaFormateada ||  */[])
    const [selectedUsuarios, setSelectedUsuarios] = useState([]);

    const { register, handleSubmit, watch, formState: { errors }, reset, setValue } = useForm({
        defaultValues: {
        }
    });

    const navigate = useNavigate()

    const c = selectedOptions => {
        setSelectedUsuarios(selectedOptions);
    };

    const agregarUsuariosAlGrupo = async () => {
        const selectedListaUsuario = selectedUsuarios.map(option => (
            {
                cod_usuario: option.value,
                estado: "A"
            }
        ));

        const dataPOST = {
            grupo: codigo_grupo,
            compania: compania,
            usuarios: selectedListaUsuario,
            token: usuarioAutenticado?.token
        }

        const { status, message } = await gruposAniadirUsuariosPorGrupo(dataPOST)
        status == 1 ? setTipoAlerta("success") : setTipoAlerta("warning")
        setMostrar(true)
        setMensaje(message)
    };

    const cancelar = () => {
        navigate(CONST_RUTA_GRUPOS_USUARIOS, {
            state: {
                codigo_grupo,
                compania,
                nombre_grupo
            }
        })
    }

    const obtenerUsuariosSiCambioCompania = async (idCompania) => {

        setSelectedUsuarios([])
        const dataPOST_1 = {
            token: usuarioAutenticado?.token,
            Codigo: idCompania,
            Estado: "A"
        }
        const { sucursales } = await sucursalesPorCompania(dataPOST_1)

        setListaSucursales(sucursales)
        setValue("sucursal", sucursales?.[0]?.CompCod)

        const dataPOST_2 = {
            Compania: watch("compania"),
            Sucursal: watch("sucursal"),
            Grupo: codigo_grupo,
            token: usuarioAutenticado?.token
        }

        const { usuarios } = await grupoListarUsuariosPorCompaniaYSucursales(dataPOST_2)

        const listaFormateadaUsuarios = usuarios?.map(u => (
            {
                value: u?.codigo,
                label: u?.nombre
            }
        ))

        setListaUsuarios(listaFormateadaUsuarios)

    }

    const obtenerUsuariosSiCambioSucursal = async (idSucursal) => {

        setSelectedUsuarios([])
        const dataPOST = {
            Compania: watch("compania"),
            Sucursal: idSucursal,
            Grupo: codigo_grupo,
            token: usuarioAutenticado?.token
        }

        const { usuarios } = await grupoListarUsuariosPorCompaniaYSucursales(dataPOST)

        const listaFormateadaUsuarios = usuarios?.map(u => (
            {
                value: u?.codigo,
                label: u?.nombre
            }
        ))

        setListaUsuarios(listaFormateadaUsuarios)
    }

    const obtenerCompaniasYSucursales = async () => {

        const dataPOST = {
          token: usuarioAutenticado?.token
        }
    
        const { companias, sucursales } = await usuarioObtenerCompaniasYSucursales(dataPOST)
        
        setListaCompanias(companias)
        setListaSucursales(sucursales)
        
        setValue("compania", compania)

        return {
          sucursales
        }
      }

      const obtenerSucursalesPorCompaniaPorDefecto = async (idCompania) => {

        if (idCompania && idCompania != "T") {
          const dataPOST = {
            token: usuarioAutenticado?.token,
            Codigo: idCompania,
            Estado: "A"
          }
          const { sucursales } = await sucursalesPorCompania(dataPOST)
          setListaSucursales(sucursales)
          setValue("sucursal", watch("sucursal") == "" ? sucursales?.[0]?.CompCod : watch("sucursal"))
        }
      }
    

      useEffect(() => {

        const cargarDatosIniciales = async () => {
          const {sucursales} = await obtenerCompaniasYSucursales(usuarioAutenticado?.token)
          if (rol == CONST_ROL_MASTER) {
            await obtenerSucursalesPorCompaniaPorDefecto(compania);
          } else {
            const listaSucursalesFormateada = sucursales?.map(s => ({
              CompCod: s?.id,
              CompNom: s?.tipo
            }))
            setListaSucursales(listaSucursalesFormateada)
            setValue("sucursal", listaSucursalesFormateada?.[0]?.CompCod)
          }
    
          await obtenerUsuariosSiCambioSucursal(watch("sucursal"));
        };
    
        cargarDatosIniciales();

      }, [])


    return (
        <div className="p-3">
            <div className="container-fluid">
                <h4 className="text-center mt-1">Añadir Usuarios al grupo: {nombre_grupo}</h4>
                <div className="row mt-4">
                    <div className="col-12 col-lg-8 mx-auto mt-0 border p-1 rounded-5 bg-form">
                        {/* Formulario */}
                        <form className="row g-2 p-1">

                            <div className="col-12 col-lg-6">
                                <label className="text-start">Compañía</label>
                                <select
                                    {...register("compania", {
                                        onChange: async (e) => {
                                            await obtenerUsuariosSiCambioCompania(e.target.value)
                                        }
                                    })}
                                    className="form-select form-select-sm" 
                                    value={watch("compania")}
                                    disabled={true}
                                >
                                    {
                                        listaCompanias?.length > 0 && listaCompanias.map(e => (
                                            <option key={e?.id} value={e?.id}>{e?.tipo}</option>
                                        ))
                                    }
                                </select>
                            </div>
                            <div className="col-12 col-lg-6">
                                <label className="text-start">Sucursal</label>
                                <select
                                    {...register("sucursal", {
                                        onChange: async (e) => {
                                            await obtenerUsuariosSiCambioSucursal(e.target.value)
                                        }
                                    })}
                                    className="form-select form-select-sm"
                                    value={watch("sucursal")}
                                  
                                >

                                    {
                                        listaSucursales?.length > 0 && listaSucursales.map(e => (
                                            <option key={e?.CompCod} value={e?.CompCod}>{e?.CompNom}</option>
                                        ))
                                    }
                                    {/* <option value={"T"}>TODOS</option> */}
                                </select>
                            </div>

                            <div className="col-12 col-lg-12">
                               
                                    <label className="text-start">Ingrese usuario(s)</label>
                                    <div>
                                        <Select
                                            isMulti
                                            name="colors"
                                            options={listaUsuarios}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            value={selectedUsuarios}
                                            onChange={setSelectedUsuarios}
                                        />
                                    </div>
                              
                            </div>
                        </form>
                        {/* Fin formulario */}
                    </div >

                </div>

                <div className='mt-4 botones-accion text-center'>
                    <button className='btn btn-sm btn-primario col-md-2' onClick={agregarUsuariosAlGrupo}>Guardar</button>
                    <button className='btn btn-sm btn-secundario col-md-2' onClick={cancelar}>Cancelar</button>
                </div>


                {
                    mostrar && (
                        <div className="row mt-4">
                            <div className="col-lg-6 mx-auto text-center alert-error">
                                <Stack spacing={2}>
                                    <Alert variant="outlined" severity={tipoAlerta} >{mensaje}</Alert>
                                </Stack>
                            </div>
                        </div>

                    )
                }

            </div>
        </div>
    )
}
