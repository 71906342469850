import { api } from "./configApi"

export const usuarioCrear = async (dataPOST) => {
    try {

        const res = await api.post("api/usuario/Agregar", dataPOST)
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                usuario: res.data.username,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                usuario: "",
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            usuario: "",
            message: "Error en el servidor",
        };
    }
}

export const usuariosBuscar = async (dataPOST) => {
    try {

        const res = await api.post("api/usuario/BuscarUsuario", dataPOST)
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                usuarios: res.data.resultado,
                message: res.data.message,
            }
        }

    } catch (error) {
        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                usuarios: [],
                message: error.response.data.message,
            };
        }
        return {
            status: -1,
            usuarios: [],
            message: "Error en el servidor",
        }
    }
}

export const usuarioDelete = async (dataPOST) => {
    try {

        const res = await api.post("api/usuario/EliminarUsuario", dataPOST)
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
            }
        }
    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        }
    }
}

export const usuarioMostrar = async (dataPOST) => {
    try {

        const res = await api.post("api/usuario/MostrarUsuario", dataPOST)

        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                usuario: res.data.usuario,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                usuario: {},
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            usuario: {},
            message: "Error en el servidor",
        }
    }
}

export const usuarioEditar = async (dataPOST) => {
    try {

        const res = await api.post("api/usuario/ActualizarUsuario", dataPOST)
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        }
    }
}

export const usuarioAsignarGrupos = async (dataPOST) => {
    try {

        const res = await api.post("api/usuario/AddUserGroup", dataPOST)
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {

            return {
                status: error.response.data.status,
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        }
    }
}

export const grupoMostrarGruposPorUsuario = async (dataPOST) => {
    try {
        const res = await api.post("api/usuario/MostrarUserGroup", dataPOST)

        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
                grupos: res.data.lista_grupo
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                message: error.response.data.message,
                grupos: []
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
            grupos: []
        }
    }
}

export const usuarioListarTodosPorCompania = async (dataPOST) => {
    try {

        const res = await api.post("api/usuario/ListarUsuario", dataPOST)

        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
                usuarios: res.data.lista
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                message: error.response.data.message,
                usuarios: []
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
            usuarios: []
        }
    }
}

// Esta api funciona tanto para master y usuario normal
export const usuarioObtenerCompaniasYSucursales = async (dataPOST) => {
    try {

        const res = await api.post("api/usuario/UsuarioXSede", dataPOST)

        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
                companias: res.data.compania,
                sucursales: res.data.sucursales,
                sedes: res.data.sedes
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                message: error.response.data.message,
                companias: [],
                sucursales: [],
                sedes: []
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
            companias: [],
            sucursales: [],
            sedes: []
        }
    }
}

export const usuarioObtenerRutasDeAcceso = async (dataPOST) => {
    try {

        const res = await api.post("api/usuario/RutaPlana", dataPOST)

        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
                rutas : res.data.ruta_plana
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                message: error.response.data.message,
                rutas : []
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
            rutas: []
        }
    }
}


export const usuarioCambiarContrasenia = async (dataPOST) => {
    try {

        const res = await api.post("api/usuario/ActualizarContrasenia", dataPOST)

        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        }
    }
}



