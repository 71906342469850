import { NavLink, useNavigate } from "react-router-dom";
import { useState, useContext } from "react";

//Iconos
import { FaBars, FaHome, FaUser } from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";
import { RiLogoutBoxLine, RiTeamFill } from "react-icons/ri";
import { BiUserCircle } from "react-icons/bi"
// import { BsShieldLockFill } from "react-icons/bs";
// import { RxModulzLogo } from "react-icons/rx"
// import { AiFillBank } from "react-icons/ai"
// import { MdOutlineLocationCity } from "react-icons/md"

import Swal from 'sweetalert2';
import { SidebarMenuRecursive } from "./SidebarMenuRecursive"

import { AuthContext } from "../../context/AuthContext";
import { desencriptar, limpiarLocalStorage, obtenerLocalStorage } from "../../resources/funciones";
import { CONST_KEY_USUARIO_AUTENTICADO, CONST_ROL_MASTER, CONST_TIPO_MODULO_MENU_OPCION, CONST_TIPO_MODULO_MENU_WPANEL_TRANSACCION } from "../../constants/constanst-valores";

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import { blue } from '@mui/material/colors';
import { navegarOtraApp } from "../../api/authApi";
import { CambiarContrasenia } from "./CambiarContrasenia";

// const routes = [
//   {
//     path: "/seguridad",
//     name: "Seguridad",
//     // icon: <BsShieldLockFill />,
//     subRoutes: [
//       {
//         path: "/seguridad/usuarios",
//         name: "Usuarios",
//       },
//       {
//         path: "/seguridad/grupos",
//         name: "Grupos",
//         // icon: <RiTeamFill />,
//         // subRoutes: [
//         //   {
//         //     path: "/seguridad/grupos/agregar",
//         //     name: "Agregar",
//         //     // icon: <RiTeamFill />,
//         //   },
//         //   {
//         //     path: "/seguridad/grupos",
//         //     name: "Listar",
//         //     // icon: <RiTeamFill />,
//         //   },
//         // ],
//       },
//       {
//         path: "/seguridad/modulos",
//         name: "Módulos",
//         // icon: <RxModulzLogo />,
//       },
//       {
//         path: "/seguridad/companias",
//         name: "Compañias",
//         // icon: <AiFillBank />,
//       },
//       {
//         path: "/seguridad/sedes",
//         name: "Sedes",
//         // icon: <MdOutlineLocationCity />,
//       },
//       {
//         path: "/seguridad/parametros",
//         name: "Parámetros",
//       },
//     ],
//   },
//   {
//     path: null,
//     name: "Salir",
//     // icon: <RiLogoutBoxLine />,
//   },
// ];

export const SideBar = ({ children, ruta }) => {

  const usuarioLS = obtenerLocalStorage(CONST_KEY_USUARIO_AUTENTICADO)

  const widthOpen = "11.5rem"
  const widthClose = "2.8rem"

  // Obtener los valores de las variables CSS cuando el componente se monta
  const computedStyles = getComputedStyle(document.documentElement);

  let routes = [];

  let route = usuarioLS?.data?.subRoutes || []

  routes = routes.concat(route, {
    path: null,
    name: "SALIR",
    tipo: CONST_TIPO_MODULO_MENU_WPANEL_TRANSACCION,
    icon: <RiLogoutBoxLine className="color-iconos-sidebar" style={{ fontSize: "24px" }} />,
  })

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  const { authLogout } = useContext(AuthContext)

  const logo_primario_guardado = usuarioLS?.estilo?.log_primario ? `data:image/png;base64,${usuarioLS?.estilo?.log_primario}` : null
  // const logo_secundario_guardado = usuarioLS?.estilo?.log_primario?.slice(24,) ? "data:image/jpeg;base64,/9j/" + usuarioLS?.estilo?.log_secundario?.slice(24,) : ""

  const navigate = useNavigate()

  const salirSesion = () => {
    Swal.fire({
      title: 'Salir sesión',
      text: `¿Estás seguro(a) de salir sesión?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Salir',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#d33',
      customClass: {
        confirmButton: 'btn-salir',
        cancelButton: 'btn-cancelar',
      },
    }).then((result) => {
      if (result.value) {
        authLogout()
        navigate(`/`)
      }
    });
  }

  const [aplicaciones, setAplicaciones] = useState([])
  const [openModalAplicaciones, setOpenModalAplicaciones] = useState(false);

  const handleClickOpen = async () => {
    const dataPOST = {
      token: usuarioLS?.token
    }

    const { apps } = await navegarOtraApp(dataPOST)

    setAplicaciones(apps)
    setOpenModalAplicaciones(true);

  };

  const handleClose = () => {
    setOpenModalAplicaciones(false);
  };


  const [openModalCambioContrasenia, setOpenModalCambioContrasenia] = useState(false);
  const handleOpenCambioContrasenia = () => setOpenModalCambioContrasenia(true);
  const handleCloseCambioContrasenia = () => setOpenModalCambioContrasenia(false);

  return (
    <>
      <div className="h-100">
        {
          ruta != "" && (
            <motion.div
              animate={{
                minWidth: isOpen ? widthOpen : widthClose,
                width: isOpen ? "auto" : widthClose, // Establece la anchura en "auto" cuando isOpen es true, de lo contrario, establece en "2.5rem"
                transition: {
                  duration: 0,
                },
              }}
              className={`sidebar`}
              style={{ position: "fixed", height: "100%" }}
            >
              <div className={"top_section justify-content-center"} style={{ position: "relative", marginTop: !isOpen && "15px" }}>

                {
                  isOpen && (
                    logo_primario_guardado ? (<div className="d-flex justify-content-center align-items-center" style={{ height: "28px", width: "28px" }}>

                      <img
                        src={logo_primario_guardado}
                        alt="Imagen logo 1"
                        className="img-thumbnail"
                      />

                    </div>) : (
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-building" viewBox="0 0 16 16">
                          <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1ZM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z" />
                          <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V1Zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3V1Z" />
                        </svg>
                      </div>
                    )
                  )
                }


                <div className="bars text-center d-flex align-items-end justify-content-center" onClick={toggle} style={{ position: "absolute", right: "8px" }}>
                  <FaBars className="color-iconos-sidebar" style={{ fontSize: "18px" }} />
                </div>

              </div>
              <section className="routes">
                <div className="d-flex justify-content-center align-items-center" style={{ flex: 1, margin: isOpen ? '2px' : '5px' }}>
                  {/* <Avatar alt="Remy Sharp" src={logo_primario_guardado} sx={{ width: isOpen ? 30 : 24, height: isOpen ? 30 : 24 }} /> */}

                  <AnimatePresence>
                    {isOpen && (
                      <div className={isOpen ? "usuario text-center" : "usuario d-flex justify-content-center"}>
                        {/* {
                          isOpen && <span className="ms-1" style={{ fontSize: "0.688rem" }}>{usuarioLS?.user.toLowerCase().split("@")[0] || ""}</span>
                        } */}

                        {
                          isOpen && <span className="ms-1" style={{ fontSize: "0.688rem", textTransform: "capitalize" }}>{usuarioLS.nombre}</span>
                        }
                      </div>
                    )}
                  </AnimatePresence>
                </div>
                {routes?.map((route, index) => {

                  if (route?.subRoutes && route?.tipo == CONST_TIPO_MODULO_MENU_OPCION && route?.subRoutes.length != 0) {
                    return (
                      <SidebarMenuRecursive
                        key={index}
                        setIsOpen={setIsOpen}
                        route={route}
                        isOpen={isOpen}
                      />
                    );
                  } else {
                    return (
                      <NavLink
                        to={route.path}
                        key={index}
                        className={(navData) => (navData.isActive && route.path != null && isOpen ? "active link" : "none link")}
                        onClick={route.path === null ? salirSesion : undefined}
                        style={{ display: !isOpen && "flex", justifyContent: !isOpen && "center" }}
                      >
                        {
                          route.icon && (
                            <div>
                              {route.icon}
                            </div>
                          )
                        }
                        <AnimatePresence>
                          {isOpen && (
                            <motion.div
                              initial="hidden"
                              animate="show"
                              exit="hidden"
                              className="link_text"
                              style={{ borderRadius: '5px' }}
                            >
                              {route.name.toLowerCase()}
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </NavLink>
                    );
                  }
                })}
              </section>

              {
                usuarioLS?.tipo != CONST_ROL_MASTER && (
                  <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "end", marginBottom: "30px", fontSize: "0.75rem" }}>
                    {
                      isOpen && (
                        <NavLink className="btn-cambiar-psw none link" onClick={handleOpenCambioContrasenia}>
                          <motion.div
                            initial="hidden"
                            animate="show"
                            exit="hidden"
                            className="link_text"
                            style={{ borderRadius: "5px" }}
                          >
                            ¿Cambiar contraseña?
                          </motion.div>
                        </NavLink>
                      )
                    }

                  </div>
                )
              }

              <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "end", marginBottom: "30px" }}>
                <div className="boton-apps" onClick={handleClickOpen}>
                  <Box sx={{ '& > :not(style)': { m: 1 } }}>
                    <Fab aria-label="add">
                      <svg
                        height="30px" width="30px"
                        fill={computedStyles?.getPropertyValue('--color-texto-nav-item')}
                        className="gb_i color-iconos-sidebar" focusable="false" viewBox="0 0 24 24">
                        <path d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"></path>
                      </svg>
                    </Fab>
                  </Box>
                  {/* <div style={{ width: "35px", height: "35px", borderRadius: "50%", border: `1px solid ${computedStyles?.getPropertyValue('--color-button-primario')}`, textAlign: "center", verticalAlign: "center" }}>
                    <svg height="30px" width="30px" fill={computedStyles?.getPropertyValue('--color-button-primario')} className="gb_i color-iconos" focusable="false" viewBox="0 0 24 24"><path d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"></path></svg>
                  </div> */}
                </div>
              </div>

              <CambiarContrasenia open={openModalCambioContrasenia} handleClose={handleCloseCambioContrasenia} />

              <SimpleDialog
                open={openModalAplicaciones}
                onClose={handleClose}
                listaApps={aplicaciones}
              />

            </motion.div>
          )
        }
        <main className="p-0 h-100" style={{ marginLeft: isOpen ? widthOpen : widthClose }}>{children}</main>
      </div>
    </>
  );
};

const SimpleDialog = (props) => {
  const { onClose, listaApps, open } = props;
  const navigate = useNavigate()

  const handleListItemClick = (app) => {

    const usuarioLS = obtenerLocalStorage(CONST_KEY_USUARIO_AUTENTICADO)
    const token = usuarioLS?.token

    const urlApp = app?.url + "/conexionExterna/" + token

    limpiarLocalStorage(CONST_KEY_USUARIO_AUTENTICADO)
    // localStorage.removeItem("urlServer");
    // Abre la nueva ruta en una nueva ventana o pestaña
    window.location.replace(urlApp);
  };

  return (

    <Dialog
      onClose={onClose}
      open={open}
      sx={{ '& .MuiDialog-container': { justifyContent: 'flex-start', alignItems: 'flex-end', width: "350px" } }}
      PaperProps={{
        className: 'custom-dialog-paper', // Agrega una clase personalizada.
      }}
    >
      <div className="popup-aplicaciones">
        <DialogTitle sx={{ textAlign: "center" }}>Aplicaciones</DialogTitle>
        <List sx={{ display: 'flex', flexWrap: 'wrap', padding: 0 }}>
          {listaApps.map((app) => (
            <ListItem disableGutters key={app?.ModCod} sx={{ padding: '0', margin: '0', width: '33.33%' }}>
              <ListItemButton onClick={() => handleListItemClick(app)}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={app?.name} primaryTypographyProps={{ fontSize: '12px', textAlign: 'center' }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
    </Dialog>
  );
}
