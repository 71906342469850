import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../context/DataContext";
import { useNavigate } from "react-router-dom";
import { listaEstadosActivo, listaEstadosActivoBusqueda } from "../../../constants/constants-selectores";
import { useForm } from "react-hook-form";
import { FcSearch } from 'react-icons/fc';
import { RiDeleteBinFill } from 'react-icons/ri'
import { FaRegEdit } from 'react-icons/fa'
import { sedeDelete, sedeMostrar, sedesBuscar } from "../../../api/sedeApi";
import { obtenerLabel, obtenerLocalStorage } from "../../../resources/funciones";

import Swal from 'sweetalert2';
import { IoMdAdd } from "react-icons/io"
import { BiSearch } from "react-icons/bi"
import { useMobileDetection } from "../../../hook/useMobileDetection";
import { CONST_KEY_USUARIO_AUTENTICADO, CONST_ROL_MASTER } from "../../../constants/constanst-valores";
import { validarAutenticacion } from "../../../resources/funcion-validar-autenticacion";
import { CONST_RUTA_SEDES_AGREGAR, CONST_RUTA_SEDES_EDITAR, CONST_RUTA_SIN_ACCESO } from "../../../constants/constants-rutas-codigos";
import { rutasCodigosPrograma } from "../../../constants/constanst-arreglo-rutas-codigo";
import { sucursalesPorCompania } from "../../../api/sucursalApi";
import { usuarioObtenerCompaniasYSucursales } from "../../../api/usuarioApi";

export const Sedes = () => {
    const usuarioAutenticado = obtenerLocalStorage(CONST_KEY_USUARIO_AUTENTICADO);
    const rol = usuarioAutenticado?.tipo

    const { listaRutasDeAcceso } = useContext(DataContext)

    const [listaCompanias, setListaCompanias] = useState([])
    const [listaSucursales, setListaSucursales] = useState([])

    const [isLoading, setIsLoading] = useState(false)

    //Estados para ver si mostrar o no los botones
    const [mostrarBotonAgregar, setMostrarBotonAgregar] = useState(false)
    const [mostrarBotonEditar, setMostrarBotonEditar] = useState(false)
    const [mostrarBotonEliminar, setMostrarBotonEliminar] = useState(false)

    const { register, handleSubmit, watch, formState: { errors }, reset, setValue } = useForm({
        defaultValues: {
            estado: "A",
            sede: "",
        }
    });

    const [listaSedes, setListaSedes] = useState([])
    const [buscar, setBuscar] = useState(false)

    const isMobile = useMobileDetection()

    const onSubmitBuscar = async (data) => {

        setIsLoading(true)
        const dataPOST = {
            compania: data?.sucursal || listaSucursales?.[0]?.id,
            estado: data?.estado,
            sede: data?.sede,
            token: usuarioAutenticado?.token
        }

        const { sedes } = await sedesBuscar(dataPOST)
        setListaSedes(sedes)
        setBuscar(true)
        setIsLoading(false)

    };

    const navigate = useNavigate()

    const sedeAgregar = async () => {
        const rutaNavegar = CONST_RUTA_SEDES_AGREGAR
        const modulo = rutasCodigosPrograma.find(r => r.ruta == rutaNavegar)

        const validarAutenticado = await validarAutenticacion(usuarioAutenticado?.token, modulo?.codigo)

        if (validarAutenticado) {
            navigate(rutaNavegar)
        } else {
            navigate(CONST_RUTA_SIN_ACCESO)
        }

    }

    const sedeEditar = async (idSede, idCompania) => {
        const rutaNavegar = CONST_RUTA_SEDES_EDITAR
        const modulo = rutasCodigosPrograma.find(r => r.ruta == rutaNavegar)
        const validarAutenticado = await validarAutenticacion(usuarioAutenticado?.token, modulo?.codigo)

        if (validarAutenticado) {
            const dataPOST = {
                codigo: idSede,
                compania: idCompania,
                token: usuarioAutenticado?.token
            }

            const { sede } = await sedeMostrar(dataPOST)
            navigate(rutaNavegar, {
                state: sede
            })
        } else {
            navigate("/sin-acceso")
        }

    }

    const optionsEliminar = (nombreCompleto) => {
        return {
            title: `¿Estás seguro de eliminar la sede: ${nombreCompleto}?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#d33',
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar',
        }
    }

    const sedeEliminar = (idSede, idCompania, nombreSede) => {
        Swal.fire(optionsEliminar(nombreSede)).then(async (result) => {
            if (result.isConfirmed) {
                const dataPOST = {
                    compania: idCompania,
                    codigo: idSede,
                    token: usuarioAutenticado?.token
                }
                const { status } = await sedeDelete(dataPOST)
                if (status == 1) {
                    sedesBuscarActivos()
                    Swal.fire(
                        'Sede eliminada',
                        '',
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Error al eliminar sede',
                        '',
                        'success'
                    )
                }

            }
        })

    }

    const sedesBuscarActivos = async () => {

        setIsLoading(true)

        const dataPOST = {
            sede: watch("sede") || "",
            compania: watch("sucursal"),
            estado: watch("estado") || "A",
            token: usuarioAutenticado?.token
        }

        const { sedes } = await sedesBuscar(dataPOST)
        setListaSedes(sedes)
        setIsLoading(false)
        setBuscar(true)

    }

    const obtenerCompaniasYSucursales = async () => {

        const dataPOST = {
            token: usuarioAutenticado?.token
        }

        const { companias, sucursales } = await usuarioObtenerCompaniasYSucursales(dataPOST)
        setListaCompanias(companias)
        setListaSucursales(sucursales)
        setValue("compania", companias?.[0]?.id)

        return {
            companias,
            sucursales
        }
    }

    const obtenerSucursalesPorCompania = async (idCompania) => {

        if (idCompania && idCompania != "T") {
            const dataPOST = {
                token: usuarioAutenticado?.token,
                Codigo: idCompania,
                Estado: "A"
            }


            const { sucursales } = await sucursalesPorCompania(dataPOST)

            setListaSucursales(sucursales)

            setValue("compania", idCompania)
            setValue("sucursal", sucursales?.[0]?.CompCod)
        }
    }

    useEffect(() => {

        const encontradoRutaBotonAgregar = listaRutasDeAcceso?.find(r => r.codigo_programa == "S0105010000")
        const encontradoRutaBotonEditar = listaRutasDeAcceso?.find(r => r.codigo_programa == "S0105020000")
        const encontradoRutaBotonEliminar = listaRutasDeAcceso?.find(r => r.codigo_programa == "S0105030000")

        encontradoRutaBotonAgregar ? setMostrarBotonAgregar(true) : setMostrarBotonAgregar(false)
        encontradoRutaBotonEditar ? setMostrarBotonEditar(true) : setMostrarBotonEditar(false)
        encontradoRutaBotonEliminar ? setMostrarBotonEliminar(true) : setMostrarBotonEliminar(false)
    }, [listaRutasDeAcceso])

    useEffect(() => {

        const cargarDatosIniciales = async () => {
            const { companias, sucursales } = await obtenerCompaniasYSucursales()

            if (rol == CONST_ROL_MASTER) {
                await obtenerSucursalesPorCompania(watch("compania"));
            } else {
                const listaSucursalesFormateada = sucursales?.map(s => ({
                    CompCod: s?.id,
                    CompNom: s?.tipo
                }))
                setListaSucursales(listaSucursalesFormateada)
                setValue("sucursal", listaSucursalesFormateada?.[0]?.CompCod)
            }
            await sedesBuscarActivos()
        };

        cargarDatosIniciales();

    }, [])

    return (
        <div className="p-3">
            <div className="container-fluid" >

                <h3 className="text-center mt-1">Sedes</h3>

                {/* Formulario */}
                <div className="my-3 border p-1 rounded-5">
                    <form onSubmit={handleSubmit(onSubmitBuscar)} className="row p-1">

                        <div className="col-12 col-lg-3">
                            <label className="text-start">Nombre</label>
                            <input
                                {...register("sede")}
                                type="text"
                                className="form-control form-control-sm"
                                autoComplete="off"
                                placeholder="Sede"
                            />
                        </div>
                        <div className="col-12 col-lg-3">
                            <label className="text-start">Compañía</label>
                            <select
                                {...register("compania", {
                                    onChange: async (e) => {
                                        await obtenerSucursalesPorCompania(e.target.value)
                                    },
                                    required: true
                                })}
                                className="form-select form-select-sm"
                                value={watch("compania")}>
                                {
                                    listaCompanias?.length > 0 && listaCompanias.map(e => (
                                        <option key={e?.id} value={e?.id}>{e?.tipo}</option>
                                    ))
                                }
                            </select>
                        </div>

                        <div className="col-12 col-lg-3">
                            <label className="text-start">Sucursal</label>
                            <select
                                {...register("sucursal", {
                                    required: true
                                })}
                                className="form-select form-select-sm"
                                value={watch("sucursal")}
                            >
                                {
                                    listaSucursales?.length > 0 && listaSucursales.map(e => (
                                        <option key={e?.CompCod} value={e?.CompCod}>{e?.CompNom}</option>
                                    ))
                                }
                                {/* <option value={"T"}>TODOS</option> */}
                            </select>
                        </div>

                        <div className="col-12 col-lg-2">
                            <label className="text-start">Estado</label>
                            <select {...register("estado")} className="form-select form-select-sm" >
                                {
                                    listaEstadosActivoBusqueda?.length > 0 && listaEstadosActivoBusqueda.map(e => (
                                        <option key={e?.id} value={e?.id}>{e?.tipo}</option>
                                    ))
                                }
                            </select>
                        </div>

                        <div className="col-12 col-lg-1">
                            <label className="text-start">&nbsp;</label>
                            <div className={isMobile ? "text-center" : ""}>
                                <button type="submit" onClick={handleSubmit(onSubmitBuscar)} style={{ cursor: 'pointer', background: 'none', border: 'none' }}>
                                    <BiSearch size={25} className="color-iconos" />
                                </button>
                            </div>
                        </div>
                    </form>
                </div >
                {/* Fin formulario */}
                {
                    mostrarBotonAgregar && (
                        <div className="mx-auto ps-0">
                            <button className='btn btn-primario btn-sm align-middle d-flex align-items-center' onClick={sedeAgregar}>
                                <IoMdAdd size={18} />
                                <span className="ms-1">Agregar</span>
                            </button>
                        </div>
                    )
                }

                <div className="col-12 table-responsive mt-2">
                    {/* Tabla de resultados */}
                    <table className="table table-hover mt-2 bg-form">
                        <thead className="table-light">
                            <tr>
                                <th scope="col" className="col text-center">Sede</th>
                                <th scope="col" className="col text-center">Compañía</th>
                                <th scope="col" className="col text-center">Estado</th>
                                {
                                    mostrarBotonEditar && (
                                        <th scope="col" className="col text-center">Editar</th>
                                    )
                                }
                                {
                                    mostrarBotonEliminar && (
                                        <th scope="col" className="col text-center">Eliminar</th>
                                    )
                                }

                            </tr>
                        </thead>
                        <tbody>

                            {
                                isLoading ? (
                                    <tr>
                                        <td colSpan="100" className="text-center">

                                            <div className="spinner-border text-secondary spinner-border-sm" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </td>
                                    </tr>

                                ) : (
                                    listaSedes?.length > 0 ? listaSedes.map((u) => (
                                        <tr key={u?.SedCod}>
                                            <td>{u?.SedDesc}</td>
                                            <td>{u?.TMCOMP10?.CompNom}</td>
                                            <td>{obtenerLabel(listaEstadosActivo, u?.SedEst)}</td>
                                            {
                                                mostrarBotonEditar && (
                                                    <td><FaRegEdit size={23} cursor={'pointer'} className="color-iconos" onClick={() => sedeEditar(u?.SedCod, u?.CompCod)} /></td>
                                                )
                                            }
                                            {
                                                mostrarBotonEliminar && (
                                                    <td><RiDeleteBinFill size={23} cursor={'pointer'} color="#C0392B" onClick={() => sedeEliminar(u?.SedCod, u?.CompCod, u?.SedDesc)} /></td>
                                                )
                                            }
                                        </tr>
                                    )) :
                                        (
                                            (buscar) ? (
                                                <tr>
                                                    <td colSpan="100" className="text-center">No hay sedes</td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td colSpan="100" className="text-center">Realice una búsqueda</td>
                                                </tr>
                                            )
                                        )
                                )
                            }

                        </tbody>
                    </table>
                </div>

                {/* Fin Tabla de resultados */}
            </div>
        </div>
    )
}
