import { useLocation, useNavigate } from "react-router-dom"
import { Checkbox } from "../../../resources/Checkbox"
import { useForm } from "react-hook-form";
import { usuarioAsignarGrupos } from "../../../api/usuarioApi";

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useState } from "react";
import { obtenerLocalStorage } from "../../../resources/funciones";
import { CONST_KEY_USUARIO_AUTENTICADO } from "../../../constants/constanst-valores";
import { CONST_RUTA_USUARIOS_LISTAR } from "../../../constants/constants-rutas-codigos";

export const UsuarioGrupos = () => {

    const [mensaje, setMensaje] = useState('')
    const [mostrar, setMostrar] = useState(false)
    const [tipoAlerta, setTipoAlerta] = useState("")

    //Obtener la accion a partir de la url (agregar o editar)
    const location = useLocation();
    const pathname = location.pathname;

    const listaGrupos = location?.state?.grupos || []

    const nombreCompletoUsuario = location?.state?.nombreUsuario || ""
    const codigoUsuario = location?.state?.codUsuario || ""
    const compania = location?.state?.compania || ""

    const usuarioAutenticado = obtenerLocalStorage(CONST_KEY_USUARIO_AUTENTICADO);

    const { register, handleSubmit, setValue, watch } = useForm();

    const handleCheckboxChange = (id, checked) => {
        setValue(`grupos.${id}`, checked);
    };

    const onSubmit = async (data) => {

        if (Object.keys(data).length !== 0) {
            // Transformar el formato de grupos antes de enviar los datos
            const gruposFormatted = Object.keys(data.grupos).map((cod_grupo) => ({
                cod_grupo,
                estado: data.grupos[cod_grupo] ? 'A' : 'I' // A para true (seleccionado), I para false (no seleccionado)
            }));

            const dataPOST = {
                usuario: codigoUsuario,
                grupos: gruposFormatted,
                compania: compania,
                token: usuarioAutenticado?.token
            }

            const { status, message } = await usuarioAsignarGrupos(dataPOST)
            setMostrar(true)
            setMensaje(message)
            status == 1 ? setTipoAlerta("success") : setTipoAlerta("warning")


        }

    };

    const navigate = useNavigate()

    const volver = () => {
        navigate(CONST_RUTA_USUARIOS_LISTAR)
    }

    return (

        <div className="p-3">

            <div className="container-fluid">
                <h3 className="text-center mt-1">Usuario - Grupo</h3>

                <strong>Usuario: </strong>
                <label>{nombreCompletoUsuario}</label>

                {/* TABLA DE USUARIO - GRUPO */}
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <table className="table table-hover mt-3">
                                <thead className="table-light">
                                    <tr>
                                        <th scope="col" className="col-3 text-center">Codigo</th>
                                        <th scope="col" className="col-6 text-center">Grupo</th>
                                        <th scope="col" className="col-3 text-center">Estado</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        listaGrupos?.length > 0 ? listaGrupos.map((g) => {

                                            return (
                                                <tr key={g?.GrupCod}>
                                                    <td>{g?.GrupCod}</td>
                                                    <td>{g?.GrupNom}</td>
                                                    <td>
                                                        <div className="form-check d-flex justify-content-center align-items-center">
                                                            <input className="form-check-input"
                                                                type="checkbox"
                                                                id={`myCheckbox${g?.GrupCod}`}
                                                                {...register(`grupos.${g?.GrupCod}`)}
                                                                defaultChecked={g?.UsGrEst === 'A'}
                                                                onChange={(e) => handleCheckboxChange(g?.GrupCod, e.target.checked)} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }) : (
                                            <tr>
                                                <td colSpan="3" className="text-center">No hay grupos</td>
                                            </tr>
                                        )
                                    }
                                </tbody>

                            </table>
                        </form>
                    </div>
                </div>
                {/* FIN TABLA */}

                <div className='mt-3 botones-accion text-center'>
                    <button className='btn btn-sm btn-primario col-md-2' onClick={handleSubmit(onSubmit)}>Guardar</button>
                    <button className='btn btn-sm btn-secundario col-md-2' onClick={volver}>Cancelar</button>
                </div>


                {
                    mostrar && (
                        <div className="row mt-4">
                            <div className="col-lg-6 mx-auto text-center alert-error">
                                <Stack spacing={2}>
                                    <Alert variant="outlined" severity={tipoAlerta} >{mensaje}</Alert>
                                </Stack>
                            </div>
                        </div>
                    )
                }
            </div>

        </div >

    )
}
