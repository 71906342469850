import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../api/configApi";
import { cambiarColores, encriptar, guardarLocalStorage, obtenerLocalStorage } from "../../resources/funciones";
import { CONST_RUTA_USUARIOS_LISTAR } from "../../constants/constants-rutas-codigos";
import { CONST_KEY_USUARIO_AUTENTICADO, CONST_PARAMETRO_SISTEMA_SEGURIDAD } from "../../constants/constanst-valores";
import { Loader } from "./Loader";
// import { DataContext } from "../../context/DataContext";
import { configColoresCompaniaSUPERADMIN } from "../../constants/config-colores-compania-superadmin";

export const IniciandoSesion = () => {

  // const { getCompaniasYSedesPorUsuario } = useContext(DataContext)

  const navigate = useNavigate();
  const location = useLocation();
  const { token } = location.state;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const onLogin = async () => {
      const dataPOST = {
        token: token,
        parametro: CONST_PARAMETRO_SISTEMA_SEGURIDAD,
      };

      const startTime = Date.now(); // Hora de inicio de la petición

      try {
        const respuesta = await api.post("api/usuario/LoginExterno", dataPOST);

        const estilosCompania = respuesta?.data?.estilo;

        if (respuesta?.status === 201) {
          const status = respuesta?.data?.status;
          const message = respuesta?.data?.message || "Tiene acceso al sistema";
          const data = respuesta?.data || "";
          const dataEncriptado = encriptar(data)

          if (status === 1 && message === "usuario tiene permiso") {
            guardarLocalStorage(dataEncriptado, CONST_KEY_USUARIO_AUTENTICADO);
            // getCompaniasYSedesPorUsuario(data?.token)
            // Guardar los colores al cargar la pagina
            if (estilosCompania) {

              const colores = [
                { name: '--color-fondo-menu-primario', color: estilosCompania?.fondo_menu },
                { name: '--color-texto-nav-item', color: estilosCompania?.text_opciones },
                { name: '--color-fondo-nav-item-activo', color: estilosCompania?.fond_opc_activo },
                { name: '--color-texto-nav-item-activo', color: estilosCompania?.text_opc_activo },
                { name: '--color-button-primario', color: estilosCompania?.boton_primario },
                { name: '--color-button-primario-hover', color: estilosCompania?.boton_primario_activo },
                { name: '--color-button-primario-text', color: estilosCompania?.text_boton_primario },
                { name: '--color-button-secundario', color: estilosCompania?.boton_secundario },
                { name: '--color-button-secundario-hover', color: estilosCompania?.boton_secundario_activo },
                { name: '--color-button-secundario-text', color: estilosCompania?.text_boton_secundario },
              ];

              cambiarColores(colores);
            } else {
              const colores = [
                { name: '--color-fondo-menu-primario', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_FONDO_MENU_PRIMARIO },
                { name: '--color-texto-nav-item', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_TEXTO_NAV_ITEM },
                { name: '--color-fondo-nav-item-activo', color: configColoresCompaniaSUPERADMIN?.CONST_FONDO_NAV_ITEM_ACTIVO },
                { name: '--color-texto-nav-item-activo', color: configColoresCompaniaSUPERADMIN?.CONST_TEXTO_NAV_ITEM_ACTIVO },
                { name: '--color-button-primario', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_PRIMARIO },
                { name: '--color-button-primario-hover', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_PRIMARIO_HOVER },
                { name: '--color-button-primario-text', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_PRIMARIO_TEXT },
                { name: '--color-button-secundario', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_SECUNDARIO },
                { name: '--color-button-secundario-hover', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_SECUNDARIO_HOVER },
                { name: '--color-button-secundario-text', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_SECUNDARIO_TEXT },
              ];
              cambiarColores(colores);
            }
          }
        }
      } catch (error) {
        // Manejar errores de la petición aquí, si es necesario
      } finally {
        const endTime = Date.now(); // Hora de finalización de la petición
        const requestDuration = endTime - startTime; // Duración de la petición en milisegundos

        // Calculamos el tiempo que el Loader debe mostrar, al menos 0.8 segundos
        const minLoaderDuration = 200; // Mínimo de 0.8 segundos
        const loaderDuration = Math.max(minLoaderDuration, requestDuration);

        // Esperar el tiempo calculado antes de ocultar el Loader
        setTimeout(() => {
          setIsLoading(false);
          navigate(CONST_RUTA_USUARIOS_LISTAR);
        }, loaderDuration);
      }
    };

    onLogin();
  }, []);

  return isLoading ? <Loader /> : null;
};





