import { useForm, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FaRegEdit, FaUserAlt } from "react-icons/fa"
import { RiUserSettingsLine } from "react-icons/ri"
import { useContext, useEffect, useState } from "react"
import { DataContext } from "../../../context/DataContext"
import { FcSearch } from 'react-icons/fc';
import { RiDeleteBinFill } from "react-icons/ri"
import { grupoDelete, grupoMostrar, gruposBuscar, gruposPermisosListar, gruposUsuariosPorGrupo } from "../../../api/grupoApi";
import { listaEstadosActivo, listaEstadosActivoBusqueda } from "../../../constants/constants-selectores";
import { IoMdAdd } from "react-icons/io"
import { BiSearch } from "react-icons/bi"

import Swal from 'sweetalert2';
import { obtenerLabel, obtenerLocalStorage } from "../../../resources/funciones";
import { modulosBuscar } from "../../../api/moduloApi";
import { useMobileDetection } from "../../../hook/useMobileDetection";
import { validarAutenticacion } from "../../../resources/funcion-validar-autenticacion";
import { CONST_KEY_USUARIO_AUTENTICADO } from "../../../constants/constanst-valores";
import { CONST_RUTA_GRUPOS_AGREGAR, CONST_RUTA_GRUPOS_EDITAR, CONST_RUTA_GRUPOS_PERMISOS, CONST_RUTA_GRUPOS_USUARIOS, CONST_RUTA_SIN_ACCESO } from "../../../constants/constants-rutas-codigos";
import { rutasCodigosPrograma } from "../../../constants/constanst-arreglo-rutas-codigo";
import { usuarioObtenerCompaniasYSucursales } from "../../../api/usuarioApi";

export const Grupos = () => {

  const usuarioAutenticado = obtenerLocalStorage(CONST_KEY_USUARIO_AUTENTICADO);

  const { listaRutasDeAcceso } = useContext(DataContext)

  const [listaCompanias, setListaCompanias] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  const isMobile = useMobileDetection()

  //Estados para ver si mostrar o no los botones
  const [mostrarBotonAgregar, setMostrarBotonAgregar] = useState(false)
  const [mostrarBotonEditar, setMostrarBotonEditar] = useState(false)
  const [mostrarBotonEliminar, setMostrarBotonEliminar] = useState(false)
  const [mostrarBotonGruposUsuarios, setMostrarBotonGruposUsuarios] = useState(false)
  const [mostrarBotonGruposPermisos, setMostrarBotonGruposPermisos] = useState(false)

  const { register, handleSubmit, watch, formState: { errors }, reset, setValue } = useForm({
    defaultValues: {
      estado: "A"
    }
  });

  const navigate = useNavigate()

  const [listaGrupos, setListaGrupos] = useState([])
  const [buscar, setBuscar] = useState(false)

  const onSubmitBuscar = async (data) => {

    const dataPOST = {
      compania: data?.compania,
      estado: data?.estado,
      token: usuarioAutenticado?.token
    }

    setIsLoading(true)
    const { grupos } = await gruposBuscar(dataPOST)
    setListaGrupos(grupos)
    setBuscar(true)
    setIsLoading(false)
  };


  const grupoAgregar = async () => {
    const rutaNavegar = CONST_RUTA_GRUPOS_AGREGAR
    const modulo = rutasCodigosPrograma.find(r => r.ruta == rutaNavegar)
    const validarAutenticado = await validarAutenticacion(usuarioAutenticado?.token, modulo?.codigo)

    if (validarAutenticado) {
      navigate(rutaNavegar)
    } else {
      navigate(CONST_RUTA_SIN_ACCESO)
    }
  }

  const grupoUsuarios = async (codigo_grupo, compania, nombre_grupo) => {

    const rutaNavegar = CONST_RUTA_GRUPOS_USUARIOS
    const modulo = rutasCodigosPrograma.find(r => r.ruta == rutaNavegar)
    const validarAutenticado = await validarAutenticacion(usuarioAutenticado?.token, modulo?.codigo)

    if (validarAutenticado) {
      const dataPOST = {
        codigo_grupo,
        compania,
        token: usuarioAutenticado?.token
      }

      const { status, usuarios } = await gruposUsuariosPorGrupo(dataPOST)

      if (status == 1) {
        navigate(rutaNavegar, {
          state: {
            usuarios,
            codigo_grupo,
            nombre_grupo,
            compania
          }
        })
      }
    } else {
      navigate(CONST_RUTA_SIN_ACCESO)
    }

  }

  const grupoPermisos = async (compania, grupo, nombreGrupo) => {
    const rutaNavegar = CONST_RUTA_GRUPOS_PERMISOS
    const modulo = rutasCodigosPrograma.find(r => r.ruta == rutaNavegar)
    const validarAutenticado = await validarAutenticacion(usuarioAutenticado?.token, modulo?.codigo)

    if (validarAutenticado) {
      navigate(rutaNavegar, {
        state: {
          // modulos,
          nombreGrupo,
          compania,
          grupo
        }
      })
    } else {
      navigate(CONST_RUTA_SIN_ACCESO)
    }

  }

  const grupoEditar = async (codGrupo, CompCod) => {

    const rutaNavegar = CONST_RUTA_GRUPOS_EDITAR
    const modulo = rutasCodigosPrograma.find(r => r.ruta == rutaNavegar)

    const validarAutenticado = await validarAutenticacion(usuarioAutenticado?.token, modulo?.codigo)

    if (validarAutenticado) {
      const dataPOST = {
        codigo: codGrupo,
        compania: CompCod,
        token: usuarioAutenticado?.token
      }
      const { grupo } = await grupoMostrar(dataPOST)
      navigate(rutaNavegar, {
        state: grupo
      })
    } else {
      navigate(CONST_RUTA_SIN_ACCESO)
    }

  }

  const optionsEliminar = (nombreGrupo) => {
    return {
      title: `¿Estás seguro de eliminar el grupo: ${nombreGrupo}?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#6c757d',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
    }
  }

  const grupoEliminar = (grupo, nombreGrupo) => {
    Swal.fire(optionsEliminar(nombreGrupo)).then(async (result) => {
      if (result.isConfirmed) {

        const dataPOST = {
          codigo: grupo,
          token: usuarioAutenticado?.token
        }

        const { status } = await grupoDelete(dataPOST)
        if (status == 1) {
          grupoBuscarActivos()
          Swal.fire(
            'Grupo eliminado',
            '',
            'success'
          )
        } else {
          Swal.fire(
            'Error al eliminar grupo',
            '',
            'success'
          )
        }
      }
    })

  }

  const grupoBuscarActivos = async () => {

    setIsLoading(true)
    const dataPOST = {
      estado: watch("estado") || "A",
      compania: watch("compania"),
      token: usuarioAutenticado?.token
    }

    const { grupos } = await gruposBuscar(dataPOST)
    setListaGrupos(grupos)
    setIsLoading(false)
    setBuscar(true)

  }

  const obtenerCompaniasYSucursales = async () => {

    const dataPOST = {
      token: usuarioAutenticado?.token
    }

    const { companias, sucursales } = await usuarioObtenerCompaniasYSucursales(dataPOST)
    setListaCompanias(companias)
    setValue("compania", companias?.[0]?.id)

    return {
      companias,
      sucursales
    }
  }

  useEffect(() => {
    // obtenerModulos()

    const encontradoRutaBotonAgregar = listaRutasDeAcceso?.find(r => r.codigo_programa == "S0102010000")
    const encontradoRutaBotonEditar = listaRutasDeAcceso?.find(r => r.codigo_programa == "S0102020000")
    const encontradoRutaBotonEliminar = listaRutasDeAcceso?.find(r => r.codigo_programa == "S0102030000")
    const encontradoRutaBotonGruposUsuarios = listaRutasDeAcceso?.find(r => r.codigo_programa == "S0102040000")
    const encontradoRutaBotonGruposPermisos = listaRutasDeAcceso?.find(r => r.codigo_programa == "S0102050000")

    encontradoRutaBotonAgregar ? setMostrarBotonAgregar(true) : setMostrarBotonAgregar(false)
    encontradoRutaBotonEditar ? setMostrarBotonEditar(true) : setMostrarBotonEditar(false)
    encontradoRutaBotonEliminar ? setMostrarBotonEliminar(true) : setMostrarBotonEliminar(false)
    encontradoRutaBotonGruposUsuarios ? setMostrarBotonGruposUsuarios(true) : setMostrarBotonGruposUsuarios(false)
    encontradoRutaBotonGruposPermisos ? setMostrarBotonGruposPermisos(true) : setMostrarBotonGruposPermisos(false)

  }, [listaRutasDeAcceso])

  useEffect(() => {

    const cargarDatosIniciales = async () => {
      await obtenerCompaniasYSucursales()
      await grupoBuscarActivos()
    };

    cargarDatosIniciales();
  }, [])

  return (
    <div className="p-3">
      <div className="container-fluid">
        <h3 className="text-center mt-1">Grupos</h3>
        {/* Formulario */}
        <div className="my-3 border p-1 rounded-5 bg-form">
          <form onSubmit={handleSubmit(onSubmitBuscar)} className="row p-1">
            <div className="col-12 col-lg-7">
              <label className="text-start">Compañía</label>
              <select 
                {...register("compania", {
                  required: true
                })} 
                className="form-select form-select-sm" 
                value={watch("compania")}
              >
                {
                  listaCompanias?.length > 0 && listaCompanias.map(e => (
                    <option key={e?.id} value={e?.id}>{e?.tipo}</option>
                  ))
                }
              </select>
            </div>
            <div className="col-12 col-lg-4">
              <label className="text-start">Estado</label>
              <select {...register("estado")} className="form-select form-select-sm" >
                {
                  listaEstadosActivoBusqueda?.length > 0 && listaEstadosActivoBusqueda.map(e => (
                    <option key={e?.id} value={e?.id}>{e?.tipo}</option>
                  ))
                }
              </select>
            </div>
            <div className="col-12 col-lg-1">
              <label className="text-start">&nbsp;</label>
              <div className={isMobile ? "text-center" : ""}>
                <button type="submit" onClick={handleSubmit(onSubmitBuscar)} style={{ cursor: 'pointer', background: 'none', border: 'none' }}>
                  <BiSearch size={25} className="color-iconos" />
                </button>
              </div>


            </div>
          </form>
        </div>
        {/* Fin formulario */}

        {
          mostrarBotonAgregar && (
            <div className="mx-auto ps-0">
              <button className='btn btn-primario btn-sm align-middle d-flex align-items-center' onClick={grupoAgregar}>
                <IoMdAdd size={18} />
                <span className="ms-1">Agregar</span>
              </button>
            </div>
          )
        }

        {/* Tabla de resultados */}
        <div className="col-12 table-responsive mt-2">
          <table className="table table-hover mt-2">
            <thead className="table-light">
              <tr>
                <th scope="col" className="col text-center">Código</th>
                <th scope="col" className="col text-center">Grupo</th>
                {/* <th scope="col" className="col text-center">App</th> */}
                <th scope="col" className="col text-center">Estado</th>
                {
                  mostrarBotonGruposUsuarios && (
                    <th scope="col" className="col text-center">Usuarios</th>
                  )
                }
                {
                  mostrarBotonGruposPermisos && (
                    <th scope="col" className="col text-center">Permisos</th>
                  )
                }
                {
                  mostrarBotonEditar && (
                    <th scope="col" className="col text-center">Editar</th>
                  )
                }
                {
                  mostrarBotonEliminar && (
                    <th scope="col" className="col text-center">Eliminar</th>
                  )
                }

              </tr>
            </thead>
            <tbody>
              {
                isLoading ? (
                  <tr>
                    <td colSpan="100" className="text-center">

                      <div className="spinner-border text-secondary spinner-border-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </td>
                  </tr>

                ) : (
                  listaGrupos?.length > 0 ? listaGrupos.map(m => (
                    <tr key={m?.GrupCod}>
                      <td>{m?.GrupCod}</td>
                      <td>{m?.GrupNom}</td>
                      {/* <td>{m?.modu == "" ? "NINGUNO" : m?.modu}</td> */}
                      <td>{obtenerLabel(listaEstadosActivo, m?.GrupEst)}</td>

                      {
                        mostrarBotonGruposUsuarios && (
                          <td><FaUserAlt size={23} cursor={'pointer'} className="color-iconos" onClick={() => grupoUsuarios(m?.GrupCod, m?.GrupComp, m?.GrupNom)} /></td>
                        )
                      }
                      {
                        mostrarBotonGruposPermisos && (
                          <td><RiUserSettingsLine size={23} cursor={'pointer'} className="color-iconos" onClick={() => grupoPermisos(m?.GrupComp, m?.GrupCod, m?.GrupNom)} /></td>
                        )
                      }
                      {
                        mostrarBotonEditar && (
                          <td><FaRegEdit size={23} cursor={'pointer'} className="color-iconos" onClick={() => grupoEditar(m?.GrupCod, m?.GrupComp)} /></td>
                        )
                      }
                      {
                        mostrarBotonEliminar && (

                          <td><RiDeleteBinFill size={23} cursor={'pointer'} color="#C0392B" onClick={() => grupoEliminar(m?.GrupCod, m?.GrupNom)} /></td>
                        )
                      }
                    </tr>
                  )) :
                    (
                      (buscar) ? (
                        <tr>
                          <td colSpan="100" className="text-center">No hay grupos</td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan="100" className="text-center">Realice una búsqueda</td>
                        </tr>
                      )
                    )
                )
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
