import { verificarUsuario } from "../api/authApi"

export const validarAutenticacion = async (token, codigo) => {

    const dataPOST = {
      token: token,
      codigo: codigo
    }

    const { autenticado } = await verificarUsuario(dataPOST)
    return autenticado

  }