import { api } from "./configApi"

export const sedeCrear = async (dataPOST) => {
    try {

        const res = await api.post("api/sede/AgregarSede", dataPOST)
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
                idSede: res.data.idSede,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                message: error.response.data.message,
                idSede: ""
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
            idSede: ""
        };
    }
}

export const sedesBuscar = async (dataPOST) => {

    try {
        
        const res = await api.post("api/sede/BuscarSede", dataPOST)
      
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                sedes: res.data.resultado,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                sedes: [],
                message: error.response.data.message,
            };
        }
        return {
            status: -1,
            sedes: [],
            message: "Error en el servidor",
        }
    }
}

export const sedeDelete = async (dataPOST) => {
    try {
        
        const res = await api.post("api/sede/EliminarSede", dataPOST)
       
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
            }
        }
    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        }
    }
}

export const sedeMostrar = async (dataPOST) => {
    try {

        const res = await api.post("api/sede/MostrarSede", dataPOST)
       
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                sede: res.data.resultado,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                sede: null,
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            sede: null,
            message: "Error en el servidor",
        }
    }
}

export const sedeEditar = async (dataPOST) => {
   
    try {

        const res = await api.post("api/sede/ActualizarSede", dataPOST)
        
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: 0,
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        }
    }
}

// Esta api funciona en usuario master
export const sedesPorCompania = async (dataPOST) => {
   
    try {

        const res = await api.post("api/sede/ListaSede", dataPOST)

        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                sedes: res.data.resultado,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: 0,
                sedes: [],
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            sedes: [],
            message: "Error en el servidor",
        }
    }
}