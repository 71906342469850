import CryptoJS from "crypto-js"
import { keyCryptoJS } from "../config/keyCrypto";

export const obtenerLabel = (lista, value) => {

  if (value !== '') {
    const label = lista?.find(d => d.id == value)?.tipo || ''
    return label
  }
}

// // Se puede guardar tanto texto como objeto
export const guardarLocalStorage = (obj, key) => {
  //Encriptacion
  // const encriptado = encriptar(obj)
  sessionStorage.setItem(key, JSON.stringify(obj));
}

export const obtenerLocalStorage = (key) => {

  const datosLSStr = sessionStorage.getItem(key);
  const datosLSObj = JSON.parse(datosLSStr);

  //Desencriptacion
  const desencriptado = desencriptar(datosLSObj)
  return desencriptado;
}

// export const guardarLocalStorage = (obj, key) => {
//   sessionStorage.setItem(key, JSON.stringify(obj));
// };

// export const obtenerLocalStorage = (key) => {
//   const datosLSStr = sessionStorage.getItem(key);
//   const datosLSObj = JSON.parse(datosLSStr);
//   return datosLSObj;
// };

export const limpiarLocalStorage = (key) => {
  sessionStorage.removeItem(key);
}

export const obtenerNombreEImagenBase64 = (file) => {

  return new Promise((resolve) => {
    const nombreImagen = file?.name || "";
    const type = file?.type || ""

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64Value = e.target.result;

        // Optimizar la imagen antes de convertirla en base64
        optimizarImagenBase64(base64Value, type)
          .then((imagenOptimizadaBase64) => {
            resolve({
              nombreImagen: nombreImagen,
              imagenBase64: imagenOptimizadaBase64.split(',')[1]
            });
          })
          .catch((error) => {
            console.error("Error al optimizar la imagen:", error);
            resolve({
              nombreImagen: nombreImagen,
              imagenBase64: base64Value, // En caso de error, devolver la imagen original
            });
          });
      };

      reader.readAsDataURL(file);
    } else {
      resolve({
        nombreImagen: nombreImagen,
        imagenBase64: null,
      });
    }
  });
};

// Función para optimizar una imagen en formato base64 utilizando canvas
const optimizarImagenBase64 = async (base64Data, imageType) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = base64Data;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Define el tamaño deseado para la imagen optimizada
      const maxWidth = 300; // Puedes ajustar esto según tus necesidades 300

      let newWidth = img.width;
      let newHeight = img.height;

      if (img.width > maxWidth) {
        newWidth = maxWidth;
        newHeight = (img.height * maxWidth) / img.width;
      }

      canvas.width = newWidth;
      canvas.height = newHeight;

      // Dibuja la imagen optimizada en el lienzo
      ctx.drawImage(img, 0, 0, newWidth, newHeight);

      // Convierte el lienzo a base64
      const optimizadaBase64 = canvas.toDataURL(`${imageType}`, 0.7); // Puedes ajustar la calidad

      resolve(optimizadaBase64);
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
};

// Obtener el elemento :root del documento
const root = document.documentElement;

export const cambiarColores = (variables) => {
  variables.forEach((variable) => {
    const { name, color } = variable;
    root.style.setProperty(name, color);
  });
}

export const handleImagenSeleccionada = (e, setLogoBase64) => {

  const archivo = e?.target?.files?.[0];
  if (archivo) {

    const reader = new FileReader();

    reader.onload = (e) => {
      const imagenEnBase64 = e.target.result;
      setLogoBase64(imagenEnBase64);
    };

    reader.readAsDataURL(archivo);

  } else {
    setLogoBase64(null)
  }
};

export const formatearFecha = (fecha) => {
  const dia = fecha.getDate().toString().padStart(2, '0');
  const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Los meses comienzan desde 0
  const año = fecha.getFullYear();

  return `${dia}/${mes}/${año}`;
}


export const encriptar = (objeto) => {
  try {
    // Serializa el objeto a JSON antes de encriptarlo
    const objetoSerializado = JSON.stringify(objeto);
    const textoCifrado = CryptoJS.AES.encrypt(objetoSerializado, keyCryptoJS).toString();
    return textoCifrado;
  } catch (error) {
    return null;
  }
};

export const desencriptar = (objeto) => {
  try {

    const bytes = CryptoJS.AES.decrypt(objeto, keyCryptoJS);
    const objetoSerializado = bytes.toString(CryptoJS.enc.Utf8);

    // Deserializa el JSON de nuevo a un objeto
    const objetoDeserializado = JSON.parse(objetoSerializado);
    return objetoDeserializado;
  } catch (error) {
    return null;
  }
};
