import { desencriptar } from "../resources/funciones"
import { api } from "./configApi"

export const authLogin = async (dataPOST) => {
    try {
        const res = await api.post("api/usuario/Login", dataPOST)

        // res.status 201
        if (res.status === 201) {
            //Desencriptar el res
            const resDesencriptado = desencriptar(res.data.body)

            return {
                status: resDesencriptado.status,
                usuario: resDesencriptado,
                message: resDesencriptado.message,
                resEncriptado: res.data.body
            }
        }

        if (res.status === 200) {

            return {
                status: 0,
                usuario: null,
                message: res.data.message,
                resEncriptado: null
            };
        }

    } catch (error) {

        return {
            status: -1,
            usuario: null,
            message: "Error en el servidor",
            resEncriptado: null
        };
    }
}

export const verificarUsuario = async (dataPOST) => {
    try {

        const res = await api.post("api/usuario/VerificarUseMod", dataPOST)

        // res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                autenticado: res.data.valor,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                autenticado: false,
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            autenticado: false,
            message: "Error en el servidor",
        };
    }
}

export const navegarOtraApp = async (dataPOST) => {
    try {

        const res = await api.post("api/usuario/OtrosSistemas", dataPOST)

        if (res.data.status === 1) {
            return {
                status: res.data.status,
                apps: res.data.rutas,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                apps: [],
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            apps: [],
            message: "Error en el servidor",
        };
    }
}