import { useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useMobileDetection } from "../../../hook/useMobileDetection"
import { authLogin } from "../../../api/authApi"
import { CONST_KEY_USUARIO_AUTENTICADO, CONST_PARAMETRO_SISTEMA_SEGURIDAD } from "../../../constants/constanst-valores"

import { TextField } from "@mui/material"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import Alert from '@mui/material/Alert';
import { cambiarColores, encriptar, guardarLocalStorage, limpiarLocalStorage, obtenerLocalStorage } from "../../../resources/funciones"
import { configColoresCompaniaSUPERADMIN } from "../../../constants/config-colores-compania-superadmin"

import { CONST_RUTA_USUARIOS_LISTAR } from "../../../constants/constants-rutas-codigos"

export const Login = () => {

    const imagen = "/img/logo-seguridad.png"
    const logoUser = "/img/logo-user.png"

    const [mensaje, setMensaje] = useState('')
    const [mostrarError, setMostrarError] = useState(false)

      // Obtener los valores de las variables CSS cuando el componente se monta
  const computedStyles = getComputedStyle(document.documentElement);

    const isMobile = useMobileDetection()

    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm({
        defaultValues: {
            user: "",
            password: "",
        }
    });

    const navigate = useNavigate()

    const onLogin = async (data) => {

        const dataPOST = {
            user: data?.user,
            password: data?.password,
            parametro: CONST_PARAMETRO_SISTEMA_SEGURIDAD
        }

        const dataPOSTEncriptado = {
            body: encriptar(dataPOST)
        }

        const { resEncriptado, usuario, status, message } = await authLogin(dataPOSTEncriptado)

        setMensaje(message)

        if (status == 1) {
         
            guardarLocalStorage(resEncriptado, CONST_KEY_USUARIO_AUTENTICADO)
            //Obtener los estilos de la pagina de cada compania
            const estilosCompania = usuario?.estilo;

            // Guardar los colores al cargar la pagina
            if (estilosCompania) {
               
                const colores = [
                    { name: '--color-fondo-menu-primario', color: estilosCompania?.fondo_menu },
                    { name: '--color-texto-nav-item', color: estilosCompania?.text_opciones },
                    { name: '--color-fondo-nav-item-activo', color: estilosCompania?.fond_opc_activo },
                    { name: '--color-texto-nav-item-activo', color: estilosCompania?.text_opc_activo },
                    { name: '--color-button-primario', color: estilosCompania?.boton_primario },
                    { name: '--color-button-primario-hover', color: estilosCompania?.boton_primario_activo },
                    { name: '--color-button-primario-text', color: estilosCompania?.text_boton_primario },
                    { name: '--color-button-secundario', color: estilosCompania?.boton_secundario },
                    { name: '--color-button-secundario-hover', color: estilosCompania?.boton_secundario_activo },
                    { name: '--color-button-secundario-text', color: estilosCompania?.text_boton_secundario },
                ];

                cambiarColores(colores);
            }else{
                const colores = [
                    { name: '--color-fondo-menu-primario', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_FONDO_MENU_PRIMARIO },
                    { name: '--color-texto-nav-item', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_TEXTO_NAV_ITEM },
                    { name: '--color-fondo-nav-item-activo', color: configColoresCompaniaSUPERADMIN?.CONST_FONDO_NAV_ITEM_ACTIVO },
                    { name: '--color-texto-nav-item-activo', color: configColoresCompaniaSUPERADMIN?.CONST_TEXTO_NAV_ITEM_ACTIVO },
                    { name: '--color-button-primario', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_PRIMARIO },
                    { name: '--color-button-primario-hover', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_PRIMARIO_HOVER },
                    { name: '--color-button-primario-text', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_PRIMARIO_TEXT },
                    { name: '--color-button-secundario', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_SECUNDARIO },
                    { name: '--color-button-secundario-hover', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_SECUNDARIO_HOVER },
                    { name: '--color-button-secundario-text', color: configColoresCompaniaSUPERADMIN?.CONST_COLOR_BUTTON_SECUNDARIO_TEXT},
                ];
                cambiarColores(colores);
            }
            navigate(CONST_RUTA_USUARIOS_LISTAR)
        } else {
            
            setMostrarError(true)
        }
    }

    useEffect(() => {
        limpiarLocalStorage(CONST_KEY_USUARIO_AUTENTICADO)
    }, [])


    return (
        <div className="bg-menu-primario" style={{ height: "100vh" }} >
            <div className="h-100 d-flex align-items-center">
                <div className="col-md-5 px-5">
                    <div className="content-left d-flex justify-content-center align-items-center my-4  border border-2" style={{ height: "450px", background: "white", borderRadius: "10px" }}>
                        <div className={isMobile ? "p-2" : "p-5"}>
                            <h3 className="text-center mb-2 text-color-primario">Sistema de Seguridad</h3>
                            <div className="text-center">
                                <img src={logoUser} alt="logo-user" />
                            </div>

                            <form onSubmit={handleSubmit(onLogin)} className="mt-3">
                                <TextField label='Usuario' type='text' {...register("user")} placeholder='Usuario' fullWidth className="my-2 input-login" inputProps={{ style: { textTransform: "none" } }} />
                                <TextField label='Contraseña' type='password' {...register("password")} placeholder='Contraseña' fullWidth className="my-2" inputProps={{ style: { textTransform: "none" } }} />
                                <Button variant='contained' fullWidth sx={{
                                    background: computedStyles?.getPropertyValue('--color-button-primario'), '&:hover': {
                                        background: computedStyles?.getPropertyValue('--color-button-primario-hover')
                                    }
                                }} type="submit" className="mt-3 mb-2 btn-primario">
                                    Iniciar Sesión
                                </Button>
                                {
                                    mostrarError && (
                                        <Grid item xs={12}>
                                            <Alert variant="outlined" severity="warning">{mensaje}</Alert>
                                        </Grid>
                                    )
                                }
                            </form>
                        </div>

                    </div>

                </div>
                {
                    !isMobile && (
                        <div className="col-md-7 p-0 content-right">
                            <img src={imagen} alt="imagen" className="img-thumbnail" style={{ width: "100%", maxHeight: "100vh" }} />
                        </div>
                    )
                }
            </div>
        </div>
    );
};


