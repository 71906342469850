import { api } from "./configApi"

export const parametroCrear = async (dataPOST) => {
    try {

        const res = await api.post("api/parametro/AgregarParametro", dataPOST)
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        };
    }
}

export const parametrosBuscar = async (dataPOST) => {
    try {
       
        const res = await api.post("api/parametro/BuscarParametro", dataPOST)
      
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                parametros: res.data.resultado,
                message: res.data.message,
            }
        }

    } catch (error) {
        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                parametros: [],
                message: error.response.data.message,
            };
        }
        return {
            status: -1,
            parametros: [],
            message: "Error en el servidor",
        }
    }
}

export const parametroDelete = async (dataPOST) => {
    try {

        const res = await api.post("api/parametro/EliminarParametro", dataPOST)
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
            }
        }
    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        }
    }
}

export const parametroMostrar = async (dataPOST) => {
    try {
        
        const res = await api.post("api/parametro/MostrarParametro", dataPOST)
        
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                parametro: res.data.resultado,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                parametro: null,
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            usuario: null,
            message: "Error en el servidor",
        }
    }
}

export const parametroEditar = async (dataPOST) => {
    try {

        const res = await api.post("api/parametro/ActualizarParametro", dataPOST)
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
        }
    }
}