import { api } from "./configApi";

export const dataSelector = async () => {
    try {
        const res = await api.get("api/datofijo")

        //res.status 201
        if (res.data.status == 1) {
            return {
                status: res.data.status,
                companias: res.data.compania,
                message: res.data.message,
                tiposIdentificacion: res.data.identificacion,
                ubigeos: res.data.ubigeo
            }
        }

        //res.status 401
        if (res.data.status == 0) {
            return {
                status: res.data.status,
                companias: "",
                message: res.data.message,
                tiposIdentificacion: [],
                ubigeos: []
            }
        }
    } catch (error) {
        return {
            status: -1,
            companias: "",
            message: "Error en el servidor",
            tiposIdentificacion: [],
            ubigeos: []
        }
    }
}