import { useNavigate } from "react-router-dom";

//Iconos
import { FcSearch } from 'react-icons/fc';
import { FaRegEdit } from 'react-icons/fa'
import { listaEstadosActivo, listaEstadosActivoBusqueda } from "../../../constants/constants-selectores";
import { RiDeleteBinFill } from 'react-icons/ri'

import Swal from 'sweetalert2';
import { companiasBuscar, companiaDelete, companiaMostrar } from "../../../api/companiaApi";
import { useContext, useEffect, useState } from "react";
import { obtenerLabel, obtenerLocalStorage } from "../../../resources/funciones";
import { DataContext } from "../../../context/DataContext";

import { IoMdAdd } from "react-icons/io"
import { BiSearch } from "react-icons/bi"
import { useMobileDetection } from "../../../hook/useMobileDetection";
import { CONST_KEY_USUARIO_AUTENTICADO, CONST_ROL_MASTER } from "../../../constants/constanst-valores";
import { validarAutenticacion } from "../../../resources/funcion-validar-autenticacion";
import { CONST_CODIGO_SUCURSALES_AGREGAR, CONST_CODIGO_SUCURSALES_EDITAR, CONST_CODIGO_SUCURSALES_ELIMINAR, CONST_RUTA_COMPANIAS_AGREGAR, CONST_RUTA_COMPANIAS_EDITAR, CONST_RUTA_SIN_ACCESO, CONST_RUTA_SUCURSALES_AGREGAR, CONST_RUTA_SUCURSALES_EDITAR } from "../../../constants/constants-rutas-codigos";
import { rutasCodigosPrograma } from "../../../constants/constanst-arreglo-rutas-codigo";

import { useForm } from 'react-hook-form';
import { sucursalesBuscar } from "../../../api/sucursalApi";
import { usuarioObtenerCompaniasYSucursales } from "../../../api/usuarioApi";

export const Sucursales = () => {

    const usuarioAutenticado = obtenerLocalStorage(CONST_KEY_USUARIO_AUTENTICADO);
    const rol = usuarioAutenticado?.tipo

    const { listaRutasDeAcceso } = useContext(DataContext)

    const [listaCompanias, setListaCompanias] = useState([])
    const [listaSucursales, setListaSucursales] = useState([])
    const isMobile = useMobileDetection()
    const [isLoading, setIsLoading] = useState(false)

    //Estados para ver si mostrar o no los botones
    const [mostrarBotonAgregar, setMostrarBotonAgregar] = useState(false)
    const [mostrarBotonEditar, setMostrarBotonEditar] = useState(false)
    const [mostrarBotonEliminar, setMostrarBotonEliminar] = useState(false)

    const { register, handleSubmit, watch, formState: { errors }, reset, setValue } = useForm({
        defaultValues: {
            Estado: "A",
        }
    });

    const [buscar, setBuscar] = useState(false)

    const onSubmitBuscar = async (data) => {
        setIsLoading(true)

        const dataPOST = {
            Estado: data?.Estado,
            Codigo: data?.compania,
            token: usuarioAutenticado?.token
        }

        const { companias } = await sucursalesBuscar(dataPOST)

        setListaSucursales(companias)
        setBuscar(true)
        setIsLoading(false)
    }

    const navigate = useNavigate()

    const companiaAgregar = async () => {

        const validarAutenticado = await validarAutenticacion(usuarioAutenticado?.token, CONST_CODIGO_SUCURSALES_AGREGAR)

        if (validarAutenticado) {
            navigate(CONST_RUTA_SUCURSALES_AGREGAR)
        } else {
            navigate(CONST_RUTA_SIN_ACCESO)
        }

    }

    const companiaEditar = async (CodCompania) => {

        const validarAutenticado = await validarAutenticacion(usuarioAutenticado?.token, CONST_CODIGO_SUCURSALES_EDITAR)

        if (validarAutenticado) {

            const dataPOST = {
                compania: CodCompania,
                token: usuarioAutenticado?.token
            }

            const { compania } = await companiaMostrar(dataPOST)

            navigate(CONST_RUTA_SUCURSALES_EDITAR, {
                state: compania
            })

        } else {
            navigate(CONST_RUTA_SIN_ACCESO)
        }
    }

    const optionsEliminar = (nombreCompania) => {
        return {
            title: `¿Estás seguro de eliminar la compañía: ${nombreCompania}?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#d33',
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar',
        }
    }

    const companiaEliminar = (compania, nombreCompania) => {

        Swal.fire(optionsEliminar(nombreCompania)).then(async (result) => {
            if (result.isConfirmed) {

                const dataPOST = {
                    compania,
                    token: usuarioAutenticado?.token
                }
                const { status } = await companiaDelete(dataPOST)
                if (status == 1) {
                    sucursalesBuscarActivos()
                    // getDataSelector()
                    Swal.fire(
                        'Compañía eliminado',
                        '',
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Error al eliminar compañía',
                        '',
                        'success'
                    )
                }

            }
        })

    }

    const sucursalesBuscarActivos = async (sucursales) => {

        if (rol == CONST_ROL_MASTER || sucursales?.length > 1) {

            setIsLoading(true)

            const dataPOST = {
                Estado: watch("Estado"),
                Codigo: watch("compania"),
                token: usuarioAutenticado?.token
            }

            const { companias } = await sucursalesBuscar(dataPOST)
            setListaSucursales(companias)
            setIsLoading(false)
            setBuscar(true)


        } else {

            if (sucursales?.length == 1) {
                setIsLoading(true)
                const dataPOST = {
                    compania: sucursales?.[0]?.id,
                    token: usuarioAutenticado?.token
                }

                const { compania } = await companiaMostrar(dataPOST)

                setListaSucursales([compania])
                setIsLoading(false)
                setBuscar(true)
            }

        }

    }

    const obtenerCompaniasYSucursales = async () => {

        const dataPOST = {
            token: usuarioAutenticado?.token
        }

        const { companias, sucursales } = await usuarioObtenerCompaniasYSucursales(dataPOST)
        setListaCompanias(companias)
        setValue("compania", companias?.[0]?.id)

        return {
            companias,
            sucursales
        }
    }

    useEffect(() => {

        const encontradoRutaBotonAgregar = listaRutasDeAcceso?.find(r => r.codigo_programa == CONST_CODIGO_SUCURSALES_AGREGAR)
        const encontradoRutaBotonEditar = listaRutasDeAcceso?.find(r => r.codigo_programa == CONST_CODIGO_SUCURSALES_EDITAR)
        const encontradoRutaBotonEliminar = listaRutasDeAcceso?.find(r => r.codigo_programa == CONST_CODIGO_SUCURSALES_ELIMINAR)

        encontradoRutaBotonAgregar ? setMostrarBotonAgregar(true) : setMostrarBotonAgregar(false)
        encontradoRutaBotonEditar ? setMostrarBotonEditar(true) : setMostrarBotonEditar(false)
        encontradoRutaBotonEliminar ? setMostrarBotonEliminar(true) : setMostrarBotonEliminar(false)

    }, [listaRutasDeAcceso])

    useEffect(() => {

        const cargarDatosIniciales = async () => {
            const { sucursales } = await obtenerCompaniasYSucursales()
            await sucursalesBuscarActivos(sucursales)
        };

        cargarDatosIniciales();
    }, [])

    return (
        <div className="p-3">
            <div className="container-fluid">
                <h3 className="text-center mt-1">Sucursal</h3>

                {/* Formulario */}
                {
                    rol == CONST_ROL_MASTER && (
                        <div className="my-3 border p-1 rounded-5">
                            <form onSubmit={handleSubmit(onSubmitBuscar)} className="row p-1">

                                <div className="col-12 col-lg-7">
                                    <label className="text-start">Compañía</label>
                                    <select
                                        {...register("compania", {
                                            required: true
                                        })} className="form-select form-select-sm"
                                        value={watch("compania")}
                                    >
                                        {
                                            listaCompanias?.length > 0 && listaCompanias.map(e => (
                                                <option key={e?.id} value={e?.id}>{e?.tipo}</option>
                                            ))
                                        }
                                    </select>
                                </div>

                                <div className="col-12 col-lg-4">
                                    <label className="text-start">Estado</label>
                                    <select {...register("Estado")} className="form-select form-select-sm" >
                                        {
                                            listaEstadosActivoBusqueda?.length > 0 && listaEstadosActivoBusqueda.map(e => (
                                                <option key={e?.id} value={e?.id}>{e?.tipo}</option>
                                            ))
                                        }
                                    </select>
                                </div>

                                <div className="col-12 col-lg-1">
                                    <label className="text-start">&nbsp;</label>
                                    <div className={isMobile ? "text-center" : ""}>
                                        <button type="submit" onClick={handleSubmit(onSubmitBuscar)} style={{ cursor: 'pointer', background: 'none', border: 'none' }}>
                                            <BiSearch size={25} className="color-iconos" />
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div >
                    )
                }

                {/* Fin formulario */}

                {
                    mostrarBotonAgregar && (
                        <div className="mx-auto ps-0">
                            <button className='btn btn-primario btn-sm align-middle d-flex align-items-center' onClick={companiaAgregar}>
                                <IoMdAdd size={18} />
                                <span className="ms-1">Agregar</span>
                            </button>
                        </div>

                    )
                }


                {/* Tabla de resultados */}
                <div className="col-12 table-responsive mt-2">
                    <table className="table table-hover mt-2 bg-form">
                        <thead className="table-light">
                            <tr>
                                <th scope="col" className="col text-center">Código</th>
                                <th scope="col" className="col text-center">RUC</th>
                                <th scope="col" className="col text-center">Sucursal</th>
                                <th scope="col" className="col text-center">Razon social</th>
                                <th scope="col" className="col text-center">Estado</th>
                                {
                                    mostrarBotonEditar && (
                                        <th scope="col" className="col text-center">Editar</th>
                                    )
                                }
                                {
                                    mostrarBotonEliminar && (
                                        <th scope="col" className="col text-center">Eliminar</th>
                                    )
                                }
                            </tr>
                        </thead>
                        <tbody>

                            {
                                isLoading ? (
                                    <tr>
                                        <td colSpan="100" className="text-center">

                                            <div className="spinner-border text-secondary spinner-border-sm" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </td>
                                    </tr>

                                ) : (
                                    listaSucursales?.length > 0 ? listaSucursales.map(c => (
                                        <tr key={c?.CompCod}>
                                            <td>{c?.CompCod}</td>
                                            <td>{c?.CompRuc}</td>
                                            <td>{c?.CompNom}</td>
                                            <td>{c?.CompRSoc}</td>
                                            <td>{obtenerLabel(listaEstadosActivo, c?.CompEst)}</td>
                                            {
                                                mostrarBotonEditar && (
                                                    <td><FaRegEdit size={23} cursor={'pointer'} className="color-iconos" onClick={() => companiaEditar(c?.CompCod)} /></td>
                                                )
                                            }
                                            {
                                                mostrarBotonEliminar && (
                                                    <td><RiDeleteBinFill size={23} cursor={'pointer'} color="#C0392B" onClick={() => companiaEliminar(c?.CompCod, c?.CompNom)} /></td>
                                                )
                                            }

                                        </tr>
                                    )) :
                                        (
                                            (buscar) ? (
                                                <tr>
                                                    <td colSpan="100" className="text-center">No hay sucursales</td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td colSpan="100" className="text-center">Realice una búsqueda</td>
                                                </tr>
                                            )
                                        )
                                )
                            }
                        </tbody>
                    </table>
                </div>
                {/* Fin Tabla de resultados */}

            </div>
        </div>
    )
}
