export const CONST_DNI = "D"

export const CONST_PARAMETRO_SISTEMA_SEGURIDAD = "SS001"

export const CONST_KEY_USUARIO_AUTENTICADO = "usuario"


//Constantes de tipo de modulos

export const CONST_TIPO_MODULO_MENU_OPCION= "MO"
export const CONST_TIPO_MODULO_MENU_WPANEL_TRANSACCION = "MWT"


//Constante de roles
export const CONST_ROL_MASTER = "M"