import { api } from "./configApi"

export const sucursalesBuscar = async (dataPOST) => {
    try {

        const res = await api.post("api/compania/ListarCompaniaSucursalSinPrincipal", dataPOST)
        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                message: res.data.message,
                companias: res.data.resultado,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: error.response.data.status,
                message: error.response.data.message,
                companias: []
            };
        }

        return {
            status: -1,
            message: "Error en el servidor",
            companias: []
        };
    }
}

export const sucursalesPorCompania = async (dataPOST) => {
   
    try {

        const res = await api.post("api/compania/ListarCompaniaSucursal", dataPOST)

        //res.status 201
        if (res.data.status === 1) {
            return {
                status: res.data.status,
                sucursales: res.data.resultado,
                message: res.data.message,
            }
        }

    } catch (error) {

        if (error.response && error.response.data.status == 0) {
            return {
                status: 0,
                sucursales: [],
                message: error.response.data.message,
            };
        }

        return {
            status: -1,
            sucursales: [],
            message: "Error en el servidor",
        }
    }
}
