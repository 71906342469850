// import axios from 'axios'

// const URLdomain = window.location.host
// const protocol = window.location.protocol

// const config = protocol + "//" + URLdomain + '/config/config.json'

// const serverUrl = await axios.get(config)

export const server = {
    // "serverUrl": serverUrl.data.server_URL, //Server local
    // "serverUrl": "http://192.168.195.54:4001/"
    "serverUrl": "https://seguridad.madrisqui.com/"
}

    
