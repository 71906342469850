
export const Loader = () => {
    return (
        <div className="d-flex justify-content-center align-items-center h-100" style={{background: "rgba(0, 0, 0, 0.1)"}}>
            <div className="loader">
                <div className="loader-inner">
                    <div className="loader-block"></div>
                    <div className="loader-block"></div>
                    <div className="loader-block"></div>
                    <div className="loader-block"></div>
                    <div className="loader-block"></div>
                    <div className="loader-block"></div>
                    <div className="loader-block"></div>
                    <div className="loader-block"></div>
                </div>
            </div>
        </div>

    )
}
