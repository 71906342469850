import { CONST_CODIGO_COMPANIAS_AGREGAR, CONST_CODIGO_COMPANIAS_EDITAR, CONST_CODIGO_COMPANIAS_ELIMINAR, CONST_CODIGO_COMPANIAS_LISTAR, CONST_CODIGO_GRUPOS_AGREGAR, CONST_CODIGO_GRUPOS_EDITAR, CONST_CODIGO_GRUPOS_ELIMINAR, CONST_CODIGO_GRUPOS_LISTAR, CONST_CODIGO_GRUPOS_PERMISOS, CONST_CODIGO_GRUPOS_USUARIOS, CONST_CODIGO_GRUPOS_USUARIOS_AGREGAR, CONST_CODIGO_MODULOS_AGREGAR, CONST_CODIGO_MODULOS_EDITAR, CONST_CODIGO_MODULOS_ELIMINAR, CONST_CODIGO_MODULOS_LISTAR, CONST_CODIGO_PARAMETROS_AGREGAR, CONST_CODIGO_PARAMETROS_EDITAR, CONST_CODIGO_PARAMETROS_ELIMINAR, CONST_CODIGO_PARAMETROS_LISTAR, CONST_CODIGO_SEDES_AGREGAR, CONST_CODIGO_SEDES_EDITAR, CONST_CODIGO_SEDES_ELIMINAR, CONST_CODIGO_SEDES_LISTAR, CONST_CODIGO_SEGURIDAD, CONST_CODIGO_SUCURSALES_AGREGAR, CONST_CODIGO_SUCURSALES_EDITAR, CONST_CODIGO_SUCURSALES_ELIMINAR, CONST_CODIGO_SUCURSALES_LISTAR, CONST_CODIGO_USUARIOS_AGREGAR, CONST_CODIGO_USUARIOS_EDITAR, CONST_CODIGO_USUARIOS_ELIMINAR, CONST_CODIGO_USUARIOS_GRUPOS, CONST_CODIGO_USUARIOS_LISTAR, CONST_RUTA_COMPANIAS_AGREGAR, CONST_RUTA_COMPANIAS_EDITAR, CONST_RUTA_COMPANIAS_LISTAR, CONST_RUTA_GRUPOS_AGREGAR, CONST_RUTA_GRUPOS_EDITAR, CONST_RUTA_GRUPOS_LISTAR, CONST_RUTA_GRUPOS_PERMISOS, CONST_RUTA_GRUPOS_USUARIOS, CONST_RUTA_GRUPOS_USUARIOS_AGREGAR, CONST_RUTA_MODULOS_EDITAR, CONST_RUTA_MODULOS_LISTAR, CONST_RUTA_PARAMETROS_AGREGAR, CONST_RUTA_PARAMETROS_EDITAR, CONST_RUTA_PARAMETROS_LISTAR, CONST_RUTA_SEDES_AGREGAR, CONST_RUTA_SEDES_EDITAR, CONST_RUTA_SEDES_LISTAR, CONST_RUTA_SEGURIDAD, CONST_RUTA_SUCURSALES_AGREGAR, CONST_RUTA_SUCURSALES_EDITAR, CONST_RUTA_SUCURSALES_LISTAR, CONST_RUTA_USUARIOS_AGREGAR, CONST_RUTA_USUARIOS_EDITAR, CONST_RUTA_USUARIOS_GRUPOS, CONST_RUTA_USUARIOS_LISTAR } from "./constants-rutas-codigos";

//Datos para validar en el AppRouter para obtener en base a la ruta el codigo de programa
export const rutasCodigosPrograma = [
    {
        codigo: CONST_CODIGO_SEGURIDAD,
        ruta: CONST_RUTA_SEGURIDAD,
    },
    {
        codigo: CONST_CODIGO_COMPANIAS_LISTAR,
        ruta: CONST_RUTA_COMPANIAS_LISTAR,
    },
    {
        codigo: CONST_CODIGO_USUARIOS_LISTAR,
        ruta: CONST_RUTA_USUARIOS_LISTAR,
    },
    {
        codigo: CONST_CODIGO_GRUPOS_LISTAR,
        ruta: CONST_RUTA_GRUPOS_LISTAR,
    },
    {
        codigo: CONST_CODIGO_MODULOS_LISTAR,
        ruta: CONST_RUTA_MODULOS_LISTAR,
    },
    {
        codigo: CONST_CODIGO_SEDES_LISTAR,
        ruta: CONST_RUTA_SEDES_LISTAR,
    },
    {
        codigo: CONST_CODIGO_SUCURSALES_LISTAR,
        ruta: CONST_RUTA_SUCURSALES_LISTAR,
    },
    {
        codigo: CONST_CODIGO_PARAMETROS_LISTAR,
        ruta: CONST_RUTA_PARAMETROS_LISTAR,
    },
    {
        codigo: CONST_CODIGO_COMPANIAS_AGREGAR,
        ruta: CONST_RUTA_COMPANIAS_AGREGAR,
    },
    {
        codigo: CONST_CODIGO_COMPANIAS_EDITAR,
        ruta: CONST_RUTA_COMPANIAS_EDITAR,
    },
    {
        codigo: CONST_CODIGO_COMPANIAS_ELIMINAR,
        ruta: "",
    },
    {
        codigo: CONST_CODIGO_USUARIOS_AGREGAR,
        ruta: CONST_RUTA_USUARIOS_AGREGAR,
    },
    {
        codigo: CONST_CODIGO_USUARIOS_EDITAR,
        ruta: CONST_RUTA_USUARIOS_EDITAR,
    },
    {
        codigo: CONST_CODIGO_USUARIOS_ELIMINAR,
        ruta: ""
    },
    {
        codigo: CONST_CODIGO_USUARIOS_GRUPOS,
        ruta: CONST_RUTA_USUARIOS_GRUPOS
    },
    {
        codigo: CONST_CODIGO_GRUPOS_AGREGAR,
        ruta: CONST_RUTA_GRUPOS_AGREGAR
    },
    {
        codigo: CONST_CODIGO_GRUPOS_EDITAR,
        ruta: CONST_RUTA_GRUPOS_EDITAR
    },
    {
        codigo: CONST_CODIGO_GRUPOS_ELIMINAR,
        ruta: ""
    },
    {
        codigo: CONST_CODIGO_GRUPOS_USUARIOS,
        ruta: CONST_RUTA_GRUPOS_USUARIOS
    },
    {
        codigo: CONST_CODIGO_GRUPOS_PERMISOS,
        ruta: CONST_RUTA_GRUPOS_PERMISOS
    },
    {
        codigo: CONST_CODIGO_GRUPOS_USUARIOS_AGREGAR,
        ruta: CONST_RUTA_GRUPOS_USUARIOS_AGREGAR
    },
    {
        codigo: CONST_CODIGO_MODULOS_AGREGAR,
        ruta: CONST_RUTA_MODULOS_LISTAR
    },
    {
        codigo: CONST_CODIGO_MODULOS_EDITAR,
        ruta: CONST_RUTA_MODULOS_EDITAR
    },
    {
        codigo: CONST_CODIGO_MODULOS_ELIMINAR,
        ruta: ""
    },
    {
        codigo: CONST_CODIGO_SEDES_AGREGAR,
        ruta: CONST_RUTA_SEDES_AGREGAR
    },
    {
        codigo: CONST_CODIGO_SEDES_EDITAR,
        ruta: CONST_RUTA_SEDES_EDITAR
    },
    {
        codigo: CONST_CODIGO_SEDES_ELIMINAR,
        ruta: ""
    },
    {
        codigo: CONST_CODIGO_PARAMETROS_AGREGAR,
        ruta: CONST_RUTA_PARAMETROS_AGREGAR
    },
    {
        codigo: CONST_CODIGO_PARAMETROS_EDITAR,
        ruta: CONST_RUTA_PARAMETROS_EDITAR
    },
    {
        codigo: CONST_CODIGO_PARAMETROS_ELIMINAR,
        ruta: ""
    },
    
    {
        codigo: CONST_CODIGO_SUCURSALES_AGREGAR,
        ruta: CONST_RUTA_SUCURSALES_AGREGAR
    },
    {
        codigo: CONST_CODIGO_SUCURSALES_EDITAR,
        ruta: CONST_RUTA_SUCURSALES_EDITAR
    },
    {
        codigo: CONST_CODIGO_SUCURSALES_ELIMINAR,
        ruta: ""
    }
]