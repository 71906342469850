
//Iconos
import { FaRegEdit } from "react-icons/fa"
import { LuArrowUpNarrowWide } from "react-icons/lu"
import { FcSearch } from "react-icons/fc"
import { RiDeleteBinFill } from "react-icons/ri"
import { BsSortNumericDown } from "react-icons/bs"

import { Link, useLocation, useNavigate } from "react-router-dom"
import { listaEstadosActivo, listaEstadosActivoBusqueda, listaTipoModulo } from "../../../constants/constants-selectores"
import { useForm, useFieldArray, useFormContext } from "react-hook-form"
import { DataContext } from "../../../context/DataContext"
import { useContext, useEffect, useState } from "react"
import { moduloDelete, moduloMostrar, moduloSubirNivel, modulosBuscar } from "../../../api/moduloApi"
import { obtenerLabel, obtenerLocalStorage } from "../../../resources/funciones"
import Swal from 'sweetalert2';
import { IoMdAdd } from "react-icons/io"
import { BiSearch } from "react-icons/bi"
import { useMobileDetection } from "../../../hook/useMobileDetection"
import { CONST_KEY_USUARIO_AUTENTICADO } from "../../../constants/constanst-valores"
import { validarAutenticacion } from "../../../resources/funcion-validar-autenticacion"
import { CONST_RUTA_MODULOS_AGREGAR, CONST_RUTA_MODULOS_EDITAR, CONST_RUTA_SIN_ACCESO } from "../../../constants/constants-rutas-codigos"
import { rutasCodigosPrograma } from "../../../constants/constanst-arreglo-rutas-codigo"
import { usuarioObtenerCompaniasYSucursales } from "../../../api/usuarioApi"

export const Modulos = () => {

  const usuarioAutenticado = obtenerLocalStorage(CONST_KEY_USUARIO_AUTENTICADO);
  //Obtener la accion a partir de la url (agregar o editar)
  const location = useLocation();

  const arrayModulosSuperiores = location?.state?.arrayModulosSuperiores

  const { listaRutasDeAcceso } = useContext(DataContext)
  const [listaCompanias, setListaCompanias] = useState([])

  const [codModuloSuperior, setCodModuloSuperior] = useState("")
  const [nombreModuloSuperior, setNombreModuloSuperior] = useState("")
  const [companiaDelModulo, setCompaniaDelModulo] = useState("")

  const [isLoading, setIsLoading] = useState(false)
  const [moduloSuperior, setModuloSuperior] = useState(arrayModulosSuperiores || [0])

  const isMobile = useMobileDetection()

  //Estados para ver si mostrar o no los botones
  const [mostrarBotonAgregar, setMostrarBotonAgregar] = useState(false)
  const [mostrarBotonEditar, setMostrarBotonEditar] = useState(false)
  const [mostrarBotonEliminar, setMostrarBotonEliminar] = useState(false)

  const { register, handleSubmit, watch, formState: { errors }, reset, setValue } = useForm({
    defaultValues: {
      estado: "A",
    }
  });

  const {
    register: registerOrdenar,
    handleSubmit: handleSubmitOrdenar,
    watch: watchOrdenar,
    formState: formStateOrdenar,
    reset: resetOrdenar,
    control
  } = useForm({
    defaultValues: {
      modulosOrdenados: [{}]
    },
  })

  const {
    fields: compradoresFields,
    append: compradoresAppend,
    remove: compradoresRemove,
  } = useFieldArray({ control, name: "modulosOrdenados" });

  const [listaModulos, setListaModulos] = useState([])
  const [buscar, setBuscar] = useState(false)

  const onSubmitBuscar = async (data) => {

    let modulosSuperiores = [...moduloSuperior];

    setIsLoading(true)
    const dataPOST = {
      CompCod: data?.compania,
      ModDesc: data?.modulo || "",
      Estado: data?.estado,
      Superior: modulosSuperiores.pop() || "",
      token: usuarioAutenticado?.token
    }

    const { modulos } = await modulosBuscar(dataPOST)

    const listaModulosOrdenados = modulos?.map((m) => ({
      codModulo: m?.ModCod,
    }))
    resetOrdenar({
      modulosOrdenados: listaModulosOrdenados
    })
    setListaModulos(modulos)
    setBuscar(true)
    setIsLoading(false)
  };

  const navigate = useNavigate()

  const moduloAgregar = async () => {

    const rutaNavegar = CONST_RUTA_MODULOS_AGREGAR
    const modulo = rutasCodigosPrograma.find(r => r.ruta == rutaNavegar)

    const validarAutenticado = await validarAutenticacion(usuarioAutenticado?.token, modulo?.codigo)

    let modulosSuperiores = [...moduloSuperior];

    if (validarAutenticado) {
      navigate(rutaNavegar, {
        state: {
          superior: { id: modulosSuperiores.pop() },
          codCompania: companiaDelModulo,
          arrayModulosSuperiores: moduloSuperior
        }
      })
    } else {
      navigate(CONST_RUTA_SIN_ACCESO)
    }

  }

  const moduloEditar = async (CodModulo) => {

    const rutaNavegar = CONST_RUTA_MODULOS_EDITAR
    const modulo = rutasCodigosPrograma.find(r => r.ruta == rutaNavegar)

    const validarAutenticado = await validarAutenticacion(usuarioAutenticado?.token, modulo?.codigo)

    if (validarAutenticado) {
      const dataPOST = {
        CodigoModulo: CodModulo,
        token: usuarioAutenticado?.token
      }
      const { modulo } = await moduloMostrar(dataPOST)

      navigate(rutaNavegar, {
        state: {
          superior: { id: codModuloSuperior, tipo: nombreModuloSuperior },
          modulo: modulo,
          arrayModulosSuperiores: moduloSuperior
        }
      })
    } else {
      navigate(CONST_RUTA_SIN_ACCESO)
    }

  }

  const modulosBuscarTodos = async () => {

    setIsLoading(true)
    let modulosSuperiores = [...moduloSuperior];

    const dataPOST = {
      CompCod: watch("compania") || listaCompanias?.[0]?.id || "",
      ModDesc: watch("modulo") || "",
      Estado: watch("estado"),
      Superior: modulosSuperiores.pop() /* || codModuloSuperior */,
      token: usuarioAutenticado?.token
    }

    const { modulos } = await modulosBuscar(dataPOST)

    const listaModulosOrdenados = modulos?.map(m => ({
      codModulo: m?.ModCod,
    }))
    resetOrdenar({
      modulosOrdenados: listaModulosOrdenados
    })
    setListaModulos(modulos)
    setIsLoading(false)

  }

  const moduloListarSubModulos = async (codModulo, nomModulo, codCompania) => {

    moduloSuperior.push(codModulo)
    setCodModuloSuperior(codModulo)
    setNombreModuloSuperior(nomModulo)
    setCompaniaDelModulo(codCompania)
    modulosBuscarTodos()
  }

  const moduloRetroceder = async () => {
    if (moduloSuperior.length > 1) {

      let modulosSuperiores = [...moduloSuperior]
      const dataPOST = {
        CompCod: watch("compania"),
        Estado: watch("estado"),
        Superior: modulosSuperiores.pop(),
        token: usuarioAutenticado?.token
      }

      const { modulos } = await moduloSubirNivel(dataPOST)
      moduloSuperior.pop()
      if (modulos?.[0]?.ModSup == "0") {
        setCompaniaDelModulo("")
      }

      setListaModulos(modulos)
    }
  }

  const optionsEliminar = (modulo) => {
    return {
      title: `¿Estás seguro de eliminar al módulo: ${modulo}?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#6c757d',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
    }
  }

  const moduloEliminar = (codModulo, modulo) => {
    Swal.fire(optionsEliminar(modulo)).then(async (result) => {
      if (result.isConfirmed) {

        const dataPOST = {
          ModCod: codModulo,
          token: usuarioAutenticado?.token
        }
        const { status } = await moduloDelete(dataPOST)
        if (status == 1) {
          modulosBuscarTodos()
          Swal.fire(
            'Módulo eliminado',
            '',
            'success'
          )
        } else {
          Swal.fire(
            'Error al eliminar módulo',
            '',
            'success'
          )
        }

      }
    })

  }

  const obtenerCompaniasYSucursales = async () => {

    const dataPOST = {
      token: usuarioAutenticado?.token
    }

    const { companias, sucursales } = await usuarioObtenerCompaniasYSucursales(dataPOST)
    setListaCompanias(companias)
    setValue("compania", companias?.[0]?.id)

    return {
      companias,
      sucursales
    }
  }

  const moduloOrdenar = (data) => {
    // console.log('data', data)
  }

  useEffect(() => {

    const encontradoRutaBotonAgregar = listaRutasDeAcceso?.find(r => r.codigo_programa == "S0103010000")
    const encontradoRutaBotonEditar = listaRutasDeAcceso?.find(r => r.codigo_programa == "S0103020000")
    const encontradoRutaBotonEliminar = listaRutasDeAcceso?.find(r => r.codigo_programa == "S0103030000")

    encontradoRutaBotonAgregar ? setMostrarBotonAgregar(true) : setMostrarBotonAgregar(false)
    encontradoRutaBotonEditar ? setMostrarBotonEditar(true) : setMostrarBotonEditar(false)
    encontradoRutaBotonEliminar ? setMostrarBotonEliminar(true) : setMostrarBotonEliminar(false)

  }, [listaRutasDeAcceso])

  useEffect(() => {

    const cargarDatosIniciales = async () => {
      await obtenerCompaniasYSucursales()
      await modulosBuscarTodos()
    };

    cargarDatosIniciales();
  }, [])


  return (
    <div className="p-3">
      <div className="container-fluid">
        <h3 className="text-center mt-1">Módulos</h3>

        {/* Formulario */}
        <div className="my-3 border p-1 rounded-5">
          <form onSubmit={handleSubmit(onSubmitBuscar)} className="row p-1">

            <div className="col-12 col-lg-6">
              <label className="text-start">Compañía</label>
              <select
                {...register("compania",{
                  required: true
                })}
                className="form-select form-select-sm"
                value={watch("compania")}
              >
                {
                  listaCompanias?.length > 0 && listaCompanias.map(e => (
                    <option key={e?.id} value={e?.id}>{e?.tipo}</option>
                  ))
                }
              </select>
            </div>
            <div className="col-12 col-lg-5">
              <label className="text-start">Estado</label>
              <select {...register("estado")} className="form-select form-select-sm">
                {
                  listaEstadosActivoBusqueda?.length > 0 && listaEstadosActivoBusqueda.map(e => (
                    <option
                      key={e?.id}
                      value={e?.id}
                    >{e?.tipo}</option>
                  ))
                }
              </select>
            </div>
            <div className="col-12 col-lg-1">
              <label className="text-start">&nbsp;</label>
              <div className={isMobile ? "text-center" : ""}>
                <button type="submit" onClick={handleSubmit(onSubmitBuscar)} style={{ cursor: 'pointer', background: 'none', border: 'none' }}>
                  <BiSearch size={25} className="color-iconos" />
                </button>
              </div>
            </div>
          </form>
        </div>
        {/* Fin formulario */}

        <div className="mx-auto ps-0">
          <div className={mostrarBotonAgregar ? "d-flex justify-content-between" : "d-flex justify-content-end"}>
            {
              mostrarBotonAgregar && (
                <div>
                  <button className='btn btn-primario btn-sm me-5' onClick={moduloAgregar}>
                    <div className="d-flex align-items-center"> {/* Contenedor para centrar */}
                      <IoMdAdd size={18} />
                      <span className="ms-1">Agregar</span>
                    </div>
                  </button>
                  {/* <button className='btn btn-primario btn-sm' onClick={handleSubmitOrdenar(moduloOrdenar)}>
                    <div className="d-flex align-items-center">
                      <BsSortNumericDown />
                      <span className="ms-1">Ordenar</span>
                    </div>
                  </button> */}
                </div>
              )}

            <div>
              <button className="btn btn-light btn-sm" onClick={moduloRetroceder} style={{ cursor: 'pointer' }}><LuArrowUpNarrowWide size={25} className="color-iconos" /></button>
            </div>
          </div>
        </div>


        {/* Tabla de resultados */}
        <div className="col-12 table-responsive mt-2">
          <table className="table table-hover mt-2">
            <thead className="table-light">
              <tr>
                <th scope="col" className="col text-center">Código</th>
                {/* <th scope="col" className="col text-center">Orden</th> */}
                <th scope="col" className="col text-center">Módulo</th>
                <th scope="col" className="col text-center">Tipo</th>
                <th scope="col" className="col text-center">Cod Programa</th>
                <th scope="col" className="col text-center">Estado</th>
                {
                  mostrarBotonEditar && (
                    <th scope="col" className="col text-center">Editar</th>
                  )
                }
                {
                  mostrarBotonEliminar && (
                    <th scope="col" className="col text-center">Eliminar</th>
                  )
                }
              </tr>
            </thead>
            <tbody>
              {
                isLoading ? (
                  <tr className="align-middle">
                    <td colSpan="100" className="text-center">

                      <div className="spinner-border text-secondary spinner-border-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </td>
                  </tr>

                ) : (
                  listaModulos?.length > 0 ? listaModulos.map((m, index) => (
                    <tr key={m?.ModCod} className="align-middle">
                      <td>{m?.ModCod}</td>
                      {/* <td className="col-1">
                        <input
                          {...registerOrdenar(`modulosOrdenados.${index}.orden`)}
                          defaultValue={index + 1}
                          type="text"
                          className="form-control form-control-sm text-center"
                          autoComplete="off"
                        />
                      </td> */}
                      <td onClick={() => moduloListarSubModulos(m?.ModCod, m?.ModDesc, m?.ModComp)} ><Link style={{ textDecoration: "none" }}>{m?.ModDesc}</Link></td>
                      <td>{obtenerLabel(listaTipoModulo, m?.ModTip)}</td>
                      <td>{m?.ModCodProg}</td>
                      <td>{m?.ModEst == "M" ? "Master" : obtenerLabel(listaEstadosActivo, m?.ModEst)}</td>
                      {
                        mostrarBotonEditar && (
                          <td><FaRegEdit size={23} cursor={'pointer'} className="color-iconos" onClick={() => moduloEditar(m?.ModCod)} /></td>
                        )
                      }
                      {
                        mostrarBotonEliminar && (
                          <td><RiDeleteBinFill size={23} cursor={'pointer'} color="#C0392B" onClick={() => moduloEliminar(m?.ModCod, m?.ModDesc)} /></td>
                        )
                      }

                    </tr>
                  )) :
                    (
                      (buscar) ? (
                        <tr>
                          <td colSpan="100" className="text-center">No hay módulos</td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan="100" className="text-center">Realice una búsqueda</td>
                        </tr>
                      )
                    )
                )
              }
            </tbody>
          </table>
        </div>
        {/* Fin Tabla de resultados */}

      </div>
    </div>
  )
}