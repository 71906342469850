
//Iconos
import { FaRegEdit } from "react-icons/fa"
import { FcSearch } from "react-icons/fc"
import { RiDeleteBinFill } from "react-icons/ri"
import { IoMdAdd } from "react-icons/io"
import { BiSearch } from "react-icons/bi"

import { Link, useLocation, useNavigate } from "react-router-dom"
import { listaEstadosActivo, listaEstadosActivoBusqueda, listaTipoModulo } from "../../../constants/constants-selectores"
import { useForm } from "react-hook-form"
import { DataContext } from "../../../context/DataContext"
import { useContext, useEffect, useState } from "react"
import { obtenerLabel, obtenerLocalStorage } from "../../../resources/funciones"
import Swal from 'sweetalert2';
import { parametroDelete, parametroMostrar, parametrosBuscar } from "../../../api/parametroApi"
import { useMobileDetection } from "../../../hook/useMobileDetection"
import { CONST_KEY_USUARIO_AUTENTICADO } from "../../../constants/constanst-valores"
import { CONST_CODIGO_COMPANIAS_EDITAR, CONST_CODIGO_PARAMETROS_AGREGAR, CONST_CODIGO_PARAMETROS_EDITAR, CONST_CODIGO_PARAMETROS_ELIMINAR, CONST_RUTA_PARAMETROS_AGREGAR, CONST_RUTA_PARAMETROS_EDITAR, CONST_RUTA_SIN_ACCESO } from "../../../constants/constants-rutas-codigos"
import { rutasCodigosPrograma } from "../../../constants/constanst-arreglo-rutas-codigo"
import { validarAutenticacion } from "../../../resources/funcion-validar-autenticacion"
import { usuarioObtenerCompaniasYSucursales } from "../../../api/usuarioApi"

export const Parametros = () => {

    const usuarioAutenticado = obtenerLocalStorage(CONST_KEY_USUARIO_AUTENTICADO);

    const { listaRutasDeAcceso } = useContext(DataContext)
    const [listaCompanias, setListaCompanias] = useState([])

    const [isLoading, setIsLoading] = useState(false)

    //Estados para ver si mostrar o no los botones
    const [mostrarBotonAgregar, setMostrarBotonAgregar] = useState(false)
    const [mostrarBotonEditar, setMostrarBotonEditar] = useState(false)
    const [mostrarBotonEliminar, setMostrarBotonEliminar] = useState(false)

    const { register, handleSubmit, watch, formState: { errors }, reset, setValue } = useForm({
        defaultValues: {
            codigo: "",
            descripcion: "",
            estado: "A"
        }
    });


    const isMobile = useMobileDetection()

    const [listaParametros, setListaParametros] = useState([])
    const [buscar, setBuscar] = useState(false)

    const onSubmitBuscar = async (data) => {
        setIsLoading(true)
        const dataPOST = {
            ParamCod: data?.codigo,
            ParamDes: data?.descripcion,
            estado: data?.estado,
            compania: data?.compania,
            token: usuarioAutenticado?.token

        }

        const { parametros } = await parametrosBuscar(dataPOST)

        setListaParametros(parametros)
        setBuscar(true)
        setIsLoading(false)
    };

    const navigate = useNavigate()

    const parametroAgregar = async () => {

        const validarAutenticado = await validarAutenticacion(usuarioAutenticado?.token, CONST_CODIGO_PARAMETROS_AGREGAR)

        if (validarAutenticado) {
            navigate(CONST_RUTA_PARAMETROS_AGREGAR)
        } else {
            navigate(CONST_RUTA_SIN_ACCESO)
        }

    }

    const parametroEditar = async (codParametro, codCompania) => {

        const validarAutenticado = await validarAutenticacion(usuarioAutenticado?.token, CONST_CODIGO_COMPANIAS_EDITAR)

        if (validarAutenticado) {
            const dataPOST = {
                codigo: codParametro,
                compania: codCompania,
                token: usuarioAutenticado?.token
            }

            const { parametro } = await parametroMostrar(dataPOST)

            navigate(CONST_RUTA_PARAMETROS_EDITAR, {
                state: parametro
            })
        } else {
            navigate(CONST_RUTA_SIN_ACCESO)
        }

    }

    const parametrosBuscarActivos = async () => {

        setIsLoading(true)
        const dataPOST = {
            ParamCod: watch("codigo") || "",
            ParamDes: watch("descripcion") || "",
            estado: watch("Estado") || "A",
            compania: watch("compania"),
            token: usuarioAutenticado?.token

        }

        const { parametros } = await parametrosBuscar(dataPOST)
       
        setListaParametros(parametros)
        setBuscar(true)
        setIsLoading(false)

    }

    const optionsEliminar = (descripcionParametro) => {
        return {
            title: `¿Estás seguro de eliminar el parámetro: ${descripcionParametro}?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#6c757d',
            confirmButtonColor: '#d33',
            confirmButtonText: 'Eliminar'
        }
    }

    const parametroEliminar = (codParametro, descripcionParametro) => {
        Swal.fire(optionsEliminar(descripcionParametro)).then(async (result) => {
            if (result.isConfirmed) {
                const dataPOST = {
                    ParamCod: codParametro,
                    token: usuarioAutenticado?.token

                }
                const { status } = await parametroDelete(dataPOST)
                if (status == 1) {
                    parametrosBuscarActivos()
                    Swal.fire(
                        'Parámetro eliminado',
                        '',
                        'success'
                    )
                } else {
                    Swal.fire(
                        'Error al eliminar parámetro',
                        '',
                        'success'
                    )
                }

            }
        })

    }


    const obtenerCompaniasYSucursales = async () => {

        const dataPOST = {
            token: usuarioAutenticado?.token
        }

        const { companias, sucursales } = await usuarioObtenerCompaniasYSucursales(dataPOST)
        setListaCompanias(companias)
        setValue("compania", companias?.[0]?.id)

        return {
            companias,
            sucursales
        }
    }

    useEffect(() => {
        const encontradoRutaBotonAgregar = listaRutasDeAcceso?.find(r => r.codigo_programa == CONST_CODIGO_PARAMETROS_AGREGAR)
        const encontradoRutaBotonEditar = listaRutasDeAcceso?.find(r => r.codigo_programa == CONST_CODIGO_PARAMETROS_EDITAR)
        const encontradoRutaBotonEliminar = listaRutasDeAcceso?.find(r => r.codigo_programa == CONST_CODIGO_PARAMETROS_ELIMINAR)

        encontradoRutaBotonAgregar ? setMostrarBotonAgregar(true) : setMostrarBotonAgregar(false)
        encontradoRutaBotonEditar ? setMostrarBotonEditar(true) : setMostrarBotonEditar(false)
        encontradoRutaBotonEliminar ? setMostrarBotonEliminar(true) : setMostrarBotonEliminar(false)
    }, [listaRutasDeAcceso])

    useEffect(() => {

        const cargarDatosIniciales = async () => {
            await obtenerCompaniasYSucursales()
            await parametrosBuscarActivos()
        };

        cargarDatosIniciales();
    }, [])

    return (
        <div className="p-3">
            <div className="container-fluid">
                <h3 className="text-center mt-1">Parámetros</h3>

                {/* Formulario */}
                <div className="my-3 border p-1 rounded-5">
                    <form className="row p-1" onSubmit={handleSubmit(onSubmitBuscar)}>

                        <div className="col-12 col-lg-3">
                            <label className="text-start">Código</label>
                            <input
                                {...register("codigo")}
                                type="text"
                                className="form-control form-control-sm"
                                autoComplete="off"
                                placeholder="Código"
                            />
                        </div>
                        <div className="col-12 col-lg-3">
                            <label className="text-start">Descripción</label>
                            <input
                                {...register("descripcion")}
                                type="text"
                                className="form-control form-control-sm"
                                autoComplete="off"
                                placeholder="Descripción"
                            />
                        </div>
                        <div className="col-12 col-lg-3">
                            <label className="text-start">Compañía</label>
                            <select 
                            {...register("compania")} 
                            className="form-select form-select-sm"
                            value={watch("compania", {
                                required: true
                            })}
                            >
                                {
                                    listaCompanias?.length > 0 && listaCompanias.map(e => (
                                        <option key={e?.id} value={e?.id}>{e?.tipo}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="col-12 col-lg-2">
                            <label className="text-start">Estado</label>
                            <select {...register("estado")} className="form-select form-select-sm" >
                                {
                                    listaEstadosActivoBusqueda?.length > 0 && listaEstadosActivoBusqueda.map(e => (
                                        <option key={e?.id} value={e?.id}>{e?.tipo}</option>
                                    ))
                                }
                            </select>
                        </div>

                        <div className="col-12 col-lg-1">
                            <label className="text-start">&nbsp;</label>
                            <div className={isMobile ? "text-center" : ""}>
                                <button type="submit" onClick={handleSubmit(onSubmitBuscar)} style={{ cursor: 'pointer', background: 'none', border: 'none' }}>
                                    <BiSearch size={25} className="color-iconos" />
                                </button>
                            </div>
                        </div>

                    </form>
                </div>
                {/* Fin formulario */}

                {
                    mostrarBotonAgregar && (
                        <div className="mx-auto ps-0">
                            <button className='btn btn-primario btn-sm d-flex align-items-center' onClick={parametroAgregar}>
                                <IoMdAdd size={18} />
                                <span className="ms-1">Agregar</span>
                            </button>
                        </div>
                    )
                }
                {/* Tabla de resultados */}
                <div className="col-12 table-responsive mt-2">

                    <table className="table table-hover mt-2">
                        <thead className="table-light">
                            <tr>
                                <th scope="col" className="col text-center">Código</th>
                                <th scope="col" className="col text-center">Descripción</th>
                                <th scope="col" className="col text-center">Valor</th>
                                <th scope="col" className="col text-center">Estado</th>
                                {
                                    mostrarBotonEditar && (
                                        <th scope="col" className="col text-center">Editar</th>
                                    )
                                }
                                {
                                    mostrarBotonEliminar && (
                                        <th scope="col" className="col text-center">Eliminar</th>
                                    )
                                }

                            </tr>
                        </thead>
                        <tbody>
                            {
                                isLoading ? (
                                    <tr>
                                        <td colSpan="100" className="text-center">

                                            <div className="spinner-border text-secondary spinner-border-sm" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </td>
                                    </tr>

                                ) : (
                                    listaParametros?.length > 0 ? listaParametros.map(m => (
                                        <tr key={m?.ParamCod}>
                                            <td>{m?.ParamCod}</td>
                                            <td>{m?.ParamDes}</td>
                                            <td>{m?.ParamVal}</td>
                                            <td>{obtenerLabel(listaEstadosActivo, m?.ParamEst)}</td>
                                            {
                                                mostrarBotonEditar && (
                                                    <td><FaRegEdit size={23} cursor={'pointer'} className="color-iconos" onClick={() => parametroEditar(m?.ParamCod, m?.CompCod)} /></td>
                                                )
                                            }
                                            {
                                                mostrarBotonEliminar && (
                                                    <td><RiDeleteBinFill size={23} cursor={'pointer'} color="#C0392B" onClick={() => parametroEliminar(m?.ParamCod, m?.ParamDes)} /></td>
                                                )
                                            }
                                        </tr>
                                    )) :
                                        (
                                            (buscar) ? (
                                                <tr>
                                                    <td colSpan="100" className="text-center">No hay parámetros</td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <td colSpan="100" className="text-center">Realice una búsqueda</td>
                                                </tr>
                                            )
                                        )
                                )
                            }
                        </tbody>
                    </table>

                </div>
                {/* Fin Tabla de resultados */}

            </div>
        </div>
    )
}
