// import { useEffect } from 'react';
import { AppRouter } from './router/AppRouter'
import { DataProvider } from './context/DataProvider'
import { AuthProvider } from './context/AuthProvider'
// import { GetUrlServer } from './api/GetUrlServer';
// import { encriptar, guardarLocalStorage } from './resources/funciones';
// import { keyServidor } from './config/keyServidor';

export const SistemaSeguridadApp = () => {
  
  // useEffect(() => {

  //   const obtenerUrl = async() => {
  //     const url_server = await GetUrlServer()
  //     const urlServerCifrado = encriptar(url_server)
  //     guardarLocalStorage(urlServerCifrado, keyServidor)
  //   }
  
  //   obtenerUrl()
  // }, [])
  
  return (
    <AuthProvider>
      <DataProvider>
        <AppRouter />
      </DataProvider>
    </AuthProvider>
  )
}
