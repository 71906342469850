import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { listaEstadosActivo, listaTipoModulo } from "../../../constants/constants-selectores";
import { moduloCrear, moduloEditar } from "../../../api/moduloApi";
import { DataContext } from "../../../context/DataContext";
import { obtenerLabel, obtenerLocalStorage } from "../../../resources/funciones";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { CONST_RUTA_MODULOS_LISTAR } from "../../../constants/constants-rutas-codigos";
import { CONST_KEY_USUARIO_AUTENTICADO } from "../../../constants/constanst-valores";
import { usuarioObtenerCompaniasYSucursales } from "../../../api/usuarioApi";

export const ModuloForm = () => {

    const usuarioAutenticado = obtenerLocalStorage(CONST_KEY_USUARIO_AUTENTICADO);

    const [listaCompanias, setListaCompanias] = useState([])

    const [mensaje, setMensaje] = useState('')
    const [mostrar, setMostrar] = useState(false)
    const [tipoAlerta, setTipoAlerta] = useState("")

    //Obtener la accion a partir de la url (agregar o editar)
    const location = useLocation();
    const pathname = location.pathname;
    const accion = pathname.split("/").pop()

    const modulo = location?.state?.modulo || {}

    const superior = location?.state?.superior || {}
    const arrayModulosSuperiores = location?.state?.arrayModulosSuperiores || []

    const codCompania = location?.state?.codCompania || ""

    const initialSuperior = [
        {
            id: superior?.id || 0,
            tipo: superior?.tipo || ""
        }
    ]

    const { register, handleSubmit, watch, formState: { errors }, reset, setValue } = useForm({
        defaultValues: {
            superior: modulo?.ModSup || "",
            tipo: modulo?.ModTip || "MWT",
            descripcion: modulo?.ModDesc || "",
            objeto: modulo?.ModObj || "",
            cod_programa: modulo?.ModCodProg || "",
            valor: modulo?.ModVal || "",
            estado: modulo?.ModEst || "A",
        }
    });

    const onSubmitCrear = async (data) => {

        const dataPOST = {
            ModSup: initialSuperior?.[0]?.id,
            ModTip: data?.tipo || "",
            ModDesc: data?.descripcion.toUpperCase().trim() || "",
            ModObj: data?.objeto || "",
            ModCodProg: data?.cod_programa || "",
            ModVal: data?.valor || "",
            ModEst: data?.estado || "",
            CompCod: data?.compania || "",

            token: usuarioAutenticado?.token
        }

        const { status, message } = await moduloCrear(dataPOST)

        setMostrar(true)
        setMensaje(message)

        if (status == 1) {
            setTipoAlerta("success")
            navigate(CONST_RUTA_MODULOS_LISTAR, {
                state: {
                    arrayModulosSuperiores: arrayModulosSuperiores
                }
            })
        } else {
            setTipoAlerta("warning")
        }
    }

    const onSubmitEditar = async (data) => {

        const dataPOST = {
            ModSup: data?.superior,
            ModTip: data?.tipo || "",
            ModDesc: data?.descripcion?.toUpperCase().trim() || "",
            ModObj: data?.objeto || "",
            ModCodProg: data?.cod_programa || "",
            ModVal: data?.valor || "",
            ModEst: data?.estado || "",
            CompCod: data?.compania || "",

            ModCod: modulo?.ModCod || "",
            ModCodNew: data?.ModCod || "",

            token: usuarioAutenticado?.token

        }

        const { status, message } = await moduloEditar(dataPOST)
        setMostrar(true)
        setMensaje(message)


        if (status == 1) {
            setTipoAlerta("success")
        } else {
            setTipoAlerta("warning")
        }
    }

    const navigate = useNavigate();

    const cancelar = () => {

        navigate(CONST_RUTA_MODULOS_LISTAR, {
            state: {
                arrayModulosSuperiores: arrayModulosSuperiores,
            }
        })
    }

    const obtenerCompaniasYSucursales = async () => {

        const dataPOST = {
            token: usuarioAutenticado?.token
        }

        const { companias, sucursales } = await usuarioObtenerCompaniasYSucursales(dataPOST)
        setListaCompanias(companias)
        setValue("compania", modulo?.ModComp || companias?.[0]?.id)

        return {
            companias,
            sucursales
        }
    }

    useEffect(() => {

        const cargarDatosIniciales = async () => {
            await obtenerCompaniasYSucursales()
        };

        cargarDatosIniciales();
    }, [])

    return (
        <div className="p-3">
            <div className="container-fluid">
                <h3 className="text-center mt-1">Módulo</h3>
                <div className="row mt-3">
                    <div className="col-12 col-lg-9 mx-auto mt-0">
                        {/* Formulario */}
                        <form id="CreateForm" className="row g-2 p-1" onSubmit={accion == "agregar" ? handleSubmit(onSubmitCrear) : handleSubmit(onSubmitEditar)}>
                            <div className="row border p-1 pb-2 rounded-5 g-2">

                                {/* <div className="col-12 col-lg-6">
                                <div className="d-flex align-items-center">
                                    <label className="col-4 text-start px-2">Superior</label>
                                    <select {...register("superior")} className="form-select form-select-sm" >
                                        {
                                            initialSuperior?.length > 0 && initialSuperior.map(c => (
                                                <option key={c?.id} value={c?.id}>{c?.tipo}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div> */}

                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Tipo</label>
                                        <select {...register("tipo")} className="form-select form-select-sm" >
                                            {
                                                listaTipoModulo?.length > 0 && listaTipoModulo.map(c => (
                                                    <option key={c?.id} value={c?.id}>{c?.tipo}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Descripcion</label>
                                        <input
                                            {...register("descripcion")}
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">

                                        <label className="col-4 text-start px-2">Objeto</label>
                                        <input
                                            {...register("objeto")}
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            style={{ textTransform: "none" }}
                                            placeholder="/ruta/ruta"
                                        />
                                    </div>
                                </div>
                                {
                                    accion == "editar" && (
                                        <div className="col-12 col-lg-6">
                                            <div className="d-flex align-items-center">
                                                <label className="col-4 text-start px-2">Cod. Programa</label>
                                                <input
                                                    {...register("cod_programa")}
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    autoComplete="off"
                                                    style={{ textTransform: "none" }}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>)
                                }

                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">

                                        <label className="col-4 text-start px-2">Valor</label>
                                        <input
                                            {...register("valor")}
                                            type="text"
                                            className="form-control form-control-sm"
                                            autoComplete="off"
                                            style={{ textTransform: "none" }}
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Estado</label>
                                        <select {...register("estado")} className="form-select form-select-sm" >
                                            {
                                                listaEstadosActivo?.length > 0 && listaEstadosActivo.map(c => (
                                                    <option key={c?.id} value={c?.id}>{c?.tipo}</option>
                                                ))
                                            }
                                            <option value={"M"}>Master</option>

                                        </select>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="d-flex align-items-center">
                                        <label className="col-4 text-start px-2">Compañía</label>
                                        <select 
                                            {...register("compania")} 
                                            className="form-select form-select-sm" 
                                            disabled={accion == "editar" ? true : (codCompania == "G" ? true : false)}
                                            value={watch("compania")}
                                        >
                                            <option value={"G"}>GLOBAL</option>
                                            {
                                                listaCompanias?.length > 0 && listaCompanias.map(c => (
                                                    <option key={c?.id} value={c?.id}>{c?.tipo}</option>
                                                ))
                                            }

                                        </select>
                                    </div>
                                </div>
                            </div>

                        </form>

                        <div className='mt-4 botones-accion text-center'>
                            {
                                accion == "agregar" && (
                                    <button className='btn btn-sm btn-primario col-md-2' type="submit" form="CreateForm" onClick={handleSubmit(onSubmitCrear)}>Guardar</button>
                                )
                            }
                            {
                                accion == "editar" && (
                                    <button className='btn btn-sm btn-primario col-md-2' type="submit" form="CreateForm" onClick={handleSubmit(onSubmitEditar)}>Guardar</button>
                                )
                            }
                            <button className='btn btn-sm btn-secundario col-md-2' onClick={cancelar}>Cancelar</button>

                        </div>
                        {/* Fin formulario */}
                    </div >

                </div>
                {
                    mostrar && (
                        <div className="row mt-4">
                            <div className="col-lg-6 mx-auto text-center alert-error">
                                <Stack spacing={2}>
                                    <Alert variant="outlined" severity={tipoAlerta} >{mensaje}</Alert>
                                </Stack>
                            </div>
                        </div>
                    )
                }

            </div>
        </div>
    )
}