import { AnimatePresence, motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { FaAngleDown } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";

const menuAnimation = {
    hidden: {
        opacity: 0,
        height: 0,
        padding: 0,
        transition: { duration: 0.2, when: "afterChildren" },
    },
    show: {
        opacity: 1,
        height: "auto",
        transition: {
            duration: 0.2,
            when: "beforeChildren",
        },
    },
};
const menuItemAnimation = {
    hidden: (i) => ({
        padding: 0,
        x: "-100%",
        transition: {
            //   duration: (i + 1) * 0.1,
            duration: i * 0.05,
        },
    }),
    show: (i) => ({
        x: 0,
        transition: {
            //   duration: (i + 1) * 0.1,
            duration: i * 0.05,
        },
    }),
};

export const SidebarMenuRecursive = ({ route, showAnimation, isOpen, setIsOpen, paddingMenu=null }) => {

    const navigate = useNavigate()
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        setIsOpen(true);
    };

    useEffect(() => {
        if (!isOpen) {
            setIsMenuOpen(false);
        }
    }, [isOpen]);
    return (
        <>
            <div className="menu" style={{paddingLeft: paddingMenu}} onClick={toggleMenu}>
                <div className="menu_item" /* onClick={()=>navigate(route?.path)} style={{cursor: "pointer"}} */>
                    <div className="icon">{route.icon}</div>
                    <AnimatePresence>
                        {isOpen && (
                            <motion.div
                                // variants={showAnimation}
                                initial="hidden"
                                animate="show"
                                exit="hidden"
                                className="link_text"
                            >
                                {route.name.toLowerCase()}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
                {isOpen && (
                    <motion.div
                        animate={
                            isMenuOpen
                                ? {
                                    rotate: -90,
                                }
                                : { rotate: 0 }
                        }
                    >
                        <FaAngleDown />
                    </motion.div>
                )}
            </div>{" "}
            <AnimatePresence>
                {isMenuOpen && (
                    <motion.div
                        // variants={menuAnimation}
                        initial="hidden"
                        animate="show"
                        exit="hidden"
                        className="menu_container"
                    >
                        {route.subRoutes.map((subRoute, i) => (
                            <motion.div /* variants={menuItemAnimation} */ key={i} custom={i}>

                                {subRoute.subRoutes ? (
                                    <SidebarMenuRecursive
                                        route={subRoute}
                                        // showAnimation={showAnimation}
                                        isOpen={isOpen}
                                        setIsOpen={setIsOpen}
                                        paddingMenu={"20px"}
                                    />
                                ) : (
                                    <NavLink to={subRoute.path} className="link-submenu">
                                        {
                                            subRoute?.icon && (
                                                <div className="icon" style={{width: "40px"}}>{subRoute.icon}</div>
                                            )
                                        }
                                       
                                        <motion.div className="link_text">{subRoute.name.toLowerCase()}</motion.div>
                                    </NavLink>
                                )}
                            </motion.div>
                        ))}
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};